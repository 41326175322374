import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { getNestedValue } from "components/FormRenderer/DMFhelperService";
import PropTypes from "prop-types";
import { getDisplayName, getDatum, getEuro, getLabel, getJahr, getJaNein, getMonatJahr } from "services/formatService";
import { krankenversicherungOptions } from "services/optionsService";

const styles = StyleSheet.create({
	table: {
		width: "100%",
		border: "1px solid gray",
		borderRadius: 8,
		fontSize: 9,
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderTop: "1px solid gray",
		paddingTop: 4,
		paddingBottom: 4,
	},
	alignTop: {
		alignItems: "top",
	},
	header: {
		borderTop: "none",
		paddingTop: 10,
		paddingBottom: 10,
	},
	bold: {
		fontWeight: "bold",
	},
	col0: {
		width: "20%",
		paddingLeft: 4,
		paddingRight: 4,
		alignContent: "center",
	},
	col1: {
		width: "40%",
		paddingLeft: 4,
		paddingRight: 4,
		display: "flex",
		flexDirection: "column",
	},
	col2: {
		width: "40%",
		paddingLeft: 4,
		paddingRight: 4,
		display: "flex",
		flexDirection: "column",
	},
});

export const HaushaltsmitgliederCard = ({ haushalt, person1, person2 }) => {
	const noneFormatter = (value) => <Text>{value ? value : "keine Angabe"}</Text>;
	const euroZeroFormatter = (value) => <Text>{value ? getEuro(value, 0) : "keine Angabe"}</Text>;
	const rolleFormatter = (person) => <Text>{person ? "(" + person.role + ")" : ""}</Text>;
	const datumFormatter = (value) => <Text>{value ? getDatum(value) : "keine Angabe"}</Text>;
	const bezeichnungFormatter = (value) => <Text>{value ? getLabel(value, krankenversicherungOptions) : "keine Angabe"}</Text>;
	const arrayLengthFormatter = (value) => <Text>{value ? getJaNein(value.length > 0) : "keine Angabe"}</Text>;
	const monatJahrFormatter = (value) => <Text>{value ? getMonatJahr(value) : "keine Angabe"}</Text>;

	const mietobjekteFormatter = (wohnsitze) => {
		const mietobjekte = wohnsitze.filter((wohnsitz) => wohnsitz.auswahl === "mietobjekt");

		if (mietobjekte.length === 0) {
			return <Text>Keine Mietobjekte vorhanden</Text>;
		}

		return (
			<>
				{mietobjekte.map((mietobjekt, index) => (
					<Text key={index} style={{ marginBottom: 4 }}>
						{mietobjekt.strasse + ", " + mietobjekt.plz + " " + mietobjekt.ort + "; " + mietobjekt.wohnflaeche + " qm"}
					</Text>
				))}
			</>
		);
	};

	const eigentumFormatter = (wohnsitze) => {
		const eigentumsobjekte = wohnsitze.filter((wohnsitz) => wohnsitz.auswahl === "eigentum");

		if (eigentumsobjekte.length === 0) {
			return <Text>Keine Eigentumsobjekte vorhanden</Text>;
		}

		return (
			<>
				{eigentumsobjekte.map((eigentum, index) => (
					<View
						key={index}
						style={{
							flexDirection: "column",
							width: "100%",
						}}
					>
						<Text style={{ flexDirection: "row", marginBottom: 4 }}>
							{"Wohnsitz " +
								(index + 1) +
								": " +
								eigentum.strasse +
								", " +
								eigentum.plz +
								" " +
								eigentum.ort +
								"; " +
								eigentum.eigene_immobilie.wohnflaeche +
								" qm; Baujahr: " +
								getJahr(eigentum.eigene_immobilie.baujahr) +
								"; PV: " +
								getJaNein(eigentum.eigene_immobilie.photovoltaik) +
								"; Öltank: " +
								getJaNein(eigentum.eigene_immobilie.oeltank) +
								"; Baumaßnahmen/Sanierung geplant: " +
								getJaNein(eigentum.eigene_immobilie.sanierung_geplant)}
						</Text>
					</View>
				))}
			</>
		);
	};

	const HMRow = ({ label, items }) => {
		return (
			<View style={[styles.row, styles.alignTop]}>
				<View style={styles.col0}>
					<Text>{label}</Text>
				</View>
				<View style={styles.col1}>
					{items.map((item, index) => {
						const entity = item.useHaushalt ? haushalt : person1.data;
						return (
							<View key={index} style={{ flexDirection: "column", marginBottom: 4 }}>
								<Text style={{ fontWeight: "bold" }}>{item.label}: </Text>
								{item.formatter(getNestedValue(entity, item.selector))}
							</View>
						);
					})}
				</View>
				<View style={styles.col2}>
					{items.map((item, index) => {
						const entity = item.useHaushalt ? haushalt : person2.data;
						return (
							<View key={index} style={{ flexDirection: "column", marginBottom: 4 }}>
								<Text style={{ fontWeight: "bold" }}>{item.label}: </Text>
								{item.formatter(getNestedValue(entity, item.selector))}
							</View>
						);
					})}
				</View>
			</View>
		);
	};

	HMRow.propTypes = {
		label: PropTypes.string,
		items: PropTypes.array,
	};

	return (
		<View style={styles.table}>
			<View style={[styles.row, styles.bold, styles.header]}>
				<Text style={styles.col0}>Information</Text>
				<Text style={styles.col1}>
					{getDisplayName(person1.data)} {rolleFormatter(person1)}
				</Text>
				<Text style={styles.col2}>
					{getDisplayName(person2.data)} {rolleFormatter(person2)}
				</Text>
			</View>
			<HMRow
				label="Persönliches"
				items={[
					{ label: "Familienstand", selector: "familienstand", formatter: noneFormatter },
					{ label: "Geburtsdatum", selector: "geburtsdatum", formatter: datumFormatter },
				]}
			/>
			<HMRow
				label="Finanzen"
				items={[
					{ label: "besondere Tätigkeit", selector: "besondere_taetigkeit", formatter: noneFormatter },
					{ label: "Nettoerwerbseinkommen", selector: "monatseinkommen.nettogehalt", formatter: euroZeroFormatter },
					{ label: "Nettovermögenseinkommen", selector: "monatseinkommen.nettovermoegenseinkommen", formatter: euroZeroFormatter },
					{ label: "Sonstige Einnahmen", selector: "monatseinkommen.sonstige_einnahmen", formatter: euroZeroFormatter },
					{ label: "Krankenversicherung", selector: "versicherung_kranken_pflege", formatter: bezeichnungFormatter },
					{ label: "Beitrag Krankenversicherung", selector: "versicherung_kranken_pflege_beitrag", formatter: euroZeroFormatter },
					{ label: "Beitrag Pflegeversicherung", selector: "versicherung_kranken_pflege_ppv", formatter: euroZeroFormatter },
				]}
			/>
			<HMRow
				label="Wohnsituation"
				items={[
					{ label: "Mietobjekte", selector: "wohnsitz", useHaushalt: true, formatter: mietobjekteFormatter },
					{ label: "Eigentum", selector: "wohnsitz", useHaushalt: true, formatter: eigentumFormatter },
				]}
			/>
			<HMRow
				label="Risiken"
				items={[
					{ label: "Halter Kfz", selector: "fahrzeuge.autos", useHaushalt: true, formatter: arrayLengthFormatter },
					{ label: "Halter Luftfahrzeug", selector: "fahrzeuge.flugzeuge", useHaushalt: true, formatter: arrayLengthFormatter },
					{ label: "Halter Wasserfahrzeug", selector: "fahrzeuge.boote", useHaushalt: true, formatter: arrayLengthFormatter },
					{ label: "Halter Sonstiges", selector: "fahrzeuge.sonstige_fahrzeuge", useHaushalt: true, formatter: arrayLengthFormatter },
					{ label: "Jagdsport", selector: "hobbies_und_aktivitaeten", useHaushalt: true, formatter: arrayLengthFormatter },
					{ label: "Hund/Pferd", selector: "haustiere", useHaushalt: true, formatter: arrayLengthFormatter },
				]}
			/>
			<HMRow label="Ruhestand" items={[{ label: "Beginn", selector: "ruhestandsbeginn", formatter: monatJahrFormatter }]} />
		</View>
	);
};

HaushaltsmitgliederCard.propTypes = {
	haushalt: PropTypes.object,
	person1: PropTypes.object,
	person2: PropTypes.object,
};
