import { besondereTaetigkeitOptions, taetigkeitsstatusOptions } from "services/optionsService";

export const defTemplateSdE = [
	{
		name: "taetigkeitsstatus",
		label: "Tätigkeitsstatus",
		control: "button_options",
		cols: 12,
		options: taetigkeitsstatusOptions,
	},
	{
		name: "alter_bei_berufseintritt",
		label: "Alter bei Berufseintritt",
		control: "numberinput",
		unit: "Jahre",
		cols: 12,
	},
	{
		name: "besondere_taetigkeit",
		label: "besondere Tätigkeit",
		control: "button_checkboxes",
		info: {
			title: "Hinweise",
			description:
				"Erfassen Sie hier bitte besondere ausgeübte Tätigkeiten: Betriebsbeauftragte: Angestellte mit besonderen Aufgaben/Betriebsbeauftragte wie z.B. Datenschutzbeaufragte, Brandschutzbeauftragte; Organe jur. Person/Verein: z.B. Vorstände, GmbH-Geschäftsführer, Aufsichtsräte, Beiräte, Vereinsvorstände; Syndikusanwalt/ang. Arzt; Ehrenamt: ehrenamtliche Tätigkeiten wie Trainer, Helfer usw.",
		},
		cols: 12,
		options: besondereTaetigkeitOptions,
	},
	{
		control: "upload",
		name: "gehaltsdokumente",
		label: "Gehaltsdokumente",
		hide_label: true,
		multiple: true,
		dok_types: ["Gehaltsabrechnung", "Rentenbescheid", "sonstiges"],
	},
	{
		control: "section",
		name: "monatseinkommen",
		label: "Monatseinkommen",
		info: {
			title: "Hinweise",
			description:
				"Das Monatseinkommen setzt sich aus dem Nettoerwerbseinkommen, dem Nettovermögenseinkommen und den sonstigen Einnahmen zusammen. Sofern eines oder mehrere der Einkommensarten vorhanden ist, sollten diese angegeben werden, da die weiteren Berechnungen darauf basieren.",
		},
		cols: 12,
		children: [
			/*{
				name: "bruttogehalt",
				label_short: "Bruttogehalt",
				label: "bei Rentnern Bruttorente",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 25000,
				step: 50,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
			},*/
			{
				name: "nettogehalt",
				label_short: "Nettoerwerbseinkommen",
				label: "bei Rentnern Nettorente",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 10000,
				step: 50,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
				info: {
					title: "Hinweise",
					description: "Das Nettoerwerbseinkommen ist das Bruttoerwerbseinkommen abzüglich der Steuern und der Sozialversicherungsabgaben. Diese Angabe dient zur Berechnung des Schuldenrisikos, sowie der Risiken des Arbeitskraftverlustes.",
				},
			},
			{
				name: "nettovermoegenseinkommen",
				label: "Nettovermögenseinkommen",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 5000,
				step: 50,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
				info: {
					title: "Hinweise",
					description: "Das Nettovermögenseinkommen sind alle Einnahmen aus Ihrem Vermögen abzüglich der Steuern. Es dient zur Berechnung verschiedener Risiken.",
				},
			},
			{
				name: "sonstige_einnahmen",
				label: "Sonstige Einnahmen",
				unit: "€",
				default: 0,
				min: 0,
				max: 5000,
				step: 50,
				unit_type: "currency_no_digit",
				control: "sliderinput",
				cols: 12,
				prefix: "über",
				info: {
					title: "Hinweise",
					description: "Unter sonstige Einnahmen fallen zum Beispiel Einnahmen aus Unterhaltszahlungen oder auch Kindergeldzahlungen. Diese dienen zur Berechnung verschiedener Risiken.",
				},
			},
		],
	},
	{
		name: "nettogesamtvermoegen", //ToDo: suchen + ersetzen
		label: "Nettogesamteinkommen",
		control: "number_label",
		unit: "€",
		unit_type: "currency_no_digit",
		disabled: true,
		cols: 12,
	},
];
