import { Box, Chip, Tooltip, Typography, useTheme } from "@mui/material";
import DMTable from "components/DMTable";
import { convertPunktToNested, getEmptyModel } from "components/FormRenderer/DMFhelperService";
import { fdefDIN77230 } from "formdefs/din";
import { fdefKunde } from "formdefs/kunde";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { bedarfAtom } from "recoil/atoms/bedarfAtom";
import { haushaltAtom } from "recoil/atoms/haushaltAtom";
import { getDatum } from "services/formatService";
import { createExtendedPerson } from "services/helperService";

KundeList.propTypes = {
	data: PropTypes.array,
};

export default function KundeList(props) {
	const { data } = props;
	const [bedarf, setBedarf] = useRecoilState(bedarfAtom);
	const [haushalt, setHaushalt] = useRecoilState(haushaltAtom);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const theme = useTheme();

	const handleSetCustomer = (row) => {
		const emptyModel = convertPunktToNested(getEmptyModel(fdefDIN77230.formdef));
		emptyModel.kunde = createExtendedPerson(row);
		const emptyKunde = { kunde: row };
		emptyKunde.kinder = [];
		emptyModel.kinder = [];

		if (row.haushalt_id) {
			const haushaltsmitglieder = data.filter((person) => person.haushalt_id === row.haushalt_id && person.id !== row.id);
			for (const member of haushaltsmitglieder) {
				if (member.art === "kunde" && row.id !== member.id) {
					emptyKunde.partner = createExtendedPerson(member);
					emptyModel.partner = createExtendedPerson(member);
				} else {
					emptyKunde.kinder.push(createExtendedPerson(member));
					emptyModel.kinder.push(createExtendedPerson(member));
				}
			}
		}
		if (row.haushalt) emptyModel.haushalt = row.haushalt;

		setHaushalt(emptyKunde);
		setBedarf(emptyModel);
		navigate("/kunde");
	};

	const columnsDefiniton = [
		{
			accessorKey: "kunde",
			accessorFn: (row) => `${row.nachname}, ${row.vorname}`,
			header: "Kunde",
			size: 100,
		},
		{
			accessorKey: "geburtsdatum",
			header: "Geburtsdatum",
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return <span>{getDatum(objectToRender.geburtsdatum)}</span>;
			},
			size: 100,
		},
		{
			accessorFn: (row) => `${row.strasse} ${row.hausnummer}, ${row.plz} ${row.ort}`,
			header: "Wohnort",
			size: 200,
		},
		{
			accessorKey: "email",
			header: "E-Mail",
			size: 100,
		},
		{
			accessorFn: (row) => `${row.tel_mobil}`,
			header: "Mobil",
			size: 80,
		},
		{
			header: "Verträge",
			size: 100,
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;

				return objectToRender.vertraege.map((sparte) => {
					return (
						<Tooltip key={sparte.label} title={sparte.label}>
							<Chip label={sparte.value} variant="filled" sx={{ m: "2px", p: "0px", height: "16px", fontSize: "12px", borderRadius: "4px" }} />
						</Tooltip>
					);
				});
			},
		},
		{
			header: "Maklerdokumente",
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return <Chip label={objectToRender.status_maklerdokumente} color={objectToRender.status_maklerdokumente === "vollständig" ? "success" : "warning"} variant="outlined" size="small" />;
			},
			size: 100,
		},
	];

	const fnRenderDetailPanel = (row) => {
		return (
			<Box
				sx={{
					alignItems: "left",
					left: "30px",
					maxWidth: "1000px",
					position: "sticky",
					width: "100%",
				}}
			>
				<Typography variant="body">Kunde seit: {getDatum(row.original.custom?.kunde_seit)}</Typography>
				<br />
				<Typography variant="body">letzter Aufruf: {getDatum(row.original.custom?.letzter_aufruf)}</Typography>
				<br />
				<Typography variant="body">letzte Aktualisierung: {getDatum(row.original.custom?.letzte_aktualisiert)}</Typography>
				<br />
				<Typography variant="body">Kundennumer CRM: {getDatum(row.original.custom?.kundennummer_crm)}</Typography>
				<br />
				<Typography variant="body">Haushaltsnummer CRM: {getDatum(row.original.custom?.haushaltsnummer_crm)}</Typography>
				<br />
				<Typography variant="body">Telefon: {getDatum(row.original.tel_privat)}</Typography>
			</Box>
		);
	};

	const createKunde = () => {
		const emptyModel = convertPunktToNested(getEmptyModel(fdefKunde.formdef));
		setHaushalt(emptyModel);
		setBedarf(emptyModel);
		navigate("/kunde/neu");
	};

	const content = () => {
		return (
			<Box sx={{ pt: "0px" }}>
				<Typography variant="h4">Alle Kunden</Typography>
				<DMTable
					data={data?.filter((person) => person.art === "kunde")}
					columns={columnsDefiniton}
					rowAction={handleSetCustomer}
					searchword=""
					initialStateConfig={{
						sorting: [
							{
								id: "kunde",
								asc: true,
							},
						],
					}}
					fnRenderDetailPanel={fnRenderDetailPanel}
					handleCreate={() => createKunde()}
				/>
			</Box>
		);
	};

	return <Fragment>{content()}</Fragment>;
}
