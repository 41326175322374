import { geschlechtOptions } from "services/optionsService";

export const defTemplatePerson = [
	{
		name: "vorname",
		label: "Vorname",
		control: "input",
		cols: 4,
		default: "",
	},
	{
		name: "nachname",
		label: "Nachname",
		control: "input",
		cols: 4,
		default: "",
	},
	{
		name: "geburtsdatum",
		label: "Geburtsdatum",
		control: "date_picker",
		cols: 4,
		default: "",
	},

	{
		name: "geschlecht",
		label: "Geschlecht",
		control: "button_options",
		cols: 12,
		hide_label: true,
		default: "keine_angabe",
		options: geschlechtOptions,
	},
];
