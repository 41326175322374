import { Box, Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { containerRendererFlex, convertPunktToNested, getEmptyModel, itemRendererFlex } from "components/FormRenderer/DMFhelperService";
import FormRenderer from "components/FormRenderer/FormRenderer";
import GaugeChart from "components/GaugeChart";
import Tile from "components/Tile";
import { fdefDIN77230 } from "formdefs/din";
import { fdefKunde } from "formdefs/kunde";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { bedarfAtom } from "recoil/atoms/bedarfAtom";
import { haushaltAtom, kundeDisplayNameSelector } from "recoil/atoms/haushaltAtom";
import { vertragszuordnungAtom } from "recoil/atoms/vertragszuordnungAtom";
import { backendGet, backendPost } from "services/dataService";
import { calcIst, getFamilienScore } from "services/DINIstCalculationService";
import { calcSoll } from "services/DINSollCalculationService";
import { getAlter, getDatum } from "services/formatService";
import { createExtendedPerson } from "services/helperService";
import AnalyseErgebnisse from "./AnalyseErgebnisse";
import Beziehungen from "./Beziehungen";
import CustomIcon from "./CustomIcon";
import Telekommunikation from "./Telekommunikation";
import FamilyScore from "./FamilyScore";

export default function Kunde() {
	const [haushalt, setHaushalt] = useRecoilState(haushaltAtom);
	const kundeDisplayName = useRecoilValue(kundeDisplayNameSelector);
	const [editMode, setEditMode] = useState(false);
	const appWindow = useRecoilValue(appWindowAtom);
	const [formValues, setFormValues] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [bedarf, setBedarf] = useRecoilState(bedarfAtom);
	const { param } = useParams();

	useEffect(() => {
		if (param) {
			setEditMode(true);
		}
	}, [param]);

	const valuesCallBack = useCallback((values, setValue) => {
		for (let kind of values.kinder) {
			if (kind.kindergeldanspruch === null) {
				let anspruch = false;
				if (getAlter(kind.geburtsdatum) < 18) anspruch = true;
				if (getAlter(kind.geburtsdatum) < 25 && kind.taetigkeitsstatus !== null) anspruch = true;
				if (kind.taetigkeitsstatus === "behinderung" && (kind.verheiratet === null || kind.verheiratet === false)) anspruch = true;
				kind.kindergeldanspruch = anspruch;
			}
		}

		setFormValues(values);
	}, []);

	const memoizedFormRenderer = useMemo(
		() => (
			<FormRenderer
				containerRenderer={containerRendererFlex}
				itemRenderer={itemRendererFlex}
				fdef={fdefKunde}
				variant="filled"
				size="small"
				valuesCallBack={valuesCallBack}
				model={haushalt} //{convertPunkt ToNested(getEmptyModel(fdefDIN77230.formdef))}
			/>
		),
		[valuesCallBack, haushalt]
	);

	const storeKunde = async () => {
		backendPost({ label: "Kunde", route: "kunden/store", toSend: formValues, setResult: setSubmitResult, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	};

	const setSubmitResult = (data) => {
		setHaushalt(data.kunde);
		const emptyModel = convertPunktToNested(getEmptyModel(fdefDIN77230.formdef));
		emptyModel.kunde = createExtendedPerson(data.kunde.kunde);
		emptyModel.partner = createExtendedPerson(data.kunde.partner);
		emptyModel.kinder = data.kunde.kinder.map((kind) => createExtendedPerson(kind));
		setBedarf(emptyModel);
		setEditMode(false);
	};

	if (editMode === true)
		return (
			<Box sx={{ width: "100%" }}>
				<Box sx={{ backgroundColor: "rgba(62, 79, 147, 0.08)", padding: "24px", alignItems: "center", display: "flex", justifyContent: "space-between", margin: "0px" }}>
					<Box sx={{}}>Bearbeiten</Box>
					<Box>
						<Button variant="outlined" onClick={() => setEditMode(false)}>
							Abbrechen
						</Button>
						<Button variant="contained" sx={{ ml: "16px", textTransform: "none" }} onClick={() => storeKunde()}>
							Speichern und schließen
						</Button>
					</Box>
				</Box>
				<Box sx={{ height: appWindow.contentHeight - 98 - 24 + "px", overflowY: "auto", padding: "24px" }}>{memoizedFormRenderer}</Box>
			</Box>
		);

	return (
		<>
			<Box sx={{ width: "100%", padding: "24px" }}>
				<Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", margin: "0px", pb: "32px" }}>
					<Typography variant="h6" sx={{ pb: "16px" }}>
						Kundendaten
					</Typography>
					<IconButton onClick={() => setEditMode(true)}>
						<CustomIcon name="EditFilled" size={24} />
					</IconButton>
				</Box>
				<Box sx={{ display: "flex" }}>
					<Box sx={{ width: "50%", mr: "24px" }}>
						<Tile sx={{ height: undefined, mb: "16px", pb: "0px", textAlign: "center", backgroundColor: "#FFFCF5", cursor: "pointer" }}>
							<FamilyScore />
						</Tile>
						<Tile sx={{ height: undefined, mb: "16px" }}>
							<Box style={{ display: "flex", alignItems: "center" }}>
								<Box sx={{ pr: "16px" }}>
									<CustomIcon name="Person" size={48} />
								</Box>
								<Box>
									<Typography variant="h6">{kundeDisplayName}</Typography>
									<Typography variant="body1">
										{getDatum(haushalt?.kunde.geburtsdatum)} | {haushalt?.kunde.strasse} {haushalt?.kunde.hausnummer}, {haushalt?.kunde.plz} {haushalt?.kunde.ort}
									</Typography>
								</Box>
							</Box>
						</Tile>
						<Telekommunikation />
					</Box>
					<Box sx={{ width: "50%" }}>
						<Beziehungen />
					</Box>
				</Box>
			</Box>
		</>
	);
}
