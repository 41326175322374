import { Box, Typography } from "@mui/material";
import DIN_Euro_List from "components/DIN_Euro_List";
import PropTypes from "prop-types";
import { Fragment, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { bedarfAtom } from "recoil/atoms/bedarfAtom";
import { getEuro } from "services/formatService";
import { ausgabenOptions, einnahmenOptions } from "services/optionsService";
import useIsVisible from "./useIsVisble";

DIN_Haushaltsrechnung.propTypes = {
	config: PropTypes.object,
};

export default function DIN_Haushaltsrechnung(props) {
	const bedarf = useRecoilValue(bedarfAtom);
	const ref = useRef();
	const isVisible = useIsVisible(ref);
	const [aktiva, setAktiva] = useState([]);
	const [passiva, setPassiva] = useState([]);

	useEffect(() => {
		if (bedarf) {
			const aktivaValues =
				bedarf.haushalt?.einnahmen?.map((item) => ({
					...item,
					label: einnahmenOptions.find((labelItem) => labelItem.value === item.bezeichnung)?.label,
					icon: "MoneyIncome",
					value: item.wert,
				})) || [];
			const passivaValues =
				bedarf.haushalt?.ausgaben?.map((item) => ({
					...item,
					label: ausgabenOptions.find((labelItem) => labelItem.value === item.bezeichnung)?.label,
					icon: "MoneySpending",
					value: item.wert,
				})) || [];

			const maxLength = Math.max(aktivaValues.length, passivaValues.length);
			const paddedAktiva = [...aktivaValues, ...Array(maxLength - aktivaValues.length).fill({ value: null })];
			const paddedPassiva = [...passivaValues, ...Array(maxLength - passivaValues.length).fill({ value: null })];

			setAktiva(paddedAktiva);
			setPassiva(paddedPassiva);
		}
	}, [bedarf]);

	const gesamtSumme = aktiva?.reduce((acc, item) => acc + item.value, 0) - passiva?.reduce((acc, item) => acc + item.value, 0);
	const labelSum = "Saldo";

	return (
		<div ref={ref} style={{ width: "100%" }}>
			{isVisible ? (
				<Fragment>
					<Box sx={{ display: "flex", alignItems: "stretch", pb: "30px" }}>
						<DIN_Euro_List items={aktiva} label="Einnahmen" labelSum="Summe" sx={{ mr: "16px", flex: 1 }} />
						<DIN_Euro_List items={passiva} label="Ausgaben" labelSum="Summe" sx={{ flex: 1 }} />
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							pl: "16px",
							pr: "16px",
							pt: "8px",
							pb: "8px",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
							<Box sx={{ pr: "16px", flexShrink: 0 }}>
								<Typography variant="body1">{labelSum}</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									overflow: "hidden",
									flexGrow: 1,
									textAlign: "right",
									minWidth: 0, // Wichtig für text-overflow
								}}
							>
								<Typography variant="h6">{getEuro(gesamtSumme, 0)}</Typography>
							</Box>
						</Box>
					</Box>
				</Fragment>
			) : null}
		</div>
	);
}
