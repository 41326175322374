import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { getLabel } from "services/formatService";
import { getChartColor } from "services/chartService";

const styles = StyleSheet.create({
	table: {
		width: "100%",
		border: "1px solid gray",
		borderRadius: 8,
		fontSize: 14,
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		paddingTop: 6,
		paddingBottom: 6,
	},
	bullet: {
		width: 16,
		height: 16,
		borderRadius: "50%",
		marginRight: 10,
		marginLeft: 10,
	},
});

export const PieChartTable = ({ data, mappings = [] }) => {
	const total = data.reduce((acc, curr) => acc + curr.wert, 0);
	return (
		<View style={styles.table}>
			{data.map((row, i) => (
				<View key={`pie_chart_table_row${i}`} style={[styles.row, { borderTop: i === 0 ? "none" : "1px solid gray" }]} wrap={false}>
					<View style={[styles.bullet, { backgroundColor: getChartColor(i) }]} />
					<Text style={{ fontSize: 10, width: "80%" }}>{`${getLabel(row.bezeichnung, mappings)} ${row.wert} €, ${Math.round((row.wert / total) * 100)}%`}</Text>
				</View>
			))}
		</View>
	);
};

PieChartTable.propTypes = {
	data: PropTypes.array,
	mappings: PropTypes.array,
};
