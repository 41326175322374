/* eslint-disable react/prop-types */
import { Document, Font, Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import { ArcElement, CategoryScale, Chart as ChartJS, LinearScale } from "chart.js";
import Spinner from "components/Spinner";
import PropTypes from "prop-types";
import { Fragment, useRef, useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { gaugeChartOptions, generalChartOptions, getChart, getChartColor } from "services/chartService";
import { groupRang } from "services/DINIstCalculationService";
import {
	BAFOEG_HOECHSTSATZ,
	BEITRAGSATZ_PPV_MAX,
	BEITRAGSATZ_PPV_MIN,
	BEITRAGSBEMESSUNGSGRENZE_KV,
	BEITRAGSSATZ_AV,
	BEITRAGSSATZ_KV,
	BEITRAGSSATZ_RV,
	DURSCHNITTLICHER_ZUSATZBEITRAG_KV,
	HALBER_REGELBEITRAG_GRV_OST,
	HALBER_REGELBEITRAG_GRV_WEST,
	INFLATIONSRATE,
	KINDERLOSENZUSCHLAG_PPV,
	MINDESTBEDARF,
	MINDESTBEITRAG_GKV_PPV_MIT_KIND,
	MINDESTBEITRAG_GKV_PPV_OHNE_KIND,
	MINDESTBEITRAGSBEMESSUNGSGRUNDLAGE,
	RENDITEKLASSE_1,
	RENDITEKLASSE_2,
} from "services/DINSollCalculationService";
import { getDatum, getEuro, getProzent } from "services/formatService";
import { BasicTable } from "./components/BasicTable";
import { BigTable } from "./components/BigTable";
import { BodyText } from "./components/BodyText";
import { CardItem } from "./components/CardItem";
import { ChartTable } from "./components/ChartTable";
import { ContentHeader } from "./components/ContentHeader";
import { ContentSubHeader } from "./components/ContentSubHeader";
import { HaushaltsmitgliederCard } from "./components/HaushaltsmitgliederCard";
import { ItemsTable } from "./components/ItemsTable";
import { ListItem } from "./components/ListItem";
import { ParagraphHeader } from "./components/ParagraphHeader";
import { PersonCard } from "./components/PersonCard";
import { PieChartTable } from "./components/PieChartTable";
import { RahmenparameterTable } from "./components/RahmenparameterTable";
import { VSpacer } from "./components/VSpacer";
import { BasicPage } from "./pages/BasicPage";
import { ColumnPage } from "./pages/ColumnPage";
import { FrontPage } from "./pages/FrontPage";
import { LastPage } from "./pages/LastPage";
import { vermoegenswerteOptions, verbindlichkeitenOptions, einnahmenOptions, ausgabenOptions, investitionszieleOptions } from "services/optionsService";

ChartJS.register(CategoryScale, LinearScale, ArcElement);

// FONTS
Font.register({
	family: "FoundryMonoline",
	fonts: [
		{ src: import.meta.env.VITE_BACKEND + "/fonts/Founml.ttf", fontWeight: "light" }, // normal
		{ src: import.meta.env.VITE_BACKEND + "/fonts/Founmm.ttf", fontWeight: "medium" }, // normal
		{ src: import.meta.env.VITE_BACKEND + "/fonts/Founmrg.ttf", fontWeight: "normal" }, // normal
		{ src: import.meta.env.VITE_BACKEND + "/fonts/Founmb.ttf", fontWeight: "bold" },
	],
});

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
	chartContainer: {
		border: "1px solid gray",
		borderRadius: 8,
	},
	cardContainer: {
		display: "flex",
		flexDirection: "column",
		gap: 16,
	},
});

export const FinanzkonzeptPDF = (props) => {
	const { pdfModel } = props;

	const kinder = pdfModel.bedarf.kinder;

	const kinderPaare = [];
	for (let i = 0; i < kinder.length; i += 2) {
		const kind1 = kinder[i];
		const kind2 = i + 1 < kinder.length ? kinder[i + 1] : null;
		kinderPaare.push({ kind1: kind1, kind2: kind2 });
	}

	const bedarfsstufe1 = groupRang(pdfModel.displaywerte.filter((item) => item.relevant && item.bedarfsstufe === "1"));
	const bedarfsstufe2 = groupRang(pdfModel.displaywerte.filter((item) => item.relevant && item.bedarfsstufe === "2"));
	const bedarfsstufe3 = groupRang(pdfModel.displaywerte.filter((item) => item.relevant && item.bedarfsstufe === "3"));

	const bedarfsstufe1Unter100 = groupRang(pdfModel.displaywerte.filter((item) => item.relevant && item.bedarfsstufe === "1" && item.ist_percent < 100));
	const bedarfsstufe2Unter100 = groupRang(pdfModel.displaywerte.filter((item) => item.relevant && item.bedarfsstufe === "2" && item.ist_percent < 100));
	const bedarfsstufe3Unter100 = groupRang(pdfModel.displaywerte.filter((item) => item.relevant && item.bedarfsstufe === "3" && item.ist_percent < 100));

	return (
		<Document>
			<FrontPage pdfModel={pdfModel} />
			<ColumnPage
				pdfModel={pdfModel}
				columns={[
					{
						content: (
							<View>
								<BodyText>Mit Ihrem Auftrag zur Erstellung Ihrer persönlichen Finanzanalyse nach DIN-Norm 77230 haben Sie eine gute und wichtige Entscheidung getroffen.</BodyText>
								<BodyText>Dieses Dokument beinhaltet die für Sie relevanten Finanzthemen in Bezug auf die Themenbereiche Absicherung, Vorsorge und Vermögensplanung.</BodyText>
								<BodyText>
									Es bildet die Basis für eine auf Ihre individuelle Lebenssituation zugeschnittene Finanzplanung. In der vorliegenden Finanzanalyse werden zusätzlich zu den Vorgaben der Norm die Lücken der einzelnen Finanzthemen sowie
									davon abgeleitete Zielerreichungsgrade dargestellt.
								</BodyText>
								<BodyText>Das Ergebnis: eine sinnvolle, ganzheitliche Analyse der finanziellen Situation unter Berücksichtigung der genannten Punkte.</BodyText>
								<BodyText>Das Analyseergebnis dient Ihnen als grundsätzliche Orientierung und Entscheidungsgrundlage für unsere weitere Zusammenarbeit.</BodyText>
								<BodyText>Vielen Dank für Ihr Vertrauen.</BodyText>
								<BodyText noMargin={true}>
									Ihr {pdfModel.berater.vorname} {pdfModel.berater.nachname}
								</BodyText>
								<BodyText noMargin={true}>{pdfModel.berater.custom.telefon}</BodyText>
								<BodyText noMargin={true}>{pdfModel.berater.email}</BodyText>
							</View>
						),
					},
					{
						content: (
							<View style={{ display: "flex", flexDirection: "column", gap: 16, width: "100%", textAlign: "left" }}>
								{pdfModel.norms.map((norm, i) => (
									<CardItem key={`norm_${i}`} icon={norm.icon} header={norm.header} text={norm.text} />
								))}
							</View>
						),
					},
				]}
			/>
			<ColumnPage
				pdfModel={pdfModel}
				columns={[
					{
						content: (
							<View>
								<ContentHeader>Inhalt</ContentHeader>
								{pdfModel.inhalte.map((item, i) => (
									<View key={`inhatle_${i}`}>
										<ListItem>
											{i + 1}. {item}
										</ListItem>
										{i !== pdfModel.inhalte.length - 1 && <VSpacer height={10} />}
									</View>
								))}
							</View>
						),
					},
					{
						flex: 2,
						content: (
							<View>
								<Image src={import.meta.env.VITE_BACKEND + "/Inhalte.png"} />
							</View>
						),
					},
				]}
			/>
			<BasicPage pdfModel={pdfModel}>
				<ContentHeader>Investitionsziele</ContentHeader>
				<BasicTable data={pdfModel.bedarf.haushalt.investitionsziele} mappings={investitionszieleOptions} />
			</BasicPage>
			<BasicPage pdfModel={pdfModel}>
				<ContentHeader>Persönliche und berufliche Ziele</ContentHeader>
				<BasicTable data={Array.from({ length: 10 }, () => ({}))} />
			</BasicPage>
			<ColumnPage
				pdfModel={pdfModel}
				contentGap={20}
				showSum={true}
				labelSum={"Liquidität"}
				valueSum={pdfModel.bedarf.haushalt.einnahmen.reduce((acc, curr) => acc + curr.wert, 0) - pdfModel.bedarf.haushalt.ausgaben.reduce((acc, curr) => acc + curr.wert, 0)}
				columns={[
					{
						content: (
							<View>
								<ContentHeader>Einnahmen - Ausgaben - Rechnung</ContentHeader>
								<View>
									<BodyText>
										Die Einnahmen-/Ausgabenrechnung stellt Ihr Nettogesamteinkommen den Ausgaben gegenüber. Das Nettogesamteinkommen besteht aus ihrem Erwerbseinkommen und ggf. weiteren Einnahmen aus Vermietung & Verpachtung,
										Kapitalvermögen, Renten oder sonstigen Einnahmen. Die Ausgaben setzen sich aus ihrem Konsum, den Kosten für Wohnen, Zins-/Tilgungsleistungen sowie ihren Ausgaben in den Bereichen Absicherung, Vorsorge und
										Vermögensplanung zusammen. Die Erfassung dient dazu, dass Verhältnis ihrer Ein- und Ausgaben übersichtlich darzustellen.
									</BodyText>
								</View>
							</View>
						),
					},
					{
						textAlign: "left",
						content: <ItemsTable header="Einnahmen" data={pdfModel.bedarf.haushalt.einnahmen} mappings={einnahmenOptions} footerText="Gesamteinnahmen" />,
					},
					{
						textAlign: "left",
						content: <ItemsTable header="Ausgaben" data={pdfModel.bedarf.haushalt.ausgaben} mappings={ausgabenOptions} footerText="Gesamtausgaben" negativeSum={true} />,
					},
				]}
			/>
			<ColumnPage
				pdfModel={pdfModel}
				columns={[
					{
						content: (
							<View style={{ width: "75%", alignSelf: "center", justifyContent: "center", alignItems: "center" }}>
								<ContentHeader textAlign="center">Ausgaben auf einen Blick</ContentHeader>
								<View style={{ height: "100%", width: "100%", alignSelf: "center", justifyContent: "center", alignItems: "center" }}>
									{getChart("chartAusgabenMonat", { width: 300, height: 300 })}
									<View style={{ position: "absolute", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
										<Text style={{ fontSize: 16, color: "rgba(0, 0, 0, 0.6)" }}>Ausgaben/ Monat</Text>
										<Text style={{ fontSize: 48, color: "rgba(0, 0, 0, 0.87)", textAlign: "center" }}>
											{getEuro(
												pdfModel.bedarf.haushalt.ausgaben.reduce((acc, curr) => acc + curr.wert, 0),
												0
											)}
										</Text>
									</View>
								</View>
							</View>
						),
					},
					{
						content: (
							<View style={{ width: "75%", height: "100%", alignSelf: "center", justifyContent: "center", alignItems: "center" }}>
								<ContentHeader textAlign="center"></ContentHeader>
								<View style={{ height: "100%", width: "100%", alignSelf: "center", justifyContent: "center", alignItems: "center" }}>
									<PieChartTable data={pdfModel.bedarf.haushalt.ausgaben} mappings={ausgabenOptions} />
								</View>
							</View>
						),
					},
				]}
			/>
			<ColumnPage
				pdfModel={pdfModel}
				contentGap={20}
				showSum={true}
				valueSum={pdfModel.bedarf.haushalt.vermoegenswerte.reduce((acc, curr) => acc + curr.wert, 0) - pdfModel.bedarf.haushalt.verbindlichkeiten.reduce((acc, curr) => acc + curr.wert, 0)}
				columns={[
					{
						content: (
							<View>
								<ContentHeader>Vermögensbilanz</ContentHeader>
								<ItemsTable header="Aktiva" data={pdfModel.bedarf.haushalt.vermoegenswerte} mappings={vermoegenswerteOptions} footerText="Nettovermögen" />
							</View>
						),
					},
					{
						content: (
							<View>
								<ContentHeader> </ContentHeader>
								<ItemsTable header="Passiva" data={pdfModel.bedarf.haushalt.verbindlichkeiten} mappings={verbindlichkeitenOptions} footerText="Verbindlichkeiten" negativeSum={true} />
							</View>
						),
					},
				]}
			/>
			<ColumnPage
				pdfModel={pdfModel}
				columns={[
					{
						content: (
							<View>
								<ContentHeader>Persönlicher Finanzscore</ContentHeader>
								<View style={styles.chartContainer}>
									<View style={{ position: "absolute", top: 10, left: 10 }}>
										<ContentSubHeader>Familien-Score</ContentSubHeader>
									</View>
									<View style={{ alignSelf: "center", justifyContent: "center", alignItems: "center" }}>
										{getChart("chartFamilienscore", { width: 200, height: 200 })}
										<View style={{ position: "absolute", bottom: 50 }}>
											<Text style={{ fontSize: 32, color: "rgba(0, 0, 0, 0.87)", marginLeft: 15 }}>{pdfModel.familienScore}%</Text>
										</View>
									</View>
								</View>
								<VSpacer />
								<BodyText>
									Mit einem leicht verständlichen Scoring-System für die Bedarfsstufen „Sicherung des finanziellen Grundbedarfs“ und „Erhaltung des Lebensstandards“ werden die Ergebnisse der DIN-Analyse dargestellt, sehr transparent und
									einfach nachvollziehbar. Somit ist eine klare Orientierung möglich, welche Finanzprodukte in der aktuellen Lebensphase benötigt werden und welche nicht. Ihr Vorteil: Sie geben keinen Cent zu viel für unnötige Produkte aus,
									wichtige Absicherungen werden erkannt und Risiken werden minimiert.
								</BodyText>
							</View>
						),
					},
					{
						content: (
							<View>
								<ContentHeader> </ContentHeader>
								<View style={styles.cardContainer}>
									{<PersonCard role="Kunde" person={pdfModel.bedarf.kunde} werte={pdfModel.displaywerte.filter((wert) => wert.haushaltsmitglied?.person_uuid === pdfModel.bedarf.kunde.uuid)} />}
									{<PersonCard role="Partner" person={pdfModel.bedarf.partner} werte={pdfModel.displaywerte.filter((wert) => wert.haushaltsmitglied?.person_uuid === pdfModel.bedarf.partner.uuid)} />}
								</View>
							</View>
						),
					},
				]}
			/>

			{pdfModel.bedarf.kinder.length > 0 && (
				<ColumnPage
					pdfModel={pdfModel}
					columns={[
						{
							content: (
								<View>
									<ContentHeader> </ContentHeader>
									<View style={styles.cardContainer}>
										{pdfModel.bedarf.kinder.map((kind, i) => (
											<PersonCard key={i} role="Kind" person={kind} werte={pdfModel.displaywerte.filter((wert) => wert.haushaltsmitglied?.person_uuid === kind.uuid)} />
										))}
									</View>
								</View>
							),
						},
						{
							content: <View></View>,
						},
					]}
				/>
			)}

			<ColumnPage
				pdfModel={pdfModel}
				columns={[
					{
						content: (
							<View>
								<ContentHeader>Übersicht Ihrer Finanzthemen</ContentHeader>
								<BodyText>
									Die folgende Übersicht zeigt Ihnen, welche Finanzthemen für Sie relevant sind und welche Reihenfolge die Finanzthemen haben. Die Orientierungsgrößen gemäß DIN-Norm 77230 werden den Ist-Werten gegenübergestellt. Über die
									Vorgaben der DIN-Norm hinausgehend zeigt Ihnen die Tabelle die Lücken in den jeweiligen Finanzthemen und die Zielerreichungsgrade auf Basis Ihrer aktuellen Ist-Werte.
								</BodyText>
								<VSpacer />
								<BodyText>
									Die Basis-Finanzanalyse beschränkt sich auf eine rein quantitative, typisierte Betrachtung und umfasst keine qualitativen Aspekte (z. B. aus Versicherungsbedingungen) sowie ggf. notwendige Vollmachterteilungen (z. B.
									Betreuungsvollmachten, Bankvollmachten, Sorgerechtsverfügungen). Ebenso werden bei der vorliegenden Analyse beim Abgleich der Orientierungsgrößen mit den Istwerten auf der Seite der Istwerte mögliche Ansprüche aus der
									Grundsicherung nicht berücksichtigt.
								</BodyText>
								<VSpacer />
								<BodyText>Eine weiterführende Betrachtung ist unabdingbar, um zu einer individuell passenden, für den Privathaushalt wirtschaftlich sinnvollen Lösung zu gelangen.</BodyText>
							</View>
						),
					},
					{
						content: (
							<View style={{ height: "100%", width: "100%", alignSelf: "center", justifyContent: "center", alignItems: "center" }}>
								<Image src={import.meta.env.VITE_BACKEND + "/Finanzthemen.png"} />
							</View>
						),
					},
				]}
			/>
			{bedarfsstufe1.length > 0 && (
				<BasicPage pdfModel={pdfModel}>
					<ContentHeader>Sicherung des finanziellen Grundbedarfs</ContentHeader>
					<ChartTable data={bedarfsstufe1} />
				</BasicPage>
			)}
			{bedarfsstufe2.length > 0 && (
				<BasicPage pdfModel={pdfModel}>
					<ContentHeader>Erhaltung des Lebensstandards</ContentHeader>
					<ChartTable data={bedarfsstufe2} />
				</BasicPage>
			)}
			{bedarfsstufe3.length > 0 && (
				<BasicPage pdfModel={pdfModel}>
					<ContentHeader>Verbesserung des Lebensstandards</ContentHeader>
					<ChartTable data={bedarfsstufe3} />
				</BasicPage>
			)}
			<BasicPage pdfModel={pdfModel}>
				<ContentHeader>Ihr Finanzfahrplan</ContentHeader>

				{bedarfsstufe1Unter100.length > 0 && (
					<View>
						<ContentSubHeader>Sicherung des finanziellen Grundbedarfs</ContentSubHeader>
						<VSpacer />
						<ChartTable data={bedarfsstufe1Unter100} />
						<VSpacer />
					</View>
				)}

				{bedarfsstufe2Unter100.length > 0 && (
					<View>
						<ContentSubHeader>Erhaltung des Lebensstandards</ContentSubHeader>
						<VSpacer />
						<ChartTable data={bedarfsstufe2Unter100} />
						<VSpacer />
					</View>
				)}

				{bedarfsstufe3Unter100.length > 0 && (
					<View>
						<ContentSubHeader>Verbesserung des Lebensstandards</ContentSubHeader>
						<VSpacer />
						<ChartTable data={bedarfsstufe3Unter100} />
					</View>
				)}
			</BasicPage>
			<ColumnPage
				pdfModel={pdfModel}
				columns={[
					{
						content: (
							<View>
								<ContentHeader>Abschließender Ausblick</ContentHeader>
								<BodyText>
									Die DIN-Norm 77230 garantiert Ihnen eine sichere und objektive Finanzanalyse für Privathaushalte rund um die Themen Absicherung, Vorsorge und Vermögensplanung. Sie trennt bewusst die Finanzanalyse (analog zur „Diagnose“ in
									der Medizin) von der nachgelagerten Finanzberatung (analog zur „Therapie/Behandlung“ in der Medizin).
								</BodyText>
								<BodyText>
									Mit ihrer Analyse haben Sie die Möglichkeit ihre Ziele schneller, sicherer und mit einem geringeren finanziellen Aufwand zu erreichen. Um diesen Nuxtzen nachhaltig zu erfahren ist ein regelmäßiger Kommunikationsrhythmus
									sinnvoll. Ob persönlich, telefonisch oder online, unser Serviceangebot steht. Wir sind für sie erreichbar unter:
								</BodyText>
								<VSpacer />
								<BodyText noMargin={true}>
									Ihr {pdfModel.berater.vorname} {pdfModel.berater.nachname}
								</BodyText>
								<BodyText noMargin={true}>{pdfModel.berater.custom.telefon}</BodyText>
								<BodyText noMargin={true}>{pdfModel.berater.email}</BodyText>
							</View>
						),
					},
					{
						content: (
							<View style={{ height: "100%", width: "100%", alignSelf: "center", justifyContent: "center", alignItems: "center" }}>
								<Image src={import.meta.env.VITE_BACKEND + "/PersonHandshake.png"} />
							</View>
						),
					},
				]}
			/>
			<BasicPage pdfModel={pdfModel}>
				<ContentHeader>Anhang Vertragsspiegel</ContentHeader>
				<BigTable data={pdfModel.vertraege} />
			</BasicPage>
			<BasicPage pdfModel={pdfModel}>
				<ContentHeader>Ihre Daten</ContentHeader>
				<HaushaltsmitgliederCard
					haushalt={pdfModel.bedarf.haushalt}
					person1={{ data: pdfModel.bedarf.kunde, role: "Kunde" }}
					person2={pdfModel.bedarf.kunde.familienstand !== "ledig" ? { data: pdfModel.bedarf.partner, role: "Partner/in" } : null}
				/>
			</BasicPage>
			{kinderPaare.map((kinderPaar, i) => (
				<BasicPage key={i} pdfModel={pdfModel}>
					<ContentHeader>Ihre Kinder</ContentHeader>
					<HaushaltsmitgliederCard haushalt={pdfModel.bedarf.haushalt} person1={{ data: kinderPaar.kind1, role: "Kind" }} person2={{ data: kinderPaar.kind2, role: "Kind" }} />
				</BasicPage>
			))}
			<BasicPage pdfModel={pdfModel}>
				<ParagraphHeader>Rahmenparameter</ParagraphHeader>
				<BodyText>
					Die Durchführung der Basis-Finanzanalyse für Privathaushalte sind nur unter Nutzung von festgelegten aktuell gültigen Rahmenparametern, Methoden und Annahmen möglich. Insbesondere bei langfristigen Betrachtungen und Analysen (z.
					B. für die Altersorge) haben die Inflationen und die Hochrechnung von Vermögenswerten einen großen Einfluss. Die Rahmenparameter haben den Stand vom 01.01.2024.
				</BodyText>
				<RahmenparameterTable
					data={[
						{ label: "Inflationsrate (Geometrichses Mittel der letzten 10 Jahre mit Stichtag 1. Januar. Quelle Statistisches Bundesamt)", value: getProzent(INFLATIONSRATE) },
						{ label: "Mindestbedarf (Mindestlohn von 12,41 € * 8 h * 21 Arbeitstage - 25 % Sozialversicherung/ Steuer)", value: getEuro(MINDESTBEDARF) },
						{ label: "Mindestbeitragsbemessungsgrundlage Krankenversicherung /Pflegepflichtversicherung  für freiwillig versicherte Selbstständige/Freiberufler", value: getEuro(MINDESTBEITRAGSBEMESSUNGSGRUNDLAGE) },
						{ label: "Allgemeiner Beitragssatz Rentenversicherung", value: getProzent(BEITRAGSSATZ_RV) },
						{ label: "Allgemeiner Beitragssatz Arbeitslosenversicherung", value: getProzent(BEITRAGSSATZ_AV) },
						{ label: "Allgemeiner Beitragssatz Krankenversicherung (mit Anspruch auf Krankengeld ab dem 43. Tag)", value: getProzent(BEITRAGSSATZ_KV) },
						{ label: "Durchschnittlicher Zusatzbeitrag für die gesetzliche Krankenversicherung", value: getProzent(DURSCHNITTLICHER_ZUSATZBEITRAG_KV) },
						{ label: "Allgemeiner Beitragssatz Pflegeversicherung", value: getProzent(BEITRAGSATZ_PPV_MIN) + " - " + getProzent(BEITRAGSATZ_PPV_MAX) + ", Kinderlosenzuschlag: " + getProzent(KINDERLOSENZUSCHLAG_PPV) },
						/*	{ label: "Kinderlosenzuschlag Pflegeversicherung", value: getProzent(KINDERLOSENZUSCHLAG_PPV) },*/
						{
							label: "Mindestbeitrag gesetzliche Kranken/Pflegepflichtversicherung für freiwillig versicherte Selbstständige",
							value: "ohne Kind: " + getEuro(MINDESTBEITRAG_GKV_PPV_OHNE_KIND) + ", mit Kind: " + getEuro(MINDESTBEITRAG_GKV_PPV_MIT_KIND),
						},
						/*	{ label: "Mindestbeitrag gesetzliche Kranken/Pflegepflichtversicherung für freiwillig versicherte Selbstständige (mit Kind)", value: getEuro(MINDESTBEITRAG_GKV_PPV_MIT_KIND) },*/
						{ label: "Halber Regelbeitraf für Selbstständige/Freiberufler in der gesetzlichen Rentenversicherung", value: "West: " + getEuro(HALBER_REGELBEITRAG_GRV_WEST) + ", Ost: " + getEuro(HALBER_REGELBEITRAG_GRV_OST) },
						/*{ label: "Halber Regelbeitraf für Selbstständige/Freiberufler in der gesetzlichen Rentenversicherung (Ost)", value: getEuro(HALBER_REGELBEITRAG_GRV_OST) },*/
						{ label: "Beitragsbemessungsgrenze Kranken-/Pflegeversicherung", value: getEuro(BEITRAGSBEMESSUNGSGRENZE_KV, 0) },
						{ label: "Bafög-Höchstbetrag", value: getEuro(BAFOEG_HOECHSTSATZ) },
					]}
				/>
				<ParagraphHeader>Hochrechnung von Barvermögen, Kapitalanlagen und Ansprüchen aus der Altervorsorge</ParagraphHeader>
				<BodyText>
					Sofern keine hochgerechneten Werte wie Rentenzahlungen oder Kapitalzahlungen durch einen Anbieter von Lebens-/Rentenversicherung oder Geldanlagen gegeben sind, erfolgt eine Hochrechnung der Vermögenswerte anhand von
					Renditeklassen. Wenn Rentenzahlungen und Kapitalzahlungen gegeben sind, werden die Rentenzahlungen herangezogen. Wenn mehrere Werte (z.B. garantierte Leistungen und Leistungen mit Überschuss) gegeben sind, werdem bei 2 Werten der
					niedrigere verwendet und bei 3 Werten der mittlere Wert.
				</BodyText>
				<RahmenparameterTable
					data={[
						{ label: "Renditeklasse 1 (Verträge mit Beitragsgarantie)", value: getProzent(RENDITEKLASSE_1) },
						{ label: "Renditeklasse 2 (Verträge mit niedriger oder keiner Beitragsgarantie)", value: getProzent(RENDITEKLASSE_2) },
					]}
				/>
				<ParagraphHeader>Haftung</ParagraphHeader>
				<BodyText>
					Basierend auf Ihre Angaben und Daten zum Stichtag {getDatum(new Date())} wurde dieses Dokument erstellt. Es wurde nur der zu diesem Datum gültige Vertragsstand berücksichtigt. Ihr Berater überprüft die von Ihnen getätigten Angaben
					nicht auf Richtigkeit und Vollständigkeit. Prognosen, insbesondere Berechnungen zur Altersvorsorge und Analysen im Bereich der Vermögensplanung, werden mit finanzmathematischen Methoden erstellt. Änderungen in der persönlichen
					Lebenssituation, Entwicklungen in der Wirtschaftslage und Änderungen der rechtlichen Rahmenbedingungen (z.B. im Steuerrecht, bei der Sozialgesetzgebung, etc.) können zu Abweichungen innerhalb der Prognose führen, weshalb wir Ihnen
					empfehlen, die Analyse regelmäßig auf Richtigkeit und Vollständigkeit zu überprüfen und gegebenenfalls ändern zu lassen. Rechts- und/oder Steuerberatung erfolgt hierbei nicht, da diese Tätigkeiten dem Berater untersagt sind.
					Diesbezüglich raten wir Ihnen, einen Steuerberater und/oder Rechtsanwalt auzusuchen.
				</BodyText>
			</BasicPage>
			<ColumnPage
				pdfModel={pdfModel}
				columns={[
					{
						content: (
							<View>
								<ContentSubHeader>Basis-Finanzanalyse für Privathaushalte nach DIN-Norm 77230</ContentSubHeader>
								<VSpacer />
								<BodyText>
									Die Basis-Finanzanalyse wurde von Fachexperten, Wissenschaftlern und Verbraucherschutzorganisationen unter Anleitung des Deutschen Instituts für Normung (DIN) über einen mehrjährigen Zeitraum entwickelt. Sie ist für jeden
									Privathaushalt anwendbar und liefert objektive und messbare Ergebnisse zum Schutz der Verbraucher. Sie dient der Eigeninformation als auch der Orientierungshilfe bei der Umsetzung finanzieller Entscheidungen. Die DIN-Norm
									77230 legt das Verfahren zur Durchführung einer Finanzanalyse für einen Privathaushalt fest. Sie verfolgt einen ganzheitlichen Ansatz und umfasst die Bereiche Absicherung (z.B. Haftungsrisiken, Risiko aus dem
									Verlust/Beschädigung von Hausrat…), Vorsorge (z.B. Kostenrisiko Krankheit, Arbeitskraftverlust, Altersvorsorge…) und Vermögensplanung (z.B. Aufbau Liquiditätsreserve, Schaffung von Eigenkapital für eine eigengenutzte
									Immobilie, Ausbildungssparen Kinder, Verschuldung…).
								</BodyText>
								<BodyText>
									Da der Haushalt in der Regel nicht über unbegrenzte finanzielle Mittel zur Schließung aller finanzieller Lücken und zur Erreichung sämtlicher monetärer Ziele verfügt, erfolgt die Finanzanalyse unter Berücksichtigung von 3
									Bedarfsstufen:
								</BodyText>
								<BodyText>
									Bedarfsstufe 1 - Sicherung des finanziellen Grundbedarfs: Hier wird der finanzielle Grundbedarf ausgewiesen, um eine Absicherung oberhalb der staatlichen Grundsicherung zu erreichen. Bei einigen Themen im Vorsorgebereich
									liegt der gesetzliche Mindestlohn zur Bestimmung des Grundbedarfs zugrunde.{" "}
								</BodyText>
								<BodyText>
									Bedarfsstufe 2 - Erhaltung des Lebensstandards: Um den aktuellen und auch den zukünftigen Lebensstandard zu erhalten gilt es, das aktuelle Einkommen zu schützen und weitere Absicherungs- und Vorsorgemaßnahmen zu treffen.
								</BodyText>
							</View>
						),
					},
					{
						content: (
							<View>
								<BodyText>Auch die Finanzthemen aus dem Bereich der Vermögensplanung gewinnen an Bedeutung. Viele Themen orientieren sich am monatlichen Nettoerwerbseinkommen.</BodyText>
								<BodyText>Bedarfsstufe 3 - Verbesserung des Lebensstandards: Auf dieser Stufe werden individuelle Sparziele des Privathaushalts betrachtet, die über den heutigen Lebensstandard hinausgehen.</BodyText>
								<BodyText>
									Ein Privathaushalt im Sinne der DIN-Norm 77230 setzt sich aus einem oder zwei Haushaltsverantwortlichen, die zueinander in Ehe, eingetragener Lebenspartnerschaft oder Partnerschaft stehen und ggf. aus wirtschaftlich
									abhängigen Kindern zusammen. Als wirtschaftlich abhängige Kinder im Sinne der DIN-Norm gelten alle minderjährigen leiblichen, Adoptiv- und Stiefkinder sowie Enkelkinder, für die eine Kindergeldbezugsberechtigung besteht,
									sowie alle volljährigen Kinder soweit ein Kindergeldbezugsberechtigung besteht, diese nicht verheiratet sind und kein Erwerbseinkommen aus einem Ausbildungsverhältnis oder hauptberuflichen Beschäftigungsverhältnis bezogen
									wird.
								</BodyText>
								<BodyText>
									Bei der Durchführung der Basis-Finanzanalyse werden die relevanten Risiken bzw. Finanzthemen des Haushalts identifiziert und in eine eindeutige Rangfolge gebracht. Danach wird für jedes relevante Finanzthema eine
									bedarfsgerechte Orientierungsgröße (Sollwerte) auf Basis der Haushaltsdaten ermittelt und/oder festgelegt. Mit der Erfassung der Istwerte (Leistungen und Ansprüche aus bestehenden Versicherungsverträgen, Kapitalanlagen
									etc.) und dem Abgleich mit den Sollwerten endet die DIN Analyse. Der Privathaushalt erhält als Ergebnis einen transparenten Überblick über seine finanzielle Situation. Darüber hinaus liefert die Finanzanalyse eine
									objektive und verständliche Grundlage für eine darauf aufbauende Finanzberatung.
								</BodyText>
							</View>
						),
					},
				]}
			/>
			<LastPage pdfModel={pdfModel} />
		</Document>
	);
};

FinanzkonzeptPDF.propTypes = {
	pdfModel: PropTypes.object,
};

export const FinanzkonzeptCharts = (props) => {
	const { familienScore, setIsLoaded, ausgaben } = props;
	const [isGenerated, setIsGenerated] = useState(false);
	const renderedChartsCount = useRef(0);
	const totalCharts = 103;
	const getAusgabenMonatChartData = (data) => {
		return {
			labels: data.map(() => ""),
			datasets: [
				{
					data: data.map((row, i) => row.wert),
					backgroundColor: data.map((row, i) => getChartColor(i)),
					weight: 5,
				},
			],
		};
	};

	const handleChartComplete = () => {
		renderedChartsCount.current += 1;

		if (renderedChartsCount.current === totalCharts) {
			setIsGenerated(true);
			setIsLoaded(true);
			console.log("Alle Charts sind fertig gerendert");
		}
	};

	const generalChartOptionsLocal = {
		...generalChartOptions,
		animation: {
			onComplete: handleChartComplete,
		},
	};

	const gaugeChartOptionsLocal = {
		...gaugeChartOptions,
		animation: {
			onComplete: handleChartComplete,
		},
	};

	return (
		<Fragment>
			{!isGenerated && <Spinner message="PDF wird generiert..." />}
			<div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", visibility: "hidden", height: 0 }}>
				{Array.from({ length: 101 }, (_, i) => (
					<Doughnut
						key={i}
						id={"bewertung_" + i + "_view"}
						options={generalChartOptionsLocal}
						data={{
							labels: ["", ""],
							datasets: [
								{
									data: [i, 100 - i],
									backgroundColor: ["#2E7D32", "#D32F2F"],
									weight: 5,
								},
							],
						}}
						width="400px"
						height="400px"
					/>
				))}
				<Doughnut
					id="chartFamilienscore"
					options={gaugeChartOptionsLocal}
					data={{
						labels: ["", ""],
						datasets: [
							{
								data: [familienScore, 100 - familienScore],
								backgroundColor: ["#2E7D32", "#D32F2F"],
								weight: 5,
							},
						],
					}}
					width="400px"
					height="400px"
				/>
				<Doughnut id="chartAusgabenMonat" options={generalChartOptionsLocal} data={getAusgabenMonatChartData(ausgaben)} width="400px" height="400px" />
			</div>
		</Fragment>
	);
};

FinanzkonzeptCharts.propTypes = {
	familienScore: PropTypes.number,
	setIsLoaded: PropTypes.func,
	ausgaben: PropTypes.array,
};
