import { Box, Typography } from "@mui/material";
import Collapsable from "components/Collapsable";
import CustomIcon from "components/CustomIcon";
import PDFViewer from "components/PDFViewer";
import Tile from "components/Tile";
import BackendLayout from "layouts/BackendLayout";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";

export default function Leistungspraesentation() {
	const appWindow = useRecoilValue(appWindowAtom);
	const [controls, setControls] = useState("");

	const navWidth = 350;

	return (
		<BackendLayout>
			<Box sx={{ display: "flex", width: appWindow.contentWidth }}>
				<Box sx={{ marginRight: "16px" }}>
					<Collapsable>
						<Tile sx={{ width: navWidth + "px", padding: "0px", margin: "0px", height: appWindow.contentHeight + "px", overflowY: "auto" }}>
							<Box sx={{ margin: "16px", padding: "0px" }}>
								<Box sx={{ pl: "8px", pt: "8px", pb: "24px", display: "flex", alignItems: "center" }}>
									<CustomIcon name="Person" />
									<Box sx={{ pl: "8px" }}>
										<Typography variant="h5" component="div">
											Leistungspräsentation
										</Typography>
									</Box>
								</Box>

								<Box>{controls}</Box>
							</Box>
						</Tile>
					</Collapsable>
				</Box>
				<Tile sx={{ display: "flex", flexGrow: "0", padding: "8px", margin: "0px", height: appWindow.contentHeight + "px" }}>
					<PDFViewer
						config={{
							widthCorrection: navWidth,
							heightCorrection: 0,
							file: "ADD9B584-4A84-4532-9170-C2581D3C7B64",
							controls: {
								showSketchpad: true,
								showZoom: true,
								showPagination: false,
								showThumbnails: false,
								showPrevNext: true,
								position: "bottom",
								thumbnailWidth: 256,
								thumbnailHeight: 32,
								callback: setControls,
							},
						}}
					/>
				</Tile>
			</Box>
		</BackendLayout>
	);
}
