import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { bedarfAtom } from "recoil/atoms/bedarfAtom";
import DMF_CheckboxGroup from "./controls/DMF_CheckboxGroup";
import DMF_Section from "./controls/DMF_Section";
import MDDButtonCheckboxes from "./controls/MDDButtonCheckboxes";
import MDDButtonOptions from "./controls/MDDButtonOptions";
import MDDDatepicker from "./controls/MDDDatepicker";
import MDDExpandable from "./controls/MDDExpandable";
import MDDInput from "./controls/MDDInput";
import MDDNumberInput from "./controls/MDDNumberInput";
import MDDNumberLabel from "./controls/MDDNumberLabel";
import MDDRadioGroup from "./controls/MDDRadioGroup";
import MDDSelect from "./controls/MDDSelect";
import MDDSliderInput from "./controls/MDDSliderInput";
import MDDStepper from "./controls/MDDStepper";
import MDDSwitch from "./controls/MDDSwitch";
import MDDTabs from "./controls/MDDTabs";
import MDDUpload from "./controls/MDDUpload";
import MDDAutocomplete from "./controls/MDDAutocomplete";
import DMF_SectionLabel from "./controls/DMF_SectionLabel";
import DMF_Component from "./controls/DMF_Component";
import MDDMultiSelect from "./controls/MDDMultiselect";
import DMF_Display from "./controls/DMF_Display";

FormRenderer.propTypes = {
	fdef: PropTypes.object,
	variant: PropTypes.string,
	jitValidate: PropTypes.bool,
	valuesCallBack: PropTypes.func,
	saveCallBack: PropTypes.func,
	containerRenderer: PropTypes.func,
	itemRenderer: PropTypes.func,
	model: PropTypes.object,
	editMode: PropTypes.bool,
	services: PropTypes.object,
};

function FormRenderer(props) {
	const {
		fdef = { formdef: [], transform_in: [], transform_out: [] },
		variant = "filled",
		jitValidate = true,
		valuesCallBack = null,
		saveCallBack = null,
		containerRenderer,
		itemRenderer,
		model = null,
		editMode = true,
		services = {},
	} = props;
	const [bedarf, setBedarf] = useRecoilState(bedarfAtom);
	// const [hidden, setHidden] = useState([]);
	const { trigger, getValues, setValue, control, handleSubmit, formState, reset, watch } = useForm({
		//resolver: yupResolver(schema),
		defaultValues: model,
	});
	const { isDirty } = formState;

	useEffect(() => {
		if (valuesCallBack) {
			valuesCallBack(getValues(), setValue);
		}
	}, [control]);

	/*
	useEffect(() => {
		if (jitValidate) trigger();
		setHidden(getHidden(getValues(), fdef.formdef));
	}, []);
*/
	const test = () => {
		console.log("State:", { fdef: fdef, values: getValues() });
	};

	const onSubmit = (data) => {
		setBedarf(data);
		//reset({}, { keepValues: true });
	};

	const handleTrigger = (name) => {
		const values = getValues();
		if (valuesCallBack) {
			valuesCallBack(values, setValue);
		}
		trigger(name);
	};

	const formItemRenderer = (items, callBack = null) => {
		const renderedItems = items.map((item, index) => {
			const renderedControl = formControlRenderer(item, callBack);
			return itemRenderer(renderedControl, item, index);
		});
		return containerRenderer(renderedItems);
	};

	const getDefinition = (path = "", template) => {
		if (path.length > 0) path += ".";
		return template.map((item) => {
			let watch = null;
			if (item.watch) {
				watch = item.watch.map((watchItem) => path + watchItem);
			}
			if (item.control === "expandable") {
				return { ...item, name: path + item.name };
			}
			let newItem = { ...item, name: path + item.name };
			if (watch) newItem = { ...newItem, watch: watch };
			if (newItem.children) newItem = { ...newItem, children: getDefinition(path + item.name, newItem.children) };
			return newItem;
		});
	};

	const getSection = (name, desc, template) => {
		return {
			control: "section",
			name: name,
			label: desc.label,
			runtime_func: { func: "setDisplayname", entity: desc.key },
			watch: [desc.key + ".vorname", desc.key + ".nachname", desc.key + ".geburtsdatum"], //[desc.key], //
			visible: { func: "wirtschaftlichAbhaengig", entity_path: desc.key },
			cols: 12,
			children: getDefinition(desc.key, template),
		};
	};

	const formControlRenderer = (item, callBack = null) => {
		let childrenRenderedArray = [];
		let childrenArray = [];

		const componentProps = { item: item, control: control, variant: variant, trigger: handleTrigger, disabled: !editMode };

		switch (item.control) {
			case "tabs":
				if (item.children_template) {
					item.children_entities.forEach((child) => {
						const child_entity = getValues(child.key);
						if (Array.isArray(child_entity)) {
							child_entity.forEach((entity, index) => {
								const tab_name = child.key + "[" + index + "]";
								const section = getSection(tab_name, { key: tab_name, label: "Kind " + index }, item.children_template);
								childrenArray.push(section);
								childrenRenderedArray.push({ name: tab_name, content: formItemRenderer(section.children) });
							});
						} else {
							const tab_name = item.name + "_" + child.key;
							const section = getSection(tab_name, child, item.children_template);
							childrenArray.push(section);
							childrenRenderedArray.push({ name: tab_name, content: formItemRenderer(section.children) });
						}
					});
					item.children = childrenArray;
				} else {
					item.children.forEach((child) => {
						childrenRenderedArray.push({ name: child.name, content: formItemRenderer(child.children) });
					});
				}
				return (
					<MDDTabs {...componentProps} getValues={getValues}>
						{childrenRenderedArray}
					</MDDTabs>
				);
			case "stepper":
				item.children.forEach((child) => {
					childrenRenderedArray.push({ name: child.name, content: formItemRenderer(child.children) });
				});
				return (
					<MDDStepper {...componentProps} getValues={getValues} saveCallBack={saveCallBack}>
						{childrenRenderedArray}
					</MDDStepper>
				);
			case "section":
				return (
					<DMF_Section {...componentProps} getValues={getValues}>
						{formItemRenderer(item.children)}
					</DMF_Section>
				);
			case "section_label":
				return <DMF_SectionLabel {...componentProps} getValues={getValues} />;
			case "expandable":
				return <MDDExpandable {...componentProps} formItemRender={formItemRenderer} getValues={getValues} setValue={setValue} />;
			case "input":
				return <MDDInput {...componentProps} getValues={getValues} />;
			case "numberinput":
				return <MDDNumberInput {...componentProps} />;
			case "sliderinput":
				return <MDDSliderInput {...componentProps} getValues={getValues} callBack={callBack} />;
			case "select":
				return <MDDSelect {...componentProps} getValues={getValues} services={services} setValue={setValue} />;
			case "autocomplete":
				return <MDDAutocomplete {...componentProps} getValues={getValues} services={services} setValue={setValue} />;
			case "multiselect":
				return <MDDMultiSelect {...componentProps} getValues={getValues} services={services} />;
			case "switch":
				return <MDDSwitch {...componentProps} getValues={getValues} />;
			case "upload":
				return <MDDUpload {...componentProps} />;
			case "radio_group":
				return <MDDRadioGroup {...componentProps} />;
			case "button_options":
				return <MDDButtonOptions {...componentProps} />;
			case "button_checkboxes":
				return <MDDButtonCheckboxes {...componentProps} />;
			case "checkbox_group":
				return <DMF_CheckboxGroup {...componentProps} />;
			case "number_label":
				return <MDDNumberLabel {...componentProps} />;
			case "year_picker":
				return <MDDDatepicker item={{ ...item, views: ["year"] }} getValues={getValues} control={control} variant={variant} trigger={handleTrigger} />;
			case "year_month_picker":
				return <MDDDatepicker item={{ ...item, views: ["month", "year"] }} getValues={getValues} control={control} variant={variant} trigger={handleTrigger} />;
			case "date_picker":
				return <MDDDatepicker {...componentProps} getValues={getValues} />;
			case "display":
				return <DMF_Display {...componentProps} getValues={getValues} services={services} setValue={setValue} />;
			case "blank":
				return <></>;
			case "boolean":
				return <></>;
			case "component":
				return <DMF_Component {...componentProps} getValues={getValues} />;
			default:
				return <>*** unbekanntes Control: {item.control}</>;
		}
	};

	if (!fdef) return <Fragment></Fragment>;
	else
		return (
			<Fragment>
				<form onSubmit={handleSubmit(onSubmit)}>{formItemRenderer(fdef.formdef)}</form>
			</Fragment>
		);
}

export default FormRenderer;
/*

        <Button onClick={() => onSubmit(getValues())} disabled={!isDirty} sx={{ mt: "-12px" }} variant="contained" color="primary">
          speichern
        </Button>
				<Button sx={{ mt: "-12px" }} onClick={() => test()} variant="contained" color="primary">
					test
				</Button>
*/
