import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { getEuro, getDatum, getLabel } from "services/formatService";

const styles = StyleSheet.create({
	table: {
		width: "100%",
		border: "1px solid gray",
		borderRadius: 8,
		fontSize: 14,
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderTop: "1px solid gray",
		paddingTop: 4,
		paddingBottom: 4,
	},
	header: {
		borderTop: "none",
		paddingTop: 10,
		paddingBottom: 10,
	},
	bold: {
		fontWeight: "bold",
	},
	col1: {
		width: "10%",
		paddingLeft: 8,
	},
	col2: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "30%",
	},
	col3: {
		width: "30%",
	},
	col4: {
		width: "30%",
	},
});

export const BasicTable = ({ data, mappings = [] }) => (
	<View style={styles.table}>
		<View style={[styles.row, styles.bold, styles.header]}>
			<Text style={styles.col1}>Nr.</Text>
			<Text style={styles.col2}>Ziel</Text>
			<Text style={styles.col3}>Zielzeitpunkt</Text>
			<Text style={styles.col4}>Wunschbetrag</Text>
		</View>
		{data.map((row, i) => (
			<View key={`basic_table_row_${i}`} style={styles.row} wrap={false}>
				<Text style={styles.col1}>{i + 1}</Text>
				<View style={styles.col2}>
					<Image src={import.meta.env.VITE_BACKEND + "/LineiconTarget.png"} style={{ width: 32, height: 32, marginRight: 5 }} />
					<Text>{row.bezeichnung && getLabel(row.bezeichnung, mappings)}</Text>
				</View>
				<Text style={styles.col3}>{row.zeitpunkt && getDatum(row.zeitpunkt)}</Text>
				<Text style={styles.col4}>{row.wert && getEuro(row.wert, 0)}</Text>
			</View>
		))}
	</View>
);

BasicTable.propTypes = {
	data: PropTypes.array,
	mappings: PropTypes.array,
};
