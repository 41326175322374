import dayjs from "dayjs";
import "dayjs/locale/de";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.locale("de");
dayjs.extend(utc);
dayjs.extend(timezone);
const not_available = "- - -";

export const getDisplayName = (person) => {
	if (person === null || person === undefined) return "";
	if (person.nachname === null) {
		if (person.vorname === null) return "./.";
		return person.vorname;
	}
	return person.vorname + " " + person.nachname;
};

export const getEuro = (wert, nachkommastellen = 2) => {
	if (!wert && typeof wert !== "number") return not_available;
	wert = Number(wert);
	return wert.toLocaleString("de-DE", { style: "currency", currency: "EUR", minimumFractionDigits: nachkommastellen, maximumFractionDigits: nachkommastellen });
};

export const getProzent = (wert, nachkommastellen = 2) => {
	if (!wert && typeof wert !== "number") return not_available;
	wert = Number(wert);
	return wert.toLocaleString("de-DE", { style: "percent", minimumFractionDigits: nachkommastellen, maximumFractionDigits: nachkommastellen });
};

export const getJaNein = (wert) => {
	if (wert === null || wert === undefined) return "keine Angabe";
	return wert ? "Ja" : "Nein";
};

export const getDatum = (timestamp) => {
	let result = not_available;
	if (!isNaN(Date.parse(timestamp))) result = dayjs.utc(timestamp).tz("Europe/Berlin").format("DD.MM.YYYY");
	return result;
};

export const getJahr = (timestamp) => {
	let result = not_available;
	if (!isNaN(Date.parse(timestamp))) result = dayjs.utc(timestamp).tz("Europe/Berlin").format("YYYY");
	return result;
};

export const getMonatJahr = (timestamp) => {
	let result = not_available;
	if (!isNaN(Date.parse(timestamp))) result = dayjs.utc(timestamp).tz("Europe/Berlin").format("MM.YYYY");
	return result;
};

export const getDatumSortable = (timestamp) => {
	let result = not_available;
	if (!isNaN(Date.parse(timestamp))) result = dayjs.utc(timestamp).tz("Europe/Berlin").format("yyyyMMdd");
	return result;
};

export const getDatumZeit = (timestamp) => {
	let result = not_available;
	if (!isNaN(Date.parse(timestamp))) result = dayjs.utc(timestamp).tz("Europe/Berlin").format("DD.MM.YYYY - HH:mm");
	return result;
};

export const getAlter = (timestamp) => {
	if (timestamp === null || timestamp === undefined) return 0;
	return dayjs().tz("Europe/Berlin").diff(dayjs.utc(timestamp).tz("Europe/Berlin"), "year");
};

export const getAlterMonate = (timestamp) => {
	if (timestamp === null || timestamp === undefined) return 0;
	return dayjs().tz("Europe/Berlin").diff(dayjs.utc(timestamp).tz("Europe/Berlin"), "month");
};

export const getErsterTagImMonat = (timestamp) => {
	if (timestamp === null || timestamp === undefined) return not_available;
	return dayjs.utc(timestamp).tz("Europe/Berlin").startOf("month");
};

export const getLabel = (key, mappings) => {
	if (key === null || key === undefined) return not_available;
	const label = mappings.find((item) => item.value === key);
	if (label === null || label === undefined) return key;
	return label.label;
};
