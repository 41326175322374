import PropTypes from "prop-types";
import Kunde from "components/Kunde";
import Vertragsspiegel from "components/Vertragsspiegel";
import AnalyseErgebnisse from "components/AnalyseErgebnisse";
import Beratungsprozess from "components/Beratungsprozess";
import VideoPlayer from "components/VideoPlayer";
import PDFViewer from "components/PDFViewer";
import DIN_Analyse from "components/DIN_Analyse";
import DIN_SollAnalyse from "components/DIN_SollAnalyse";
import DIN_Vermoegensbilanz from "components/DIN_Vermoegensbilanz";
import DIN_Haushaltsrechnung from "components/DIN_Haushaltsrechnung";

DynamicComponentLoader.propTypes = {
	config: PropTypes.object,
};

export default function DynamicComponentLoader({ config }) {
	switch (config?.component) {
		case "Kunde":
			return <Kunde config={config} />;
		case "Vertragsspiegel":
			return <Vertragsspiegel config={config} />;
		case "AnalyseErgebnisse":
			return <AnalyseErgebnisse config={config} />;
		case "Beratungsprozess":
			return <Beratungsprozess config={config} />;
		case "VideoPlayer":
			return <VideoPlayer config={config} />;
		case "PDFViewer":
			return <PDFViewer config={config} />;
		case "DIN_SollAnalyse":
			return <DIN_SollAnalyse config={config} />;
		case "DIN_Analyse":
			return <DIN_Analyse config={config} />;
		case "DIN_Vermoegensbilanz":
			return <DIN_Vermoegensbilanz config={config} />;
		case "DIN_Haushaltsrechnung":
			return <DIN_Haushaltsrechnung config={config} />;

		default:
			return <div>Component not found: {config.component}</div>;
	}
}
