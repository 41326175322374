import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { getEuro, getLabel } from "services/formatService";

const styles = StyleSheet.create({
	table: {
		width: "100%",
		border: "1px solid gray",
		borderRadius: 8,
		fontSize: 14,
		height: "97%",
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		borderTop: "1px solid gray",
		paddingTop: 4,
		paddingBottom: 4,
	},
	header: {
		borderTop: "none",
		paddingTop: 10,
		paddingBottom: 10,
	},
	bold: {
		fontWeight: "bold",
	},
	iconWithText: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginLeft: 5,
	},
	col1: {
		width: "100%",
		marginLeft: 5,
	},
	price: {
		marginRight: 5,
	},
	footer: {
		marginTop: "auto",
		paddingTop: 10,
		paddingBottom: 10,
	},
	footerText: {
		marginLeft: 5,
	},
	pricePositive: {
		marginRight: 5,
		color: "#1B5E20",
	},
	priceNegative: {
		marginRight: 5,
		color: "#C62828",
	},
});

export const ItemsTable = ({ header, data, footerText, mappings = [], negativeSum = false }) => {
	const totalPrice = data?.reduce((acc, curr) => acc + curr.wert, 0);
	return (
		<View style={styles.table}>
			<View style={[styles.row, styles.bold, styles.header]}>
				<Text style={[styles.col1, styles.bold]}>{header}</Text>
			</View>
			{data.map((row, i) => (
				<View key={`items_table_row_${i}`} style={[styles.row, { borderBottom: i === data.length - 1 ? "1px solid gray" : "none" }]} wrap={false}>
					<View style={styles.iconWithText}>
						<Image src={import.meta.env.VITE_BACKEND + "/LineiconMoney.png"} style={{ width: 32, height: 32, marginRight: 5 }} />
						<Text style={{ width: 150, height: 32, marginRight: 5, fontSize: 10 }}>{getLabel(row.bezeichnung, mappings)}</Text>
					</View>
					<Text style={styles.price}>{getEuro(row.wert, 0)}</Text>
				</View>
			))}
			<View style={[styles.row, styles.footer]}>
				<Text style={styles.footerText}>{footerText}</Text>
				{totalPrice > 0 && !negativeSum ? <Text style={styles.pricePositive}>{getEuro(totalPrice, 0)}</Text> : <Text style={styles.priceNegative}>{getEuro(totalPrice, 0)}</Text>}
			</View>
		</View>
	);
};

ItemsTable.propTypes = {
	header: PropTypes.string,
	data: PropTypes.array,
	footerText: PropTypes.string,
	mappings: PropTypes.array,
	negativeSum: PropTypes.bool,
};
