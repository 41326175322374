import PropTypes from "prop-types";
import ReactPlayer from "react-player";

VideoPlayer.propTypes = {
	config: PropTypes.object,
};

export default function VideoPlayer(props) {
	const { config } = props;
	return <ReactPlayer url={config.url} width="100%" height="100%" controls={config.controls} />;
}
