import FoundryMonolineBold from "assets/fonts/Founmb_.ttf";
import FoundryMonolineMedium from "assets/fonts/Founmm_.ttf";

const primary = "#001f54";
const secondary = "#F6121C";
const neutral = "#3C4A47";

const appPalette = {
	spcacing: 4,

	neutral: neutral,
	borderActive: "#3E4F9350",
	borderInactive: "#00000012",
	text: {
		primary: "#000000A0",
		emphasized: "#000000C0",
	},
	navigation: {
		main: "#F0F0F0",
		contrastText: "#000000",
	},
	primary: {
		main: primary,
		contrastText: "#FFFFFF",
	},
	secondary: {
		main: secondary,
		contrastText: "#FFFFFF",
	},
	primaryContrast: {
		main: "#FFFFFF",
		contrastText: neutral,
	},
	actionCancel: {
		main: "#6E0019",
		contrastText: "#FFFFFF",
	},
	borderColor: "#E5E5E5",
	borderColorActive: "#A0A0A0",
	bgColor: "rgba(255, 252, 245, 1)",
	clickableHover: "#CCCCCC",
	clickableArea: "#C8E6D880",

	//Sparten
	Leben: {
		main: "#80D8FF",
		contrastText: "#000000",
	},
	"Leben DRV": {
		main: "#80D8FF",
		contrastText: "#000000",
	},
	Sach: {
		main: "#00B0FF",
		contrastText: "#000000",
	},
	Kranken: {
		main: "#FFEB3B",
		contrastText: "#000000",
	},
	"Kranken (GKV)": {
		main: "#FFEB3B",
		contrastText: "#000000",
	},
	Finanzierungen: {
		main: "#FFC107",
		contrastText: "#000000",
	},
	Kfz: {
		main: "#FF7043",
		contrastText: "#000000",
	},
	Kapitalanlage: {
		main: "#FF4081",
		contrastText: "#000000",
	},
	Kapitalanlagen: {
		main: "#FF4081",
		contrastText: "#000000",
	},

	"ohne Einschränkungen": {
		main: "#FFEB3B",
		contrastText: "#000000",
	},
	Info: {
		main: "#FFEB3B",
		contrastText: "#000000",
	},
};

const appTheme = {
	overrides: {
		"@font-face": [
			{
				fontFamily: "FoundryMonoline-Bold",
				src: `url(${FoundryMonolineBold}) format('truetype')`,
				fontWeight: "bold",
			},
			{
				fontFamily: "FoundryMonoline-Medium",
				src: `url(${FoundryMonolineMedium}) format('truetype')`,
				fontWeight: "normal",
			},
		],
	},
	typography: {
		fontFace: "FoundryMonoline-Medium",

		h1: {
			fontFace: "FoundryMonoline-Medium",
			fontSize: "96px",
			fontWeight: 400,
			lineHeight: 1.167,
			letterSpacing: "-1.5px",
		},
		h2: {
			fontFace: "FoundryMonoline-Bold",
			fontSize: "60px",
			fontWeight: 400,
			lineHeight: 1.2,
			letterSpacing: "-0.5px",
		},
		h3: {
			fontFace: "FoundryMonoline-Bold",
			fontSize: "48px",
			fontWeight: 400,
			lineHeight: 1.167,
		},
		h4: {
			fontFace: "FoundryMonoline-Bold",
			fontSize: "34px",
			fontWeight: 400,
			lineHeight: 1.235,
			letterSpacing: "0.25px",
		},
		h5: {
			fontFace: "FoundryMonoline-Bold",
			color: appPalette.text.emphasized,
			fontSize: "24px",
			fontWeight: 400,
			lineHeight: 1.334,
		},
		h6: {
			fontFace: "FoundryMonoline-Medium",
			fontSize: "20px",
			fontStyle: "normal",
			fontWeight: 400,
			lineHeight: 1.6,
			letterSpacing: "0.15px",
		},
		caption: {
			fontFace: "FoundryMonoline-Medium",
			fontSize: "12px",
			fontWeight: 400,
			lineHeight: 1.66,
			letterSpacing: "0.4px",
		},
		overline: {
			fontFace: "FoundryMonoline-Medium",
			fontSize: "12px",
			fontWeight: 400,
			lineHeight: 2.66,
			textTransform: "uppercase",
		},
		subtitle2: {
			fontFace: "FoundryMonoline-Bold",
			fontSize: "14px",
			fontWeight: 400,
			lineHeight: 1.57,
			letterSpacing: "0.1px",
		},
		subtitle1: {
			fontFace: "FoundryMonoline-Bold",
			color: "rgba(0, 0, 0, 0.87)",
			fontSize: "16px",
			fontWeight: 400,
			lineHeight: "175%",
			letterSpacing: "0.15px",
		},
		body1: {
			fontFace: "FoundryMonoline-Medium",
			fontSize: "16px",
			fontWeight: 400,
			lineHeight: 1.5,
			letterSpacing: "0.15px",
		},
		body2: {
			fontFace: "FoundryMonoline-Medium",
			fontSize: "14px",
			fontWeight: 400,
			lineHeight: "143%",
			letterSpacing: "0.17px",
		},
	},
	components: {
		MuiBadge: {
			styleOverrides: {
				badge: {
					fontSize: "8px",
					fontWeight: "bold",
					color: "rgba(0, 0, 0, 0.87)",
					backgroundColor: "#E5CC9C",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					height: "42px",
					fontSize: "16px",
					fontWeight: 600,
				},
			},
			defaultProps: {
				disableElevation: true,
				disableRipple: true,
				disableTouchRipple: true,
				sx: {
					textTransform: "none",
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "none",
					color: "#2E2C34",
					fontSize: "18px",
					fontWeight: 400,
					borderStyle: "solid",
					borderBottomWidth: "0px",
					"&.Mui-selected": {
						position: "relative",
						"&::after": {
							content: '""',
							position: "absolute",
							left: 5,
							right: 5,
							bottom: 0,
							height: "1px",
							background: primary,
							borderRadius: "2px",
						},
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					"&:hover": {
						backgroundColor: appPalette.clickableHover,
						cursor: "pointer",
					},
				},
			},
		},
	},
};

export { appPalette, appTheme };
