export const vermoegenswerteOptions = [
	{ label: "Girokonto", value: "girokonto" },
	{ label: "Geldschulden gegenüber dem Haushalt", value: "geldschulden" },
	{ label: "Spareinlagen", value: "spareinlagen" },
	{ label: "festverzinsliche Wertpapiere", value: "wertpapiere" },
	{ label: "Bausparverträge", value: "bausparvertraege" },
	{ label: "Lebensversicherungen", value: "lebensversicherungen" },
	{ label: "Börsennotierte Aktien", value: "aktien" },
	{ label: "Investmentfonds", value: "investmentfonds" },
	{ label: "Unternehmenseigentum", value: "unternehmenseigentum" },
	{ label: "Wertgegenstände", value: "wertgegenstaende" },
	{ label: "Fahrzeuge", value: "fahrzeuge" },
	{ label: "Hauptwohnsitz", value: "hauptwohnsitz" },
	{ label: "weitere Immobilien", value: "weitere_immobilien" },
	{ label: "Sonstige", value: "sonstige" },
	{ label: "eigengenutzte Immobilien", value: "wohnsitze" },
	{ label: "Grundstücke", value: "grundstuecke" },
];

export const verbindlichkeitenOptions = [
	{ label: "Darlehen Hauptwohnsitz", value: "hauptwohnsitz" },
	{ label: "Darlehen weitere Immobilien", value: "weitere_immobilien" },
	{ label: "Darlehen Fahrzeuge", value: "fahrzeuge" },
	{ label: "Sonstige Kredite", value: "sonstige_kredite" },
	{ label: "Konsumentenkredit", value: "konsumentenkredit" },
	{ label: "Kreditkarten Schulden", value: "kreditkarten" },
	{ label: "Überziehungskredite", value: "ueberziehungkredit" },
	{ label: "Darlehen eigengenutzte Immobilien", value: "wohnsitze" },
	{ label: "Grundstücke", value: "grundstuecke" },
];

export const einnahmenOptions = [
	{ label: "pauschales Haushaltseinkommen", value: "pauschale_einnahmen" },
	{ label: "Nettoeinkommen aus selbständiger Arbeit", value: "selbstaendige_arbeit" },
	{ label: "Nettoeinkommen aus nicht selbständiger Arbeit", value: "nicht_selbstaendige_arbeit" },
	{ label: "Einnahmen aus Vermögen", value: "vermoegen" },
	{ label: "sonstige Einnahmen", value: "sonstige_einnahmen" },
];
export const ausgabenOptions = [
	{ label: "pauschale Lebenshaltungskosten", value: "pauschale_ausgaben" },
	{ label: "Nahrungsmittel, Getränke, Tabakwaren", value: "nahrung" },
	{ label: "Bekleidung und Schuhe", value: "bekleidung" },
	{ label: "Wohnen, Energie, Wohnungsinstandhaltung", value: "wohnen" },
	{ label: "Innenausstattung, Haushaltsgeräte und -gegenstände", value: "innenausstattung" },
	{ label: "Gesundheit", value: "gesundheit" },
	{ label: "Verkehr", value: "verkehr" },
	{ label: "Information und Kommunikation", value: "information" },
	{ label: "Freizeit, Sport und Kultur", value: "freizeit" },
	{ label: "Bildungsdienstleistungen", value: "bildung" },
	{ label: "Gastronomie- und Beherbungsdienstleistungen", value: "gastronomie" },
	{ label: "Versicherungs- und Finanzdienstleistungen", value: "versicherungen" },
	{ label: "sonstiges Ausgaben", value: "sonstige_ausgaben" },
	{ label: "Unterhalt", value: "unterhalt" },
];
export const investitionszieleOptions = [
	{ label: "Erwerb Kfz", value: "ziel_auto" },
	{ label: "Kapitalanlage Immobilie", value: "ziel_appartement" },
	{ label: "früherer Ruhestand", value: "ziel_ruhestand" },
	{ label: "Aufbau weitere Liquidität", value: "ziel_liquiditaet" },
	{ label: "Weltreise", value: "ziel_weltreise" },
	{ label: "Berufliche Auszeit", value: "ziel_auszeit" },
	{ label: "Umzug", value: "ziel_umzug" },
	{ label: "Fortbildung", value: "ziel_fortbildung" },
	{ label: "Konsumgut", value: "ziel_fernseher" },
	{ label: "neues Hobby", value: "ziel_neues_hobby" },
];
export const krankenversicherungOptions = [
	{ value: "gkv", label: "gesetzlich", icon: "Healthcare" },
	{ value: "pkv", label: "privat", icon: "Healthinsurance" },
	{ value: "heilfuersorge", label: "Heilfürsorge", icon: "Healthservice" },
];

export const altersvorsorgeOptions = [
	{ label: "Deutsche Rentenversicherung", value: "deutsche_rentenversicherung" },
	{ label: "Beamtenversorgung", value: "beamtenversorgung" },
	{ label: "Berufsständisches Versorgungswerk", value: "berufsstaendisches_versorgungswerk" },
	{ label: "andere Versorgungseinrichtung", value: "andere_versorgungseinrichtung" },
	{ label: "Zusatzversorgungskasse", value: "zusatzversorgungskasse" },
];

export const taetigkeitsstatusOptions = [
	{ value: "angestellt", label: "Angestellt", icon: "Selfemployed1" },
	{ value: "angestellt_oeffentlicher_dienst", label: "Angestellt öffentlicher Dienst", icon: "Civilwork" },
	{ value: "selbstaendig_freiberufler", label: "Selbstständig/ Freiberufler", icon: "Selfemployed" },
	{ value: "beamtet", label: "Beamtet", icon: "Civilwork" },
	{ value: "hausfrau_mann", label: "Hausfrau/-mann", icon: "Home" },
	{ value: "studierend", label: "Schüler/Student", icon: "Study" },
	{ value: "rentner", label: "in Rente", icon: "Elderly" },
];

export const geschlechtOptions = [
	{ value: "maennlich", label: "männlich", icon: "Masculin" },
	{ value: "weiblich", label: "weiblich", icon: "Feminin" },
	{ value: "divers", label: "divers", icon: "Diverse" },
];

export const besondereTaetigkeitOptions = [
	{ value: "ehrenamt", label: "Ehrenamt", icon: "Volunteer" },
	{ value: "besondere_aufgaben", label: "Besondere Aufgaben", icon: "Inspect" },
	{ value: "syndikusanwalt_angestellter_arzt", label: "Syndikusanwalt/ Angestellter Arzt", icon: "Civilwork" },
	{ value: "organ_unternehmen", label: "Organ Unternehmen", icon: "Position" },
];

export const haustierOptions = [
	{ value: "hund", label: "Hund", icon: "Dog" },
	{ value: "pferd", label: "Pferd", icon: "Horse" },
];
