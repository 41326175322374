import { Fullscreen, FullscreenExit, KeyboardArrowUp, Logout, ManageAccounts } from "@mui/icons-material";
import { AppBar, Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography, useTheme } from "@mui/material";
import Fade from "@mui/material/Fade";
import Logo from "assets/Formaxx-Logo.svg?react";
import Beratungsprozess from "components/Beratungsprozess";
import CustomIcon from "components/CustomIcon";
import Notizen from "components/Notizen";
import Tile from "components/Tile";
import Vertragsspiegel from "components/Vertragsspiegel";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { haushaltAtom, kundeDisplayNameSelector } from "recoil/atoms/haushaltAtom";
import { notizenAtom } from "recoil/atoms/notizenAtom";
import { workflowAtom } from "recoil/atoms/workflowAtom";
import { getDatum } from "services/formatService";
import { doReplace } from "services/helperService";
import { getActiveModule } from "services/workflowService";
import CreatePDF from "./CreatePDF";

export default function Header() {
	const notizen = useRecoilValue(notizenAtom);
	const [anchorAccount, setAnchorAccount] = useState(null);
	const openAccount = Boolean(anchorAccount);
	const navigate = useNavigate();
	const appWindow = useRecoilValue(appWindowAtom);
	const haushalt = useRecoilValue(haushaltAtom);
	const workflow = useRecoilValue(workflowAtom);
	const activeModule = getActiveModule(workflow);
	const kundeDisplayName = useRecoilValue(kundeDisplayNameSelector);
	const theme = useTheme();
	const [anchorNotes, setAnchorNotes] = useState(null);
	const openNotes = Boolean(anchorNotes);
	const [openVertragsspiegel, setOpenVertragsspiegel] = useState(false);
	const [dokumente, setDokumente] = useState([]);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const [fullscreen, setFullscreen] = useState(false);

	const handleFullscreenToggle = () => {
		if (!fullscreen) {
			document.documentElement.requestFullscreen();
		} else if (document.fullscreenElement) {
			document.exitFullscreen();
		}
		setFullscreen(!fullscreen);
	};

	useEffect(() => {
		const handleFullscreenChange = () => {
			setFullscreen(!!document.fullscreenElement);
		};

		document.addEventListener("fullscreenchange", handleFullscreenChange);

		return () => {
			document.removeEventListener("fullscreenchange", handleFullscreenChange);
		};
	}, []);

	useEffect(() => {
		if (fullscreen) {
			const handleKeyDown = (event) => {
				if (event.key === "Escape") {
					document.exitFullscreen(); // Beendet den Fullscreen-Modus explizit
				}
			};

			window.addEventListener("keydown", handleKeyDown);

			return () => {
				window.removeEventListener("keydown", handleKeyDown);
			};
		}
	}, [fullscreen]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleShowVertragsPicker = (row) => {
		setOpenVertragsspiegel(true);
	};

	const handleCloseVertragsPicker = () => {
		setOpenVertragsspiegel(false);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleAccountMenuClose = () => {
		setAnchorAccount(null);
	};

	const handlNotesMenuOpen = (event) => {
		setAnchorNotes(event.currentTarget);
	};

	const handleNotesMenuClose = () => {
		setAnchorNotes(null);
	};

	const burgerMenuItems = [];
	burgerMenuItems.push(
		{
			type: "navigation",
			label: "meine Benutzerdaten",
			route: "/accountinformationen",
			icon: <ManageAccounts sx={{ width: "32px", height: "32px" }} />,
		},
		{
			type: "action",
			label: "abmelden",
			icon: <Logout sx={{ width: "32px", height: "32px" }} />,
		},
		{
			type: "divider",
		},
		{
			type: "navigation",
			label: "Hilfe",
			route: "/static/hilfe",
		},
		{
			type: "navigation",
			label: "Kontakt",
			route: "/static/kontakt",
		}
	);

	useEffect(() => {
		switch (workflow?.active_module) {
			case "D001BC43-4614-42F2-BDFC-AD5230CCC428":
				setDokumente([{ label: "Bedarfserfassung", key: "ADD9B584-4A84-4532-9170-C2581D3C7B65" }]);
				break;
			default:
				setDokumente([]);
		}

		switch (workflow?.active_submodule) {
			case "92211BCF-BE56-4165-B33E-BE12C1A40817":
				setDokumente([{ label: "Finanzkonzept", key: "Analyse" }]);
				break;
			default:
		}
	}, [workflow]);

	return (
		<>
			{fullscreen ? (
				<Box sx={{ position: "absolute", top: 5, right: 5 }}>
					<IconButton
						onClick={handleFullscreenToggle}
						sx={{
							zIndex: 1000,

							borderRadius: 0,
							height: "64px",
							width: "64px",
							"&:hover": {
								backgroundColor: "transparent", // setzt die Hintergrundfarbe auf transparent im Hover-Zustand
							},
						}}
					>
						<FullscreenExit
							sx={{
								fontSize: "48px",
								"&:hover": {
									color: theme.palette.primary.main, // setzt die Farbe des Icons auf Blau im Hover-Zustand
								},
							}}
						/>
					</IconButton>
				</Box>
			) : (
				<AppBar position="fixed" elevation={0} color="navigation" sx={{ justifyItems: "center", borderBottomColor: "#C0C0C0", borderBottomStyle: "solid", borderBottomWidth: "1px" }}>
					<Toolbar
						sx={{
							margin: "0 auto",
							width: appWindow.contentWidth,
							height: "72px",
							...(appWindow.isMobile && { paddingLeft: "0px", paddingRight: "0px", width: "100%" }),
						}}
					>
						<Box sx={{ flexGrow: 0 }}>
							<IconButton id="quick-nav-button" onClick={() => navigate("/home")}>
								<Logo height="32px" />
							</IconButton>
						</Box>
						{kundeDisplayName && (
							<Box sx={{ flexGrow: 0 }}>
								<Tile
									sx={{
										ml: "48px",
										height: "64px",
										width: workflow.active_module === 0 ? "275px" : "550px",
										padding: "0px",
										display: "flex",
										alignItems: "center",
										flexGrow: "0",
										...(workflow.active_module === null && { width: "auto" }),
									}}
								>
									<Box
										sx={{
											padding: "0px",
											display: "flex",
											height: "100%",
											pl: "16px",
											width: workflow.active_module ? "50%" : "auto",
											flexGrow: "0",
											whiteSpace: "nowrap",
											alignItems: "center",
											borderRadius: workflow.active_module === null ? "8px" : "8px 0 0 8px",
										}}
									>
										<Box>
											<CustomIcon name={haushalt.kunde.geschlecht === "weiblich" ? "Femaleperson" : "Person"} size={48} />
										</Box>
										<Box sx={{ pl: "8px", pr: "16px", minWidth: 0, overflow: "hidden" }}>
											<Typography
												variant="body1"
												component="div"
												sx={{
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
													maxWidth: "100%",
												}}
											>
												{kundeDisplayName} ({getDatum(haushalt?.kunde.geburtsdatum)})
											</Typography>
											<Typography
												variant="body2"
												component="span"
												sx={{
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
													maxWidth: "100%",
												}}
											>
												{haushalt?.kunde.strasse} {haushalt?.kunde.hausnummer}, {haushalt?.kunde.plz} {haushalt?.kunde.ort}
											</Typography>
										</Box>
									</Box>
									{workflow.active_module !== null && (
										<Box
											sx={{
												padding: "0px",
												margin: "0px",
												pr: "16px",
												height: "100%",
												display: "flex",
												width: "50%",
												flexGrow: "0",
												borderRadius: "0 8px 8px 0",
												borderLeft: "1px solid #E6E6E6",
												alignItems: "center",
												":hover": {
													cursor: "pointer",
													backgroundColor: theme.palette.grey[100],
												},
											}}
											onClick={handleClick}
										>
											<CustomIcon name="Steps" size={48} />
											<Box sx={{ pl: "8px", pr: "16px", flexGrow: "1", minWidth: 0, overflow: "hidden" }}>
												<Typography
													variant="body1"
													component="div"
													sx={{
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow: "ellipsis",
														maxWidth: "100%",
													}}
												>
													{doReplace(workflow.workflow.modules.find((module) => module.uuid === workflow.active_module).label, haushalt)}
												</Typography>
												<Typography variant="body2" component="span">
													Schritt {activeModule.index} von {workflow.workflow.modules.length}
												</Typography>
											</Box>
											<KeyboardArrowUp sx={{ width: "24px", height: "24px", ...(open && { transform: "rotate(180deg)" }) }} />
										</Box>
									)}
								</Tile>
							</Box>
						)}
						<Box sx={{ flexGrow: 1 }}></Box>
						{kundeDisplayName && (
							<Fragment>
								<IconButton onClick={handleShowVertragsPicker} sx={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#616161", borderRadius: "4px", marginLeft: "16px", padding: "0px", width: "48px", height: "48px" }}>
									<CustomIcon name="Document" size={48} />
								</IconButton>
								<IconButton onClick={handlNotesMenuOpen} sx={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#616161", borderRadius: "4px", marginLeft: "16px", padding: "0px", width: "48px", height: "48px" }}>
									<Badge badgeContent={notizen.length} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
										<CustomIcon name="Notizen" size={48} />
									</Badge>
								</IconButton>
							</Fragment>
						)}
						{dokumente && dokumente.length > 0 && <CreatePDF dokumente={dokumente} />}

						<Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
							<IconButton
								onClick={handleFullscreenToggle}
								sx={{
									zIndex: 1000,
									borderRadius: 0,
									height: "64px",
									width: "64px",
									"&:hover": {
										backgroundColor: "transparent",
									},
								}}
							>
								{fullscreen ? (
									<FullscreenExit
										sx={{
											fontSize: "48px",
											"&:hover": {
												color: theme.palette.primary.main,
											},
										}}
									/>
								) : (
									<Fullscreen
										sx={{
											fontSize: "48px",
											"&:hover": {
												color: theme.palette.primary.main,
											},
										}}
									/>
								)}
							</IconButton>
						</Box>
					</Toolbar>
				</AppBar>
			)}
			<Menu
				id="account-menu"
				anchorEl={anchorAccount}
				open={openAccount}
				onClose={handleAccountMenuClose}
				sx={{ backgroundColor: "rgba(0, 0, 0, 0.25)" }}
				slotProps={{
					paper: {
						elevation: 0,
						sx: {
							overflow: "visible",
							filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
							mt: 1.5,
							"& .MuiAvatar-root": {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							"&:before": {
								content: '""',
								display: "block",
								position: "absolute",
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: "background.paper",
								transform: "translateY(-50%) rotate(45deg)",
								zIndex: 0,
							},
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				{burgerMenuItems.map((item, index) => GetMenuItem(item, index))}
			</Menu>
			<Menu
				id="fade-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
				MenuListProps={{ component: "div", sx: { padding: "0px" } }}
				slotProps={{
					paper: {
						sx: {
							borderRadius: "8px",
						},
					},
				}}
			>
				<Beratungsprozess />
			</Menu>
			<Menu
				id="notes-menu"
				anchorEl={anchorNotes}
				open={openNotes}
				onClose={handleNotesMenuClose}
				TransitionComponent={Fade}
				MenuListProps={{ component: "div", sx: { padding: "0px" } }}
				slotProps={{
					paper: {
						sx: {
							borderRadius: "8px",
						},
					},
				}}
			>
				<Notizen />
			</Menu>
			<Dialog
				open={openVertragsspiegel}
				onClose={handleCloseVertragsPicker}
				maxWidth={false}
				PaperProps={{
					style: {
						width: "100%",
						height: "100%",
						margin: 0,
						maxHeight: "90%",
					},
				}}
			>
				<DialogTitle>Vertrag hinzufügen</DialogTitle>
				<DialogContent>
					<Box sx={{ display: "flex", flexGrow: "0", padding: "0px", height: "100%" }}>
						<Vertragsspiegel hideTitle={true} />
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseVertragsPicker}>schließen</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export const GetMenuItem = (item, index, callback = null) => {
	const navigate = useNavigate();

	const handleNavigate = () => {
		if (callback !== null) callback();
		navigate(item.route);
	};

	if (Object.prototype.hasOwnProperty.call(item, "visible") && !item.visible) return null;
	switch (item.type) {
		case "navigation":
			return (
				<MenuItem key={index} onClick={() => handleNavigate()}>
					{item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
					<ListItemText sx={{ marginLeft: "8px" }}>
						<Typography variant="title">{item.label}</Typography>
					</ListItemText>
				</MenuItem>
			);
		case "action":
			return (
				<MenuItem key={index} onClick={() => item.action()}>
					{item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
					<ListItemText sx={{ marginLeft: "8px" }}>
						<Typography variant="title">{item.label}</Typography>
					</ListItemText>
				</MenuItem>
			);
		case "divider":
			return <Divider key={index} style={{ margin: 0 }} />;
		case "label":
			return (
				<Box key={index}>
					<ListItemText>
						<Typography variant="h3" sx={{ marginLeft: "16px", marginBottom: "24px" }}>
							{item.label}
						</Typography>
					</ListItemText>
				</Box>
			);
		default:
			return item.type + " is not implemented";
	}
};
