import PropTypes from "prop-types";
import Car from "assets/icons/Lineicon-car.svg?react";
import Civilwork from "assets/icons/Lineicon-civilwork.svg?react";
import Couple from "assets/icons/Lineicon-couple.svg?react";
import Diverse1 from "assets/icons/Lineicon-diverse-1.svg?react";
import Diverse from "assets/icons/Lineicon-diverse.svg?react";
import Elderly from "assets/icons/Lineicon-elderly.svg?react";
import Feminin from "assets/icons/Lineicon-feminin.svg?react";
import Idcard from "assets/icons/Lineicon-idcard.svg?react";
import Married from "assets/icons/Lineicon-married.svg?react";
import Masculin from "assets/icons/Lineicon-masculin.svg?react";
import Person from "assets/icons/Lineicon-person.svg?react";
import Selfemployed1 from "assets/icons/Lineicon-selfemployed-1.svg?react";
import Selfemployed from "assets/icons/Lineicon-selfemployed.svg?react";
import Study from "assets/icons/Lineicon-study.svg?react";
import Stop from "assets/icons/Stop.svg?react";
import FileUpload from "assets/icons/FileUpload.svg?react";
import QRCode from "assets/icons/QrCode.svg?react";
import Horse from "assets/icons/LineiconHorse.svg?react";
import Dog from "assets/icons/LineiconDog.svg?react";
import Boat from "assets/icons/LineiconBoat.svg?react";
import Hunting from "assets/icons/LineiconHunting.svg?react";
import Money from "assets/icons/LineiconMoney.svg?react";
import Plane from "assets/icons/LineiconPlane.svg?react";
import PersonFilled from "assets/icons/PersonFilled.svg?react";
import Child from "assets/icons/LineiconChild.svg?react";
import Home from "assets/icons/LineiconHome.svg?react";
import Homeownership from "assets/icons/LineiconHomeownership.svg?react";
import Oiltank from "assets/icons/LineiconOiltank.svg?react";
import Refurbish from "assets/icons/LineiconRefurbish.svg?react";
import Rent from "assets/icons/LineiconRent.svg?react";
import Solarpannel from "assets/icons/LineiconSolarpannel.svg?react";
import Healthservice from "assets/icons/LineiconHealthservice.svg?react";
import Healthinsurance from "assets/icons/LineiconHealthinsurance.svg?react";
import Healthcare from "assets/icons/LineiconHealthcare.svg?react";
import Target from "assets/icons/LineiconTarget.svg?react";
import Info from "assets/icons/InfoFilled.svg?react";
import Steps from "assets/icons/LineiconSteps.svg?react";
import Userdata from "assets/icons/LineiconUserdata.svg?react";
import Document from "assets/icons/LineiconDocument.svg?react";
import Fax from "assets/icons/LineiconFax.svg?react";
import Mail from "assets/icons/LineiconMail.svg?react";
import Phone from "assets/icons/LineiconPhone.svg?react";
import MoneyIncome from "assets/icons/LineiconMoneyIncome.svg?react";
import MoneySpending from "assets/icons/LineiconMoneySpending.svg?react";

import Work1 from "assets/icons/LineiconWork1.svg?react";
import BarchartUp from "assets/icons/LineiconBarchartUp.svg?react";
import Balancesheet from "assets/icons/LineiconBalancesheet.svg?react";
import Family from "assets/icons/LineiconFamily.svg?react";
import Legalinsurance from "assets/icons/LineiconLegalinsurance.svg?react";
import Work from "assets/icons/LineiconWork.svg?react";
import Healthcareinsurance from "assets/icons/LineiconHealthcareinsurance.svg?react";

import DrawRectangle from "assets/icons/LineiconToolRectangle-Stroke.svg?react";
import DrawOval from "assets/icons/LineiconToolOval-Stroke.svg?react";
import DrawTriangle from "assets/icons/LineiconToolTriangle-Stroke.svg?react";
import DrawCircle from "assets/icons/LineiconToolCircle-Stroke.svg?react";
import DrawSmallPen from "assets/icons/LineiconToolSmallPen-Fill.svg?react";
import DrawHighlighter from "assets/icons/LineiconToolHighlighter-Fill.svg?react";
import DrawPen from "assets/icons/LineiconToolPen-Fill.svg?react";
import DrawEraser from "assets/icons/LineiconToolEraser-Fill.svg?react";

import CardGame from "assets/icons/LineiconCardGame.svg?react";
import Comments from "assets/icons/LineiconComments.svg?react";
import Calculator from "assets/icons/LineiconCalculator.svg?react";
import Presentation from "assets/icons/LineiconPresentation.svg?react";
import Clientprofile from "assets/icons/LineiconClientprofile.svg?react";
import EditFilled from "assets/icons/EditFilled.svg?react";
import Score from "assets/icons/LineiconScore.svg?react";
import ArrowBack from "assets/icons/ArrowBackFilled.svg?react";
import Femaleperson from "assets/icons/LineiconFemaleperson.svg?react";
import Volunteer from "assets/icons/LineiconVolunteer.svg?react";
import Inspect from "assets/icons/LineiconInspect.svg?react";
import Position from "assets/icons/LineiconPosition.svg?react";

import HealthChecklist from "assets/icons/LineiconHealthChecklist.svg?react";
import QuestionMark from "assets/icons/QuestionMark.svg?react";
import Close from "assets/icons/Close.svg?react";
import Notizen from "assets/icons/LineiconToolComment.svg?react";

import MarkerToggle from "assets/icons/MarkerToggle.svg?react";
import PencilToggle from "assets/icons/PencilToggle.svg?react";
import PenToggle from "assets/icons/PenToggle.svg?react";
import RubberToggle from "assets/icons/RubberToggle.svg?react";
import ToolMouse from "assets/icons/ToolMouse.svg?react";
import ToolShape from "assets/icons/ToolShape.svg?react";
import ToolText from "assets/icons/ToolText.svg?react";
import ToolEllipse from "assets/icons/ToolEllipseButton.svg?react";
import ToolArrow from "assets/icons/ToolArrowButton.svg?react";
import ToolTriangle from "assets/icons/ToolTriangleButton.svg?react";
import ToolCircle from "assets/icons/ToolCircle.svg?react";
import ToolSquare from "assets/icons/ToolSquare.svg?react";

import ThicknessXSmall from "assets/icons/ThicknessXSmall.svg?react";
import ThicknessSmall from "assets/icons/ThicknessSmall.svg?react";
import ThicknessMedium from "assets/icons/ThicknessMedium.svg?react";
import ThicknessLarge from "assets/icons/ThicknessLarge.svg?react";
import ThicknessXLarge from "assets/icons/ThicknessXLarge.svg?react";

import PDF from "assets/icons/LineiconPDF.svg?react";
import Vehicle from "assets/icons/LineiconVehicle.svg?react";
import Video from "assets/icons/LineiconVideo.svg?react";
import Folder from "assets/icons/LineiconFolder.svg?react";
import Bycicle from "assets/icons/LineiconBycicle.svg?react";

function CustomIcon({ name, size = 32, width = 32, height = 32, borderTopRightRadius = "0px", borderBottomLeftRadius = "0px" }) {
	const style = {
		width: size ? size + "px" : width + "px",
		height: size ? size + "px" : height + "px",
		borderTopRightRadius,
		borderBottomLeftRadius,
	};
	switch (name) {
		case "DrawRectangle":
			return <DrawRectangle style={style} />;
		case "DrawOval":
			return <DrawOval style={style} />;
		case "DrawTriangle":
			return <DrawTriangle style={style} />;
		case "DrawCircle":
			return <DrawCircle style={style} />;
		case "DrawSmallPen":
			return <DrawSmallPen style={style} />;
		case "DrawHighlighter":
			return <DrawHighlighter style={style} />;
		case "DrawPen":
			return <DrawPen style={style} />;
		case "DrawEraser":
			return <DrawEraser style={style} />;

		case "Work1":
			return <Work1 style={style} />;
		case "BarchartUp":
			return <BarchartUp style={style} />;
		case "Balancesheet":
			return <Balancesheet style={style} />;
		case "Family":
			return <Family style={style} />;
		case "Legalinsurance":
			return <Legalinsurance style={style} />;
		case "Work":
			return <Work style={style} />;
		case "Healthcareinsurance":
			return <Healthcareinsurance style={style} />;
		case "MoneyIncome":
			return <MoneyIncome style={style} />;
		case "Fax":
			return <Fax style={style} />;
		case "MoneySpending":
			return <MoneySpending style={style} />;
		case "Userdata":
			return <Userdata style={style} />;
		case "Mail":
			return <Mail style={style} />;
		case "Phone":
			return <Phone style={style} />;
		case "Document":
			return <Document style={style} />;
		case "Steps":
			return <Steps style={style} />;
		case "Info":
			return <Info style={style} />;
		case "Target":
			return <Target style={style} />;
		case "Healthservice":
			return <Healthservice style={style} />;
		case "Healthinsurance":
			return <Healthinsurance style={style} />;
		case "Healthcare":
			return <Healthcare style={style} />;
		case "Home":
			return <Home style={style} />;
		case "Homeownership":
			return <Homeownership style={style} />;
		case "Oiltank":
			return <Oiltank style={style} />;
		case "Refurbish":
			return <Refurbish style={style} />;
		case "Rent":
			return <Rent style={style} />;
		case "Solarpannel":
			return <Solarpannel style={style} />;
		case "Car":
			return <Car style={style} />;
		case "Child":
			return <Child style={style} />;
		case "Civilwork":
			return <Civilwork style={style} />;
		case "Couple":
			return <Couple style={style} />;
		case "Diverse1":
			return <Diverse1 style={style} />;
		case "Diverse":
			return <Diverse style={style} />;
		case "Elderly":
			return <Elderly style={style} />;
		case "Feminin":
			return <Feminin style={style} />;
		case "Idcard":
			return <Idcard style={style} />;
		case "Married":
			return <Married style={style} />;
		case "Masculin":
			return <Masculin style={style} />;
		case "Person":
			return <Person style={style} />;
		case "Selfemployed1":
			return <Selfemployed1 style={style} />;
		case "Selfemployed":
			return <Selfemployed style={style} />;
		case "Study":
			return <Study style={style} />;
		case "FileUpload":
			return <FileUpload style={style} />;
		case "QRCode":
			return <QRCode style={style} />;
		case "Horse":
			return <Horse style={style} />;
		case "Dog":
			return <Dog style={style} />;
		case "Boat":
			return <Boat style={style} />;
		case "Hunting":
			return <Hunting style={style} />;
		case "Money":
			return <Money style={style} />;
		case "Plane":
			return <Plane style={style} />;
		case "PersonFilled":
			return <PersonFilled style={style} />;
		case "KeineAngabe":
			return <Stop style={style} />;

		case "CardGame":
			return <CardGame style={style} />;
		case "Comments":
			return <Comments style={style} />;
		case "Calculator":
			return <Calculator style={style} />;
		case "Presentation":
			return <Presentation style={style} />;
		case "Clientprofile":
			return <Clientprofile style={style} />;
		case "EditFilled":
			return <EditFilled style={style} />;
		case "Score":
			return <Score style={style} />;
		case "ArrowBack":
			return <ArrowBack style={style} />;
		case "Femaleperson":
			return <Femaleperson style={style} />;
		case "Volunteer":
			return <Volunteer style={style} />;
		case "Inspect":
			return <Inspect style={style} />;
		case "Position":
			return <Position style={style} />;
		case "HealthChecklist":
			return <HealthChecklist style={style} />;
		case "QuestionMark":
			return <QuestionMark style={style} />;
		case "Close":
			return <Close style={style} />;
		case "Notizen":
			return <Notizen style={style} />;
		case "MarkerToggle":
			return <MarkerToggle style={style} />;
		case "PencilToggle":
			return <PencilToggle style={style} />;
		case "PenToggle":
			return <PenToggle style={style} />;
		case "RubberToggle":
			return <RubberToggle style={style} />;
		case "ToolMouse":
			return <ToolMouse style={style} />;
		case "ToolShape":
			return <ToolShape style={style} />;
		case "ToolText":
			return <ToolText style={style} />;
		case "PDF":
			return <PDF style={style} />;
		case "Vehicle":
			return <Vehicle style={style} />;
		case "ToolEllipse":
			return <ToolEllipse style={style} />;
		case "ToolArrow":
			return <ToolArrow style={style} />;
		case "ToolTriangle":
			return <ToolTriangle style={style} />;
		case "ToolSquare":
			return <ToolSquare style={style} />;
		case "ToolCircle":
			return <ToolCircle style={style} />;
		case "ThicknessXSmall":
			return <ThicknessXSmall style={style} />;
		case "ThicknessSmall":
			return <ThicknessSmall style={style} />;
		case "ThicknessMedium":
			return <ThicknessMedium style={style} />;
		case "ThicknessLarge":
			return <ThicknessLarge style={style} />;
		case "ThicknessXLarge":
			return <ThicknessXLarge style={style} />;
		case "Video":
			return <Video style={style} />;
		case "Folder":
			return <Folder style={style} />;
		case "Bycicle":
			return <Bycicle style={style} />;
	}
}

CustomIcon.propTypes = {
	name: PropTypes.string.isRequired,
	size: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	borderTopRightRadius: PropTypes.string,
	borderBottomLeftRadius: PropTypes.string,
};

export default CustomIcon;
