import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { getEuro } from "services/formatService";
import CustomIcon from "./CustomIcon";
import Tile from "./Tile";

DIN_Euro_List.propTypes = {
	items: PropTypes.array,
	label: PropTypes.string,
	labelSum: PropTypes.string,
	sx: PropTypes.object,
};

export default function DIN_Euro_List(props) {
	const { items, label, labelSum, sx = {} } = props;
	if (!items || items.length === 0) return null;
	return (
		<Tile sx={{ ...sx, width: "50%", padding: "0px", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					pl: "16px",
					pt: "16px",
					pb: "16px",
					boxShadow: "0px 1px 0px #E6E6E6",
				}}
			>
				<Typography variant="h6">{label}</Typography>
			</Box>
			<Box sx={{ flexGrow: 1 }}>
				{items?.map((item, index) => (
					<Box
						key={index}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							pl: "16px",
							pr: "16px",
							pt: "8px",
							pb: "8px",
							borderBottomWidth: "1px",
							borderBottomStyle: "solid",
							borderBottomColor: "grey.300",
							minWidth: 0,
						}}
					>
						{item.value === null ? (
							<Box sx={{ height: "50px" }}></Box>
						) : (
							<Box sx={{ height: "50px", display: "flex", alignItems: "center", flexGrow: 1, minWidth: 0 }}>
								{item.icon && (
									<Box sx={{ pr: "16px", flexShrink: 0 }}>
										<CustomIcon name={item.icon} size={48} />
									</Box>
								)}
								<Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden", minWidth: 0 }}>
									<Typography variant="body1" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
										{item.label}
									</Typography>
									<Typography variant="body2">{getEuro(item.value, 0)}</Typography>
								</Box>
							</Box>
						)}
					</Box>
				))}
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					pl: "16px",
					pr: "16px",
					pt: "8px",
					pb: "8px",
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", flexGrow: 1, minWidth: 0 }}>
					<Box sx={{ pr: "16px", flexShrink: 0 }}>
						<Typography variant="body1">{labelSum}</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							overflow: "hidden",
							flexGrow: 1,
							textAlign: "right",
							minWidth: 0, // Wichtig für text-overflow
						}}
					>
						<Typography variant="h6">
							{getEuro(
								items.reduce((acc, item) => acc + item.value, 0),
								0
							)}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Tile>
	);
}
