import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, Typography, useTheme } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import DMTable, { flattenArray } from "components/DMTable";
import PropTypes from "prop-types";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { bedarfAtom } from "recoil/atoms/bedarfAtom";
import { calcSoll } from "services/DINSollCalculationService";
import { getDisplayName, getEuro } from "services/formatService";
import InfoDialog from "./FormRenderer/components/InfoDialog";

DIN_SollAnalyse.propTypes = {
	config: PropTypes.object,
};

const getHaushaltsmitglieder = (bedarf) => {
	const haushaltsmitglieder = [];
	haushaltsmitglieder.push({ geschlecht: bedarf.kunde.geschlecht, name: getDisplayName(bedarf.kunde), person_uuid: bedarf.kunde.uuid });
	if (bedarf.kunde.familienstand !== "ledig") haushaltsmitglieder.push({ geschlecht: bedarf.partner.geschlecht, name: getDisplayName(bedarf.partner), person_uuid: bedarf.partner.uuid });
	if (bedarf.kinder && bedarf.kinder.length > 0) {
		bedarf.kinder.forEach((kind) => {
			haushaltsmitglieder.push({ geschlecht: kind.geschlecht, name: getDisplayName(kind), person_uuid: kind.uuid });
		});
	}
	return haushaltsmitglieder;
};

export default function DIN_SollAnalyse(props) {
	const { config } = props;
	const [bedarf, setBedarf] = useRecoilState(bedarfAtom);
	const [activeModule, setActiveModule] = useState("Alle Bedarfsfelder");
	const appWindow = useRecoilValue(appWindowAtom);
	const calculatedSollwerte = useMemo(() => calcSoll(bedarf), []);
	const haushaltsmitglieder = getHaushaltsmitglieder(bedarf);

	const [sollwerte, setSollwerte] = useState(calculatedSollwerte);
	const [nurRelevant, setNurRelevant] = useState(true);
	const [haushaltsmitglied, setHaushaltsmitglied] = useState("Alle");
	const theme = useTheme();

	const handleChange = (event) => {
		setHaushaltsmitglied(event.target.value);
	};

	useEffect(() => {
		if (calculatedSollwerte) {
			let sollwerteFiltered = calculatedSollwerte;
			if (activeModule !== "Alle Bedarfsfelder") sollwerteFiltered = sollwerteFiltered.filter((sollwert) => sollwert.bedarfsfeld_vertrieb === activeModule);
			if (nurRelevant) sollwerteFiltered = sollwerteFiltered.filter((item) => item.relevant);
			if (haushaltsmitglied !== "Alle") {
				sollwerteFiltered = sollwerteFiltered.filter((item) => item.haushaltsmitglied.name === haushaltsmitglied.name);
			}
			setSollwerte(sollwerteFiltered);
		}
	}, [activeModule, nurRelevant, haushaltsmitglied]);

	const setNewActiveModule = (newActiveModule) => {
		// if (newActiveModule !== "Beratungsprozess") setWorkflow({ workflow: workflow.workflow, active_step: 0 });
		setActiveModule(newActiveModule);
	};

	const getSollFormatted = (item) => {
		switch (item.einheit) {
			case "euro":
				return getEuro(item.sollwert);
			case "euro_gerundet":
				return getEuro(item.sollwert, 0);
			case "string":
			default:
				return item.sollwert;
		}
	};

	const DINModulIcon = (props) => {
		const { bedarfsfeld } = props;
		let iconName = "";
		switch (bedarfsfeld) {
			case "Krankheit und Pflege":
				iconName = "Healthcareinsurance";
				break;
			case "Partner*in und ggf. Kinder":
				iconName = "Family";
				break;
			case "Liquidität und Vermögensbilanz":
				iconName = "Balancesheet";
				break;
			case "Haus und Wohnung":
				iconName = "Home";
				break;
			case "Mobilität und Reisen":
				iconName = "Car";
				break;
			case "Haftung und Rechtsschutz / Vermögensschutz":
				iconName = "Legalinsurance";
				break;
			case "Vorsorge für das Alter":
				iconName = "BarchartUp";
				break;
			case "Sparen und Vermögensbildung":
				iconName = "Work1";
				break;
			case "Arbeitskraftverlust":
				iconName = "Work";
				break;
			default:
				iconName = "";
		}

		return (
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<CustomIcon name={iconName} size={32} />
				<Box sx={{ pl: "8px" }}>{bedarfsfeld}</Box>
			</Box>
		);
	};

	const columnsDefiniton = [
		{
			accessorKey: "rang",
			header: "Rang",
			size: 70,
			muiTableBodyCellProps: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				if (objectToRender.relevant) return undefined;
				return { sx: { backgroundColor: theme.palette.grey[100] } };
			},
		},
		{
			accessorFn: (row) => flattenArray(row.bedarfsfeld_din),
			accessorKey: "bedarfsfeld_din",
			header: "DIN-Modul",
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return (
					<Box>
						{objectToRender.bedarfsfeld_din.map((bedarfsfeld, index) => (
							<DINModulIcon key={index} bedarfsfeld={bedarfsfeld} />
						))}
					</Box>
				);
			},
			size: 200,
			muiTableBodyCellProps: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				if (objectToRender.relevant) return undefined;
				return { sx: { backgroundColor: theme.palette.grey[100] } };
			},
		},
		{
			accessorFn: (row) => `${row.bedarfsfeld_vertrieb} ${row.finanzthema}`,
			header: "Bedarfsfeld/Risiko",
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return (
					<Fragment>
						<div>{objectToRender.bedarfsfeld_vertrieb}</div>
						<div>
							<b>{objectToRender.finanzthema}</b>
						</div>
					</Fragment>
				);
			},
			size: 200,
			muiTableBodyCellProps: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				if (objectToRender.relevant) return undefined;
				return { sx: { backgroundColor: theme.palette.grey[100] } };
			},
		},

		{
			accessorFn: (row) => `${row.haushaltsmitglied?.name}`,
			header: "Haushaltsmitglied",
			size: 100,
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<CustomIcon name={objectToRender.haushaltsmitglied?.geschlecht === "weiblich" ? "Femaleperson" : "Person"} size={32} />
						<Box sx={{ pl: "8px" }}>
							<b>{objectToRender.haushaltsmitglied?.name}</b>
						</Box>
					</Box>
				);
			},

			muiTableBodyCellProps: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				if (objectToRender.relevant) return undefined;
				return { sx: { backgroundColor: theme.palette.grey[100] } };
			},
		},
		{
			accessorFn: (row) => `${row.wert} ${row.einheit}`,
			header: "Sollwert",
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return (
					<Box>
						<div>
							{getSollFormatted(objectToRender)}
							<InfoDialog item={{ info: { title: "Erläuterung", description: objectToRender.info } }} />
						</div>
						<Typography variant="body2">
							<b>{objectToRender.risiko_label}</b>
						</Typography>
					</Box>
				);
			},
			size: 100,
			muiTableBodyCellProps: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				if (objectToRender.relevant) return { align: "right" };
				return { sx: { backgroundColor: theme.palette.grey[100] } };
			},
		},
	];

	return (
		<Box sx={{ height: appWindow.contentHeight - 100 + "px", padding: "0px", margin: "0px", overflowY: "auto" }}>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<Typography variant="h5" sx={{ marginRight: "auto", paddingBottom: "24px" }}>
					{activeModule}
				</Typography>
				<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
					<FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
						<InputLabel size="small">Haushaltsmitglied</InputLabel>
						<Select value={haushaltsmitglied} onChange={handleChange}>
							<MenuItem value="Alle">Alle</MenuItem>
							{haushaltsmitglieder.map((haushaltsmitglied) => (
								<MenuItem key={haushaltsmitglied.name} value={haushaltsmitglied}>
									{haushaltsmitglied.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControlLabel
						checked={nurRelevant || false}
						onChange={(e) => {
							setNurRelevant(e.target.checked);
						}}
						control={<Switch />}
						label="nur relevante Risiken anzeigen"
						sx={{ marginLeft: "auto", marginRight: 0 }}
					/>
				</Box>
			</Box>
			<DMTable
				data={sollwerte}
				columns={columnsDefiniton}
				searchword=""
				initialStateConfig={{
					sorting: [
						{
							id: "rang",
							asc: true,
						},
					],
				}}
			/>
		</Box>
	);
}

/*
		<Box sx={{ display: "flex", width: appWindow.contentWidth }}>
			<Tile sx={{ width: "400px", padding: "24px", mr: "16px", height: appWindow.contentHeight + "px", overflowY: "auto" }}>
				<Box sx={{ margin: "0px", padding: "0px" }}>
					<Box sx={{ pl: "8px", pt: "8px", pb: "24px", display: "flex", alignItems: "center" }}>
						<Box sx={{ pl: "8px" }}>
							<Typography variant="h5" component="div">
								DIN-Sollwerte
							</Typography>
						</Box>
					</Box>
					<AppModuleTile label="Alle Bedarfsfelder" activeModule={activeModule} setActiveModule={setNewActiveModule} />
					<AppModuleTile background_image="/images/SdE1.svg" label="Sicherung des Einkommens" activeModule={activeModule} setActiveModule={setNewActiveModule} />
					<AppModuleTile background_image="/images/SdV1.jpg" label="Sicherung der Vermögenswerte" activeModule={activeModule} setActiveModule={setNewActiveModule} />
					<AppModuleTile background_image="/images/HuF2.jpg" label="Haus & Familie" activeModule={activeModule} setActiveModule={setNewActiveModule} />
					<AppModuleTile background_image="/images/SdG1.svg" label="Sicherung der Gesundheit" activeModule={activeModule} setActiveModule={setNewActiveModule} />
					<AppModuleTile background_image="/images/AV3.jpg" label="Altersvorsorge" activeModule={activeModule} setActiveModule={setNewActiveModule} />
					<AppModuleTile background_image="/images/VAO6.svg" label="Vermögensaufbau/-optimierung" activeModule={activeModule} setActiveModule={setNewActiveModule} />
				</Box>
			</Tile>
		</Box>
*/
