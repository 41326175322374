import { Clear } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, List, ListItem, MenuItem, Select, Switch, Typography, useTheme } from "@mui/material";
import Chart from "components/Chart";
import CustomIcon from "components/CustomIcon";
import DMTable from "components/DMTable";
import Tile from "components/Tile";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { bedarfAtom } from "recoil/atoms/bedarfAtom";
import { vertragszuordnungAtom } from "recoil/atoms/vertragszuordnungAtom";
import { backendGet, backendPost } from "services/dataService";
import { calcIst, isGroupingRang } from "services/DINIstCalculationService";
import { calcSoll } from "services/DINSollCalculationService";
import { getEuro, getDisplayName } from "services/formatService";
import YesNoComponent from "./FormRenderer/components/YesNoComponent";
import Vertragsspiegel from "./Vertragsspiegel";
import InfoDialog from "./FormRenderer/components/InfoDialog";
import AnalyseErgebnisse from "./AnalyseErgebnisse";

DIN_Analyse.propTypes = {
	config: PropTypes.object,
};
const getHaushaltsmitglieder = (bedarf) => {
	console.log("bedarf", bedarf);
	const haushaltsmitglieder = [];
	haushaltsmitglieder.push({ geschlecht: bedarf.kunde.geschlecht, name: getDisplayName(bedarf.kunde), person_uuid: bedarf.kunde.uuid });
	if (bedarf.kunde.familienstand !== "ledig") haushaltsmitglieder.push({ geschlecht: bedarf.partner.geschlecht, name: getDisplayName(bedarf.partner), person_uuid: bedarf.partner.uuid });
	if (bedarf.kinder && bedarf.kinder.length > 0) {
		bedarf.kinder.forEach((kind) => {
			haushaltsmitglieder.push({ geschlecht: kind.geschlecht, name: getDisplayName(kind), person_uuid: kind.uuid });
		});
	}
	return haushaltsmitglieder;
};
export default function DIN_Analyse(props) {
	const { config } = props;
	const { enqueueSnackbar } = useSnackbar();
	//const [haushalt, setHaushalt] = useRecoilState(haushaltAtom);
	const [vertraege, setVertraege] = useState([]);
	const [bedarf, setBedarf] = useRecoilState(bedarfAtom);
	const [activeModule, setActiveModule] = useState("Alle Bedarfsfelder");
	const appWindow = useRecoilValue(appWindowAtom);
	const calculatedSollwerte = useMemo(() => calcSoll(bedarf), []);
	const [calculatedDisplaywerte, setCalculatedDisplaywerte] = useState(calcIst(calculatedSollwerte, [], bedarf, config.isSimulation));
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const [vertragToRemove, setVertragToRemove] = useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [openDINErgebnise, setOpenDINErgebnise] = useState(false);

	const handleShowDINErgebnisse = (row) => {
		setOpenDINErgebnise(true);
	};

	const handleCloseDINErgebnisse = () => {
		setOpenDINErgebnise(false);
	};

	const haushaltsmitglieder = getHaushaltsmitglieder(bedarf);

	const [displaywerte, setDisplaywerte] = useState(calculatedDisplaywerte);
	const [nurRelevant, setNurRelevant] = useState(true);
	const [haushaltsmitglied, setHaushaltsmitglied] = useState("Alle");
	const theme = useTheme();
	const [open, setOpen] = useState(false);

	const [vertragszuordnung, setVertragszuordnung] = useRecoilState(vertragszuordnungAtom);

	useEffect(() => {
		const newValues = calcIst(calculatedSollwerte, vertragszuordnung, bedarf, config.isSimulation);
		setCalculatedDisplaywerte(newValues);
	}, [config]);

	useEffect(() => {
		backendGet({ label: "Kunden", route: "vertrag/haushalt/" + bedarf.kunde.haushalt_id, setResult: setVertragsspiegelData, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
		backendGet({ label: "Vertragszuordnung", route: "vertragszuordnung/" + bedarf.kunde.haushalt_id, setResult: setResultVertragszuordnung, setIsLoading: setIsLoading, enqueueSnackbar: enqueueSnackbar });
	}, [bedarf]);

	const setResultVertragszuordnung = (data) => {
		if (data?.zuordnung) {
			setVertragszuordnung(data.zuordnung);
		}
	};

	const setVertragsspiegelData = (data) => {
		setVertraege(data);
	};

	const [activeRow, setActiveRow] = useState();

	useEffect(() => {
		setCalculatedDisplaywerte(calcIst(calculatedSollwerte, vertragszuordnung, bedarf, config.isSimulation));
	}, [vertragszuordnung]);

	const handleShowVertragsPicker = (row) => {
		setActiveRow(row);
		setOpen(true);
	};

	const handleCloseVertragsPicker = () => {
		setOpen(false);
	};

	const handleAddVertrag = (vertrag, wert, uuid_deckung) => {
		let zuordnungNeu = [...vertragszuordnung];

		if (isGroupingRang(activeRow.rang)) {
			const relevanteSollwerte = calculatedSollwerte.filter((sollwert) => sollwert.rang === activeRow.rang && sollwert.relevant);
			relevanteSollwerte.forEach((sollwert) => {
				zuordnungNeu = [...zuordnungNeu, { ...vertrag, din_wert: wert, uuid_deckung: uuid_deckung, rang: sollwert.rang, person_uuid: sollwert.haushaltsmitglied.person_uuid }];
			});
		} else {
			zuordnungNeu = [
				...zuordnungNeu,
				{
					...vertrag,
					din_wert: wert,
					uuid_deckung: uuid_deckung,
					rang: activeRow.rang,
					person_uuid: activeRow.haushaltsmitglied.person_uuid,
				},
			];
		}

		let targetRang = null;
		switch (Number(activeRow.rang)) {
			case 1:
				if (vertrag.produkt_id === 4) targetRang = 6;
				break;
			case 3:
				targetRang = 23;
				break;
			case 4:
				targetRang = 24;
				break;
			case 5:
				targetRang = 25;
				break;
			case 7:
				targetRang = 26;
				break;
			case 20:
				targetRang = 29;
				break;
			case 18:
				targetRang = 27;
				break;
			default:
		}

		if (targetRang) {
			zuordnungNeu = [
				...zuordnungNeu,
				{
					...vertrag,
					din_wert: wert,
					uuid_deckung: uuid_deckung,
					rang: targetRang,
					person_uuid: activeRow.haushaltsmitglied.person_uuid,
				},
			];
		}

		backendPost({
			label: "Vertragszuordnung",
			route: "vertragszuordnung/store",
			toSend: {
				haushalt_id: bedarf.kunde.haushalt_id,
				zuordnung: zuordnungNeu,
			},
			setResult: setResultVertragszuordnung,
			setIsLoading: setIsLoading,
			enqueueSnackbar: enqueueSnackbar,
		});
		setVertragszuordnung(zuordnungNeu);
	};

	const handleChange = (event) => {
		setHaushaltsmitglied(event.target.value);
	};

	useEffect(() => {
		if (calculatedDisplaywerte) {
			let displaywerteFiltered = calculatedDisplaywerte;
			if (activeModule !== "Alle Bedarfsfelder") displaywerteFiltered = displaywerteFiltered.filter((sollwert) => sollwert.bedarfsfeld_vertrieb === activeModule);
			if (nurRelevant) displaywerteFiltered = displaywerteFiltered.filter((item) => item.relevant);
			if (haushaltsmitglied !== "Alle") {
				displaywerteFiltered = displaywerteFiltered.filter((item) => item.haushaltsmitglied.name === haushaltsmitglied.name);
			}
			setDisplaywerte(displaywerteFiltered);
		}
	}, [activeModule, nurRelevant, haushaltsmitglied, calculatedDisplaywerte]);

	const handleYesAction = () => {
		let newVertragszuordnung = null;
		if (isGroupingRang(vertragToRemove.rang)) {
			newVertragszuordnung = vertragszuordnung.filter(
				(vertrag) => !(vertrag.id === vertragToRemove.id && vertrag.rang === vertragToRemove.rang && vertrag.uuid_deckung === vertragToRemove.uuid_deckung && vertrag.din_wert === vertragToRemove.din_wert)
			);
		} else {
			newVertragszuordnung = vertragszuordnung.filter(
				(vertrag) =>
					!(
						vertrag.id === vertragToRemove.id &&
						vertrag.rang === vertragToRemove.rang &&
						vertrag.uuid_deckung === vertragToRemove.uuid_deckung &&
						vertrag.person_uuid === vertragToRemove.person_uuid &&
						vertrag.din_wert === vertragToRemove.din_wert
					)
			);

			let targetRang = null;
			switch (Number(vertragToRemove.rang)) {
				case 1:
					if (vertragToRemove.produkt_id === 4)
						// Gesetzliche KV --> kopple Rang 1 und 6
						targetRang = 6;
					break;
				case 3:
					targetRang = 23;
					break;
				case 4:
					targetRang = 24;
					break;
				case 5:
					targetRang = 25;
					break;
				case 6:
					if (vertragToRemove.produkt_id === 4)
						// Gesetzliche KV --> kopple Rang 1 und 6
						targetRang = 1;
					break;
				case 7:
					targetRang = 26;
					break;
				case 20:
					targetRang = 29;
					break;
				case 18:
					targetRang = 27;
					break;
				case 23:
					targetRang = 3;
					break;
				case 24:
					targetRang = 4;
					break;
				case 25:
					targetRang = 5;
					break;
				case 26:
					targetRang = 7;
					break;
				case 29:
					targetRang = 20;
					break;
				case 27:
					targetRang = 18;
					break;
				default:
			}
			if (targetRang) {
				newVertragszuordnung = newVertragszuordnung.filter(
					(vertrag) =>
						!(vertrag.id === vertragToRemove.id && vertrag.rang === targetRang && vertrag.uuid_deckung === vertragToRemove.uuid_deckung && vertrag.person_uuid === vertragToRemove.person_uuid && vertrag.din_wert === vertragToRemove.din_wert)
				);
			}
		}
		backendPost({
			label: "Vertragszuordnung",
			route: "vertragszuordnung/store",
			toSend: {
				haushalt_id: bedarf.kunde.haushalt_id,
				zuordnung: newVertragszuordnung,
			},
			setResult: setResultVertragszuordnung,
			setIsLoading: setIsLoading,
			enqueueSnackbar: enqueueSnackbar,
		});
		setVertragszuordnung(newVertragszuordnung);
	};

	const handleOpenDialog = (vertrag) => {
		setVertragToRemove(vertrag);
		setDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
	};
	const getFormattedValue = (wert, einheit) => {
		switch (einheit) {
			case "euro":
				return getEuro(wert);
			case "euro_gerundet":
				return getEuro(wert, 0);
			case "string":
			default:
				return wert;
		}
	};

	const getTableBodyCellProps = (tableCellObject, passedItem, sx = {}) => {
		const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
		if (objectToRender.relevant) return { sx: { ...sx, verticalAlign: "top" } };
		return { sx: { ...sx, backgroundColor: theme.palette.grey[100], verticalAlign: "top" } };
	};

	const getModuleLabel = () => {
		if (config.isSimulation) return "DIN Simulation";
		return "DIN Soll/Ist Analyse";
	};

	const getPieChart = (row) => {
		return (
			<Box sx={{ display: "flex", flexDirection: "column", height: "64px", width: "100%" }}>
				<Box sx={{ flex: "1 1 40%", position: "relative", alignContent: "center" }}>
					<Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
						<Chart
							chartDef={{
								key: "bewertung_" + row.rang + "_" + row.haushaltsmitglied?.name,
								unit: "%",
								size: "48",
								chart_type: "doughnut",
								data: [
									{
										value: row.ist_percent,
										color: "#4caf50",
										label: "Istwert",
									},
									{
										value: 100 - row.ist_percent,
										color: "#EF5350",
										label: "fehlt",
									},
								],
							}}
						/>
					</Box>
					<Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
						<Typography variant="caption">{Math.round(row.ist_percent, 1)}%</Typography>
					</Box>
				</Box>
				<Box sx={{ width: "100%", textAlign: "center", marginTop: "8px" }}>
					<Typography variant="body2">{getFormattedValue(row.ist_wert, row.einheit)}</Typography>
				</Box>
			</Box>
		);
	};

	const getAbsicherung = (row) => {
		return (
			<Fragment>
				<List dense={true} sx={{ ml: "8px", mr: "8px" }}>
					{row.vertraege?.map((vertrag, index) => {
						return (
							<ListItem
								key={index}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center", // Zentriert den Inhalt horizontal
									borderStyle: "solid",
									borderWidth: "1px",
									borderColor: "#E6E6E6",
									borderRadius: "8px",
									margin: "2px",
									padding: "2px",
								}}
							>
								<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
									<Box sx={{ display: "flex", flexDirection: "row", padding: 0, margin: 0 }}>
										<Box sx={{ margin: "4px", padding: "0px" }}>
											<img src={`pg_logos/${vertrag.produktgeber.logo}`} alt="" height="32px" />
										</Box>
										<Box sx={{ ml: "4px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
											<Typography variant="body2">{vertrag.produkt.produkt}</Typography>
											<Typography variant="body2">{typeof vertrag.din_wert === "string" ? vertrag.din_wert : getEuro(vertrag.din_wert, 0)}</Typography>
										</Box>
										<Box sx={{ ml: "auto" }}>
											<IconButton onClick={() => handleOpenDialog(vertrag)}>
												<Clear />
											</IconButton>
										</Box>
									</Box>
								</Box>
							</ListItem>
						);
					})}
					<Button sx={{ fontSize: "13px", fontWeight: 400, lineHeight: "13px", pr: "8px", pl: "8px", pt: "4px", pb: "4px", height: "30px", textTransform: "none" }} variant="outlined" onClick={() => handleShowVertragsPicker(row)}>
						Vertrag hinzufügen
					</Button>
				</List>
			</Fragment>
		);
	};

	const columnsDefiniton = [
		{
			accessorKey: "rang",
			header: "Rang",
			size: 50,
			muiTableBodyCellProps: (tableCellObject, passedItem) => getTableBodyCellProps(tableCellObject, passedItem),
		},
		{
			accessorFn: (row) => `${row.bedarfsfeld_vertrieb} ${row.finanzthema}`,
			header: "Bedarfsfeld/Risiko",
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return (
					<Fragment>
						<div>{objectToRender.bedarfsfeld_vertrieb}</div>
						<div>
							<b>{objectToRender.finanzthema}</b>
						</div>
					</Fragment>
				);
			},
			size: 200,
			muiTableBodyCellProps: (tableCellObject, passedItem) => getTableBodyCellProps(tableCellObject, passedItem),
		},
		{
			accessorFn: (row) => `${row.haushaltsmitglied?.name}`,
			header: "Haushaltsmitglied",
			size: 100,
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<CustomIcon name={objectToRender.haushaltsmitglied?.geschlecht === "weiblich" ? "Femaleperson" : "Person"} size={32} />
						<Box sx={{ pl: "8px", textAlign: "left" }}>
							<b>{objectToRender.haushaltsmitglied?.name}</b>
						</Box>
					</Box>
				);
			},
			muiTableBodyCellProps: (tableCellObject, passedItem) => getTableBodyCellProps(tableCellObject, passedItem, { textAlign: "right" }),
		},
		{
			accessorFn: (row) => `${row.sollwert} ${row.risiko_label}`,
			header: "Sollwert",
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return (
					<Box>
						<div>
							{getFormattedValue(objectToRender.sollwert, objectToRender.einheit)}
							<InfoDialog item={{ info: { title: "Erläuterung", description: objectToRender.info } }} />
						</div>
						<Typography variant="body2">
							<b>{objectToRender.risiko_label}</b>
						</Typography>
					</Box>
				);
			},
			size: 100,
			muiTableBodyCellProps: (tableCellObject, passedItem) => getTableBodyCellProps(tableCellObject, passedItem, { textAlign: "right" }),
		},
		{
			accessorFn: (row) => `${row.wert} ${row.einheit}`,
			header: "Bewertung Istwert",
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return getPieChart(objectToRender);
			},
			size: 100,
			muiTableBodyCellProps: (tableCellObject, passedItem) => getTableBodyCellProps(tableCellObject, passedItem),
		},
		{
			accessorFn: (row) => `${row.wert} ${row.einheit}`,
			header: "Abgesichert durch",
			Cell: (tableCellObject, passedItem) => {
				const objectToRender = tableCellObject ? tableCellObject.row.original : passedItem;
				return getAbsicherung(objectToRender);
			},
			size: 150,
			muiTableBodyCellProps: (tableCellObject, passedItem) => getTableBodyCellProps(tableCellObject, passedItem),
		},
	];

	return (
		<Fragment>
			<Box sx={{ height: appWindow.contentHeight - 100 + "px", padding: "0px", margin: "0px", overflowY: "auto" }}>
				<Tile sx={{ width: appWindow.contentWidth + "px", padding: "24px", margin: "0px", height: appWindow.contentHeight + "px" }}>
					<Box sx={{ height: appWindow.contentHeight - 100 + "px", padding: "0px", margin: "0px", overflowY: "auto" }}>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Typography variant="h5" sx={{ marginRight: "auto", paddingBottom: "24px" }} onClick={handleShowDINErgebnisse}>
								{getModuleLabel()} - {activeModule}
							</Typography>
							<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
								<FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
									<InputLabel size="small">Haushaltsmitglied</InputLabel>
									<Select value={haushaltsmitglied} onChange={handleChange}>
										<MenuItem value="Alle">Alle</MenuItem>
										{haushaltsmitglieder.map((haushaltsmitglied) => (
											<MenuItem key={haushaltsmitglied?.name} value={haushaltsmitglied}>
												{haushaltsmitglied?.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControlLabel
									checked={nurRelevant || false}
									onChange={(e) => {
										setNurRelevant(e.target.checked);
									}}
									control={<Switch />}
									label="nur relevante Risiken anzeigen"
									sx={{ marginLeft: "auto", marginRight: 0 }}
								/>
							</Box>
						</Box>
						<DMTable
							data={displaywerte}
							columns={columnsDefiniton}
							searchword=""
							initialStateConfig={{
								sorting: [
									{
										id: "rang",
										asc: true,
									},
								],
							}}
						/>
					</Box>
				</Tile>
			</Box>
			<Dialog
				open={open}
				onClose={handleCloseVertragsPicker}
				maxWidth={false}
				PaperProps={{
					style: {
						width: "100%",
						height: "100%",
						margin: 0,
						maxHeight: "90%",
					},
				}}
			>
				<DialogTitle>
					Vertrag hinzufügen (Rang {activeRow?.rang} - {activeRow?.finanzthema} - {activeRow?.haushaltsmitglied?.name} -- Sollwert: {typeof activeRow?.sollwert === "string" ? activeRow?.sollwert : getEuro(activeRow?.sollwert, 0)} --
					Istwert: {typeof activeRow?.ist_wert === "string" ? activeRow?.ist_wert : getEuro(activeRow?.ist_wert, 0)})
				</DialogTitle>
				<DialogContent>
					<Box sx={{ display: "flex", flexGrow: "0", padding: "0px", height: "100%" }}>
						<Vertragsspiegel hideTitle={true} activeRow={activeRow} addCallback={handleAddVertrag} isSimulation={config.isSimulation} />
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseVertragsPicker}>schließen</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openDINErgebnise}
				onClose={handleCloseDINErgebnisse}
				maxWidth={false}
				PaperProps={{
					style: {
						width: "100%",
						height: "100%",
						margin: 0,
						maxHeight: "90%",
					},
				}}
			>
				<DialogTitle>DIN 77230 Ergebnisse</DialogTitle>
				<DialogContent>
					<Box sx={{ display: "flex", flexGrow: "0", padding: "0px", height: "100%" }}>
						<AnalyseErgebnisse hideTitle={true} />
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDINErgebnisse}>schließen</Button>
				</DialogActions>
			</Dialog>
			<YesNoComponent open={dialogOpen} onClose={handleCloseDialog} onYes={handleYesAction} title="Wirklich?" message={"Soll die Zuordnung der Deckung wirklich gelöscht werden?"} />
		</Fragment>
	);
}
