import { sollwerte } from "./din_sollwerte";
import { getAlter, getAlterMonate, getErsterTagImMonat, getLabel } from "./formatService";
import { investitionszieleOptions, besondereTaetigkeitOptions, taetigkeitsstatusOptions } from "./optionsService";

export const INFLATIONSRATE = 0.023;
export const MINDESTBEDARF = 1563.66;
export const MINDESTBEITRAGSBEMESSUNGSGRUNDLAGE = 1178.33;
export const BEITRAGSSATZ_RV = 0.186;
export const BEITRAGSSATZ_AV = 0.026;
export const BEITRAGSSATZ_KV = 0.146;
export const DURSCHNITTLICHER_ZUSATZBEITRAG_KV = 0.017;
export const BEITRAGSATZ_PPV_MIN = 0.024;
export const BEITRAGSATZ_PPV_MAX = 0.034;
export const KINDERLOSENZUSCHLAG_PPV = 0.006;
export const MINDESTBEITRAG_GKV_PPV_OHNE_KIND = 239.2;
export const MINDESTBEITRAG_GKV_PPV_MIT_KIND = 232.13;
export const HALBER_REGELBEITRAG_GRV_WEST = 328.76;
export const HALBER_REGELBEITRAG_GRV_OST = 322.25;
export const BEITRAGSBEMESSUNGSGRENZE_KV = 62100;
export const BAFOEG_HOECHSTSATZ = 934;

export const RENDITEKLASSE_1 = 0.01;
export const RENDITEKLASSE_2 = 0.045;

export const PAUSCH_FAKTOR_HAUSRAT = 650;
export const DURSCHNITTLICHER_EIGENANTEIL_PFLEGE = 1800;

export const isWirtschaftlichAbhaengigesKind = (kind) => {
	let alter = 0;
	if (kind.geburtsdatum !== undefined) alter = getAlter(kind.geburtsdatum);
	if (alter > 0 && alter < 18) return true;
	if (alter >= 18 && alter <= 24 && kind.kindergeldanspruch && kind.taetigkeitsstatus !== "ausbildung" && !kind.verheiratet) return true;
	return false;
};

const calcSollDetail = (gesamtModel, modelPerson, modelHaushalt, bezeichnungHaushaltsmitglied, isKundePartner, kinder) => {
	//console.log("calcSollDetail");
	//console.log("gesamtModel", gesamtModel);
	/*console.log("modelPerson", modelPerson);
	console.log("modelHaushalt", modelHaushalt);
	console.log("bezeichnungHaushaltsmitglied", bezeichnungHaushaltsmitglied);
	console.log("isKundePartner", isKundePartner);
	console.log("kinder", kinder);
	console.log("personenRolle", personenRolle);*/
	const getValue = (attribute, defaultValue, model = modelPerson) => {
		const keys = attribute.split(".");
		let result = model;
		if (result === undefined) return defaultValue;
		for (const key of keys) {
			if (result[key] !== undefined) {
				result = result[key];
			} else {
				return defaultValue;
			}
		}
		return result;
	};

	const getValueHaushalt = (attribute, defaultValue) => {
		return getValue(attribute, defaultValue, modelHaushalt);
	};

	let tmpSollwerte = JSON.parse(JSON.stringify(sollwerte));

	const eigentum = getValueHaushalt("wohnsitz", []).filter((wohnsitz) => wohnsitz.auswahl === "eigentum", []);
	const miete = getValueHaushalt("wohnsitz", []).filter((wohnsitz) => wohnsitz.auswahl === "zur_miete", []);
	const kaufobjekte = getValueHaushalt("kaufobjekte", []);
	const verbindlichkeiten = getValueHaushalt("verbindlichkeiten", []);
	const investitionsziele = getValueHaushalt("investitionsziele", []);

	const getSum = (werte, column) => {
		let result = 0;
		werte.forEach((wert) => {
			result += wert[column];
		});
		return result;
	};

	const getAnzahlHaushaltsverantwortliche = () => {
		let result = 1;

		if (gesamtModel.kunde.familienstand === "verheiratet" || gesamtModel.kunde.familienstand === "partnerschaft") result++;
		return result;
	};

	const updateAtribut = (rang, setters) => {
		const risiko_uuid = setters.risiko_uuid || "";
		let objekt = tmpSollwerte.find((item) => item.rang === rang && item.risiko_uuid === risiko_uuid);

		if (objekt === undefined) {
			objekt = JSON.parse(JSON.stringify(tmpSollwerte.find((item) => item.rang === rang)));
			tmpSollwerte.push(objekt);
		}

		objekt["haushaltsmitglied"] = { person_uuid: modelPerson.uuid, name: bezeichnungHaushaltsmitglied, geschlecht: modelPerson.geschlecht };

		setters.forEach((setter) => {
			objekt[setter.key] = setter.value;
		});
	};

	const is = (attribute, compare) => {
		let result = false;
		if (modelPerson[attribute] && modelPerson[attribute] === compare) result = true;
		return result;
	};

	const isNot = (attribute, compare) => {
		let result = false;
		if (modelPerson[attribute] && modelPerson[attribute] !== compare) result = true;
		return result;
	};

	const isOneOf = (attribute, compare, model = modelPerson) => {
		let result = false;

		if (model && model[attribute]) {
			if (Array.isArray(model[attribute])) {
				result = compare.some((item) => model[attribute].includes(item));
			} else {
				result = compare.includes(model[attribute]);
			}
		}
		return result;
	};

	const isNotOneOf = (attribute, compare, model = modelPerson) => {
		return !isOneOf(attribute, compare, model);
	};

	const isOneOfHaushalt = (attribute, compare) => {
		return isOneOf(attribute, compare, modelHaushalt);
	};

	const getGRVBeitrag = () => {
		if (plzRegion(modelPerson.plz) === "Ostdeutschland") return HALBER_REGELBEITRAG_GRV_OST;
		return HALBER_REGELBEITRAG_GRV_WEST;
	};

	const plzRegion = (plz) => {
		// Sicherstellen, dass die PLZ eine Zahl ist und 5 Zeichen lang
		if (typeof plz !== "string" || !/^\d{5}$/.test(plz)) {
			return "West"; //Ungültige Postleitzahl --> default Westdeutschland
		}

		const plzPrefix = parseInt(plz.substring(0, 2));

		// Ostdeutsche PLZ-Bereiche (inklusive Ostberlin)
		const ostPlz = [
			[1, 19], // 01xxx - 19xxx (Sachsen, Brandenburg, Mecklenburg-Vorpommern, Thüringen, etc.)
			[39, 39], // 39xxx (Sachsen-Anhalt)
		];

		// Westberlin Bereich explizit ausschließen
		const westBerlin = ["10115", "10117", "10119", "10178", "10179", "10405", "10407", "10409", "10435", "10437", "10439"];

		// Prüfen, ob PLZ in Westberlin liegt
		if (westBerlin.includes(plz)) {
			return "Westdeutschland";
		}

		// Prüfen, ob PLZ in Ostdeutschland liegt
		for (const [min, max] of ostPlz) {
			if (plzPrefix >= min && plzPrefix <= max) {
				return "Ostdeutschland";
			}
		}

		// Andernfalls Westdeutschland
		return "Westdeutschland";
	};

	const getAnzKinder_abhaengig = () => {
		//wirtschaftlich abhängige Kinder: < 18 || 18 - 24 && Kindergeldanspruch && nicht Ausbildung && nicht verheiratet
		let result = 0;
		kinder.forEach((kind) => {
			if (isWirtschaftlichAbhaengigesKind(kind)) result++;
		});
		return result;
	};

	const getAnzKinderUnter18 = () => {
		let result = 0;
		kinder.forEach((kind) => {
			if (getAlter(kind.geburtsdatum) < 18) result++;
		});
		return result;
	};

	const getAnzImmobilien = () => {
		let result = 0;

		result += eigentum.length;
		result += getValueHaushalt("immobilien", []).length;
		return result;
	};

	const isWithinNext12Months = (date) => {
		const today = new Date();
		const dateIn12Months = new Date(today);
		dateIn12Months.setMonth(today.getMonth() + 12);
		return date >= today && date <= dateIn12Months;
	};

	const getAnzBauSanierung = () => {
		let result = 0;
		eigentum.forEach((wohnsitz) => {
			if (wohnsitz.eigene_immobilie.sanierung_geplant) result++;
		});
		getValueHaushalt("immobilien", []).forEach((immobilie) => {
			if (immobilie.sanierung_geplant) result++;
		});
		getValueHaushalt("kaufobjekte", []).forEach((kaufobjekt) => {
			if (isWithinNext12Months(new Date(kaufobjekt.erwerb_zum))) result++;
		});
		return result;
	};

	const getAnzHeizoel = () => {
		let result = 0;
		eigentum.forEach((wohnsitz) => {
			if (wohnsitz.eigene_immobilie.oeltank) result++;
		});
		getValueHaushalt("immobilien", []).forEach((immobilie) => {
			if (immobilie.oeltank) result++;
		});
		getValueHaushalt("grundstuecke", []).forEach((grundstueck) => {
			if (grundstueck.oeltank) result++;
		});
		return result;
	};

	const getAnzPhotovoltaik = () => {
		let result = 0;
		eigentum.forEach((wohnsitz) => {
			if (wohnsitz.eigene_immobilie.photovoltaik) result++;
		});
		getValueHaushalt("immobilien", []).forEach((immobilie) => {
			if (immobilie.photovoltaik) result++;
		});
		return result;
	};

	const getAnzBesondereTaetigkeiten = () => {
		let result = 0;
		const besondere_taetigkeit = getValue("besondere_taetigkeit", []);
		if (Array.isArray(besondere_taetigkeit) && besondere_taetigkeit.length > 0) {
			result++;
		}
		if (getValue("taetigkeitsstatus") === "beamtet" || getValue("taetigkeitsstatus") === "angestellt_oeffentlicher_dienst") result++;
		return result;
	};

	const getBesondereTaetigkeiten = () => {
		let besondere_taetigkeiten = [...getValue("besondere_taetigkeit", [])];
		if (getValue("taetigkeitsstatus") === "beamtet" || getValue("taetigkeitsstatus") === "angestellt_oeffentlicher_dienst") {
			besondere_taetigkeiten.push("Beamter/öffentlicher Dienst");
		}
		return besondere_taetigkeiten;
	};

	const getSumHausrat = () => {
		let result = 0;

		eigentum.forEach((wohnsitz) => {
			result += wohnsitz.eigene_immobilie.hausrat_wert;
		});
		miete.forEach((wohnsitz) => {
			result += wohnsitz.mietobjekt.hausrat_wert;
		});
		return result;
	};

	//Prüfung, ob es im Haushalt Fahrzeuge (Autos, Boote, Flugzeuge, Sonstige) gibt
	const hasFahrzeug = () => {
		const anzFahrzeuge = getValueHaushalt("fahrzeuge.autos", []).length + getValueHaushalt("fahrzeuge.boote", []).length + getValueHaushalt("fahrzeuge.flugzeuge", []).length + getValueHaushalt("fahrzeuge.sonstige", []).length;
		return anzFahrzeuge > 0;
	};

	const hasImmobilienfinanzierung = () => {
		let result = false;
		eigentum.forEach((wohnsitz) => {
			if (wohnsitz.eigene_immobilie.darlehensstand > 0) result = true;
		});
		getValueHaushalt("immobilien", []).forEach((immobilie) => {
			if (immobilie.darlehensstand > 0) result = true;
		});
		return result;
	};

	//Betrag * 1*Inflationsrate^Jahre (mit Monaten)
	const getHochrechnung = (betrag, monate) => {
		let result = betrag;
		result = result * (1 + INFLATIONSRATE) ** (monate / 12);
		//1563,66 * (1 + 0,023) ^(165/12)

		return result;
	};

	const getStandKonsumentendarlehen = () => {
		return getSum(
			verbindlichkeiten.filter((verbindlichkeit) => verbindlichkeit.bezeichnung === "konsumentenkredit"),
			"wert"
		);
	};

	const getStandUeberziehungskredite = () => {
		return getSum(
			verbindlichkeiten.filter((verbindlichkeit) => verbindlichkeit.bezeichnung === "ueberziehungkredit"),
			"wert"
		);
	};

	const getStandEigeneImmobilienDarlehen = () => {
		let result = 0;
		eigentum?.forEach((wohnsitz) => {
			if (wohnsitz.eigene_immobilie.darlehensstand > 0) result += wohnsitz.eigene_immobilie.darlehensstand;
		});
		return result;
	};

	//Beginn der Berechnungen für NettoGesamteinkommen
	const getNettoGesamteinkommen = (model = modelPerson) => {
		return getNettoErwerbseinkommen(model) + getNettovermoegenseinkommen(model) + getNettoRenteneinkommen(model) + getNettoSontigesEinkommen(model);
	};

	const getNettoGesamteinkommenHaushalt = () => {
		let result = 0;
		result += getNettoGesamteinkommen(gesamtModel.kunde);
		//wenn > 1 Haushaltsverantwortliche, dann auch das Einkommen des Partners berücksichtigen
		if (getAnzahlHaushaltsverantwortliche() === 2) result += getNettoGesamteinkommen(gesamtModel.partner);

		kinder.forEach((kind, index) => {
			result += getNettoGesamteinkommen(kind);
		});

		return result;
	};

	/*
Das Nettoerwerbseinkommen umfasst die aktuellen Bezüge aus selbstständiger und/oder nichtselbst-
ständiger Arbeit sowie Lohnersatzleistungen (Arbeitslosengeld I, Elterngeld) als Monats- oder Jahreswert,
die im Durchschnitt eines Jahres absehbar dauerhaft erzielt werden können. Hilfsweise können auch die
voraussichtlichen künftigen Bezüge für die nächsten 12 Monate angesetzt werden. Zu diesem Wert müssen
auch Nettovermögenseinkommen aus eigenem Betrieb (Gewinnausschüttungen aus Unternehmensbeteiligungen
bspw. als Gesellschafter-Geschäftsführer einer GmbH) berücksichtigt werden, wenn die Erzielung
dieses Nettovermögenseinkommen ebenso wie die Erzielung des Nettoerwerbseinkommens (u. a. das
Gehalt als Geschäftsführer) maßgeblich von der Erwerbstätigkeit der Person abhängig ist, also bspw. bei
Eintritt einer Berufsunfähigkeit ebenfalls mit wegfallen würde. Sollte die Erzielung des Nettovermögenseinkommens
nicht von der Erwerbstätigkeit des Unternehmers abhängen, müssen diese Einkommen dem
Nettovermögenseinkommen zugeordnet werden.
Das Nettoerwerbseinkommen ist bei gesetzlich krankenversicherten Arbeitnehmern der Bruttolohn abzüglich
Lohnsteuer und Sozialversicherung. Bei privat krankenversicherten Arbeitnehmern sind, um eine Gleichstellung
mit pflichtversicherten Angestellten sicherzustellen, ferner noch die Beiträge zur substitutiven privaten
Krankenversicherung und Pflegepflichtversicherung abzuziehen. Bei Selbstständigen/Freiberuflern ergibt
sich das Nettoerwerbseinkommen aus den Privatentnahmen abzüglich Steuern und Beiträgen zur gesetzlichen
oder privaten Krankenversicherung, sowie der gesetzlichen Pflege- und evtl. Rentenversicherungen. Weiterhin
müssen Pflichtbeiträge zu berufsständischen Versorgungswerken berücksichtigt werden.
	*/

	const getNettoErwerbseinkommen = (model) => {
		let result = 0;

		if (isNotOneOf("taetigkeitsstatus", ["rentner"], model)) {
			const monatseinkommen = getValue("monatseinkommen", undefined, model);
			if (monatseinkommen !== undefined) if (monatseinkommen.nettogehalt) result += monatseinkommen.nettogehalt;
		}
		return result;
	};

	/*
Das Nettovermögenseinkommen umfasst das Liquiditätsergebnis aus Vermietung und Verpachtung sowie
Erträge und/oder Entnahmen aus Barvermögen, Kapitalanlagen und/oder sonstigen Vermögenswerten als
Monats- oder Jahreswert, die im Durchschnitt eines Jahres absehbar dauerhaft erzielt werden können.
Das Liquiditätsergebnis aus Vermietung und Verpachtung wird aus den Mieteinnahmen abzüglich der Steuern
und der Bewirtschaftungskosten sowie der Zins- und Tilgungsleistungen ermittelt. Für die Steuern und die
Bewirtschaftungskosten kann ersatzweise ein pauschaler Abschlag in Höhe von 25 % angesetzt werden.
Erträge und/oder Entnahmen aus Barvermögen, Kapitalanlagen und/oder sonstigen Vermögenswerten
werden aus den Zuflüssen abzüglich der Steuern ermittelt. Für die Steuern kann ersatzweise ein pauschaler
Abschlag in Höhe von 25 % der Zuflüsse, soweit diese den Sparerfreibetrag übersteigen, angesetzt werden.
*/

	const getNettovermoegenseinkommen = (model) => {
		let result = 0;
		const monatseinkommen = getValue("monatseinkommen", undefined, model);
		if (monatseinkommen !== undefined) if (monatseinkommen.nettovermoegenseinkommen) result += monatseinkommen.nettovermoegenseinkommen;
		return result;
	};

	/*
Das Nettorenteneinkommen umfasst die aktuellen Zahlungen aus der Altersvorsorge, Witwen-/Waisen-
renten und Berufs-/Dienstunfähigkeits-/Erwerbsminderungsrenten sowie weitere dauerhaft zur Verfügung
stehende Renten z. B. Pflege-, Unfall- und Haftpflichtrenten jeweils als Monats- oder Jahreswert nach Steuern
und Sozialabgaben, die im Durchschnitt eines Jahres absehbar dauerhaft erzielt werden können.
Zahlungen aus der Altersvorsorge umfassen gesetzliche (Deutsche Rentenversicherung, Versorgungswerke
usw.), private und betriebliche Renten sowie Beamtenpensionen.
*/
	const getNettoRenteneinkommen = (model) => {
		let result = 0;
		if (isOneOf("taetigkeitsstatus", ["rentner"], model)) {
			const monatseinkommen = getValue("monatseinkommen", undefined, model);
			if (monatseinkommen !== undefined) if (monatseinkommen.nettogehalt) result += monatseinkommen.nettogehalt;
		}

		return result;
	};

	/*
	Das sonstige Nettoeinkommen umfasst Zahlungen aus Ehegatten- und Kinderunterhalt, Kindergeld, Arbeitslosengeld
II, Wohngeld, Sozialhilfe, BAföG sowie regelmäßige private Zuwendungen jeweils nach Steuern.
	*/
	const getNettoSontigesEinkommen = (model) => {
		let result = 0;
		const monatseinkommen = getValue("monatseinkommen", undefined, model);
		if (monatseinkommen !== undefined) if (monatseinkommen.sonstige_einnahmen) result += monatseinkommen.sonstige_einnahmen;
		return result;
	};

	const getNettoErwerbseinkommen80 = () => {
		let result = getMax(MINDESTBEDARF, getNettoErwerbseinkommen() * 0.8);
		if (isOneOf("versicherung_kranken_pflege", ["pkv", "heilfuersorge"])) {
			result += getValue("versicherung_kranken_pflege_beitrag", 0);
			result += getValue("versicherung_kranken_pflege_ppv", 0);
		}
		if (getValue("taetigkeitsstatus", "") === "selbstaendig_freiberufler") {
			if (isOneOf("versicherung_kranken_pflege", ["gkv"])) {
				result += getValue("versicherung_kranken_pflege_beitrag", 0);
				result += getValue("versicherung_kranken_pflege_ppv", 0);
			}
			result += getValue("beitrag_drv_versorgungswerk", 0);
			result += getValue("altersvorsorge", []).reduce((acc, item) => acc + item.wert, 0);
		}
		return result;
	};

	const getSollwert345 = () => {
		let sollwert = MINDESTBEDARF;

		switch (getValue("versicherung_kranken_pflege", "")) {
			case "gkv":
				break;
			case "pkv":
				sollwert += getValue("versicherung_kranken_pflege_beitrag", 0) + getValue("versicherung_kranken_pflege_ppv", 0);
				break;
			case "heilfuersorge":
				sollwert += MINDESTBEITRAG_GKV_PPV_OHNE_KIND; //TODO: Anzahl Kinder berücksichtigen
				break;
			default:
		}
		if (getValue("taetigkeitsstatus", "") === "selbstaendig_freiberufler") {
			if (getValue("versicherung_kranken_pflege", "") === "gkv") sollwert += getValue("versicherung_kranken_pflege_beitrag", 0) + getValue("versicherung_kranken_pflege_ppv", 0);

			sollwert += getGRVBeitrag();
		}
		return sollwert;
	};

	const setRisiko = (risikoNr, einheit, isRelevant, valueRelevant, valueNotRelevant, risiko_uuid = "", risiko_label = "") => {
		updateAtribut(risikoNr, [
			{ key: "relevant", value: isRelevant },
			{ key: "sollwert", value: valueRelevant },
			{ key: "einheit", value: einheit },
			{ key: "valueNotRelevant", value: valueNotRelevant },
			{ key: "risiko_uuid", value: risiko_uuid },
			{ key: "risiko_label", value: risiko_label },
		]);
	};

	const getMax = (value1, value2) => {
		if (value1 > value2) return value1;
		return value2;
	};

	const getMin = (value1, value2) => {
		if (value1 < value2) return value1;
		return value2;
	};

	//7. todesfallbedingte finanzielle Einbußen (Grundschutz)
	/*
  Betroffener Personenkreis
  Haushaltsverantwortliche, die in Partnerschaft/Ehe/eingetragener Lebenspartnerschaft leben und/oder wirtschaftlich abhängige Kinder haben

  Mindestsollwert
  Der Mindestsollwert in Bedarfsstufe 1 entspricht:
	5 × Jahres-Mindestbedarfsgröße
	zzgl. 3 × Jahres-Mindestbedarfsgröße je wirtschaftlich abhängigem Kind
	zzgl. aktueller Darlehensstand Konsumentendarlehen des Privathaushaltes
	zzgl. aktueller Darlehensstand für die selbstgenutzte Immobilie des Privathaushaltes.
  */

	const getValueRisiko7 = () => {
		return MINDESTBEDARF * 12 * 5 + MINDESTBEDARF * 12 * 3 * getAnzKinder_abhaengig() + getStandKonsumentendarlehen() + getStandEigeneImmobilienDarlehen();
	};

	const getValueRisiko18 = () => {
		return 3 * MINDESTBEDARF * getAnzahlHaushaltsverantwortliche();
	};

	//1563,66 Mindestbedard, 2,3% Inflation, 165 Monate

	//Berechnung kontrollieren
	const getValueRisiko20 = () => {
		let value = getHochrechnung(MINDESTBEDARF, -getAlterMonate(getErsterTagImMonat(getValue("ruhestandsbeginn"))));

		if (modelHaushalt?.wohnsitz?.filter((wohnsitz) => wohnsitz.auswahl === "eigentum").length > 0) {
			updateAtribut(20, [{ key: "autoPercent", value: 20 }]);
		}
		return value;
	};

	const getValueRisiko26 = () => {
		const valueRisiko26 =
			5 * 12 * 0.8 * (getNettoErwerbseinkommen() + getNettoRenteneinkommen()) +
			3 * 12 * 0.8 * getAnzKinder_abhaengig() * (getNettoErwerbseinkommen() + getNettoRenteneinkommen()) +
			getStandKonsumentendarlehen() +
			getStandEigeneImmobilienDarlehen();
		return getMax(getValueRisiko7(), valueRisiko26);
	};

	const getValueRisiko27 = () => {
		const valueRisiko27 = 6 * getNettoGesamteinkommenHaushalt();
		return getMax(getValueRisiko18(), valueRisiko27);
	};

	const getValueRisiko29 = () => {
		const valueRisiko29 = getHochrechnung(getNettoErwerbseinkommen() * 0.8 * 1.25, -getAlterMonate(getErsterTagImMonat(getValue("ruhestandsbeginn"))));
		if (modelHaushalt?.wohnsitz?.filter((wohnsitz) => wohnsitz.auswahl === "eigentum").length > 0) {
			updateAtribut(29, [{ key: "autoPercent", value: 20 }]);
		}
		return getMax(getValueRisiko20(), valueRisiko29);
	};

	const calcInstandhaltung = (baujahr, verkehrswert) => {
		const aktuellesJahr = new Date().getFullYear();
		const alter = aktuellesJahr - new Date(baujahr).getFullYear();

		if (alter < 10) return verkehrswert * 0.05;
		if (alter < 20) return verkehrswert * 0.075;
		return verkehrswert * 0.1;
	};

	const getValueRisiko33 = () => {
		let result = 0;

		eigentum.forEach((wohnsitz) => {
			result += calcInstandhaltung(wohnsitz.eigene_immobilie.baujahr, wohnsitz.eigene_immobilie.neubausumme);
		});

		getValueHaushalt("immobilien", []).forEach((immobilie) => {
			result += calcInstandhaltung(immobilie.baujahr, immobilie.neubausumme);
		});
		return result;
	};

	const getValueRisiko35 = () => {
		const valueRisiko35 = getNettoGesamteinkommenHaushalt() * 6;
		return getMax(20000, valueRisiko35);
	};

	const getValueRisiko36 = () => {
		let result = "Privat und Verkehr";
		if (isOneOf("taetigkeitsstatus", ["angestellt", "angestellt_oeffentlicher_dienst", "beamtet"]) && isHaushaltsverantwortlich) result += ", Beruf/Arbeit";
		if (isHaushaltsverantwortlich) result += ", Wohnung/Grundstück";
		return (result += "; 300.000 € Versicherungssumme");
	};

	const getValueRisiko37 = () => {
		return getAnzKinderUnter18() * getMin(10 * 6 * BAFOEG_HOECHSTSATZ, 5 * getNettoGesamteinkommenHaushalt());
	};

	const getValueRisiko38 = () => {
		let result = 0;
		getValueHaushalt("ersatzinvestitionen", []).forEach((ersatzinvestition) => {
			if (ersatzinvestition.zielzeitpunkt === null || ersatzinvestition.zielzeitpunkt === undefined || new Date(ersatzinvestition.zielzeitpunkt) > new Date()) result += ersatzinvestition.wunschbetrag;
		});
		return result;
	};

	const getValueRisiko41 = () => {
		return getSum(kaufobjekte, "wert") * 0.3;
	};

	const getValueRisiko42 = () => {
		//return getSum(investitionsziele, "wert");
		let result = 0;
		getValueHaushalt("investitionsziele", []).forEach((investitionsziel) => {
			if (investitionsziel.zielzeitpunkt === null || investitionsziel.zeitpunkt === undefined || new Date(investitionsziel.zeitpunkt) > new Date()) result += investitionsziel.wert;
		});
		return result;
	};

	const isErwerbstaetigHausfrau = isOneOf("taetigkeitsstatus", ["angestellt", "selbstaendig_freiberufler", "beamtet", "hausfrau_mann", "angestellt_oeffentlicher_dienst"]);
	const isErwerbstaetig = isOneOf("taetigkeitsstatus", ["angestellt", "selbstaendig_freiberufler", "beamtet", "angestellt_oeffentlicher_dienst"]);
	const isHaushaltsverantwortlich = isKundePartner;
	const isErwachsen = getAlter(modelPerson.geburtsdatum) >= 18;
	const isHaushaltsverantwortlichErwerbstaetigAngestelltFreiberufler = isHaushaltsverantwortlich && isErwerbstaetig && isOneOf("taetigkeitsstatus", ["angestellt", "angestellt_oeffentlicher_dienst", "selbstaendig_freiberufler"]);

	//1. Kostenrisiko Krankheit Grundschutz
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushalts, die eine Auslandsreise nicht ausschließen

  Mindestsollwert
  Der Mindestsollwert ist das Vorliegen einer Absicherung im Krankheitsfall im Ausland.
  */
	setRisiko(1, "string", true, "Krankenversicherung", "");

	//2. Allgemeines Haftungsrisiko
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushalts

  Mindestsollwert
  Der Mindestsollwert in Bedarfsstufe 1 entspricht einer Versicherungssumme von 10 Mio. € pauschal für Personen-, Sach- oder Vermögensschäden.
  */
	setRisiko(2, "euro_gerundet", true, 10000000, 0);

	//3. Arbeitskraftverlust Erwerbsunfähigkeit (Grundschutz)
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	setRisiko(3, "euro_gerundet", isErwerbstaetigHausfrau, getSollwert345(), 0);

	//4. Arbeitskraftverlust Berufs-/Dienstunfähigkeit (Grundschutz)
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXXx
  */
	setRisiko(4, "euro_gerundet", isErwerbstaetigHausfrau, getSollwert345(), 0);

	//5. Arbeitskraftverlust Arbeitsunfähigkeit (Grundschutz)
	/*
  Betroffener Personenkreis
  Alle erwerbstätigen Haushaltsverantwortlichen, ausgenommen Beamte auf Lebenszeit/Berufssoldaten/Richter

  Mindestsollwert
  Der Mindestsollwert in Bedarfsstufe 1 entspricht der Mindestbedarfsgröße.
	Der Mindestsollwert erhöht sich
	— bei privat krankenversicherten Arbeitnehmern um den tatsächlich gezahlten Beitrag zur privaten Krankenversicherung und Pflegepflichtversicherung.
	— bei privat krankenversicherten Selbstständigen/Freiberuflern um den tatsächlich	gezahlten Beitrag zur privaten Krankenversicherung und Pflegepflichtversicherung und dem halben Regelbeitrag zur gesetzlichen Rentenversicherung nach den Rahmenbedingungen in 5.2, Tabelle 2.
	— bei Selbstständigen/Freiberuflern, die Mitglied der gesetzlichen 	Krankenversicherung sind, um den Mindestbeitrag der gesetzlichen Kranken-	/Pflegepflichtversicherung unter Berücksichtigung des individuellen	Beitragssatzes zur Pflegepflichtversicherung und dem halben Regelbeitrag zur gesetzlichen Rentenversicherung nach den Rahmenbedingungen in 5.2, Tabelle 2.
  */
	setRisiko(5, "euro_gerundet", isHaushaltsverantwortlichErwerbstaetigAngestelltFreiberufler, getSollwert345(), 0);

	//6. Kostenrisiko Pflegebedürftigkeit (Grundschutz)
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	setRisiko(6, "string", true, "Pflegepflichtversicherung", "");

	//7. todesfallbedingte finanzielle Einbußen (Grundschutz)
	/*
  Betroffener Personenkreis
  Haushaltsverantwortliche, die in Partnerschaft/Ehe/eingetragener Lebenspartnerschaft leben und/oder wirtschaftlich abhängige Kinder haben

  Mindestsollwert
  Der Mindestsollwert in Bedarfsstufe 1 entspricht:
	5 × Jahres-Mindestbedarfsgröße
	zzgl. 3 × Jahres-Mindestbedarfsgröße je wirtschaftlich abhängigem Kind
	zzgl. aktueller Darlehensstand Konsumentendarlehen des Privathaushaltes
	zzgl. aktueller Darlehensstand für die selbstgenutzte Immobilie des Privathaushaltes.
  */

	setRisiko(7, "euro_gerundet", isHaushaltsverantwortlich && (getAnzahlHaushaltsverantwortliche() > 1 || getAnzKinder_abhaengig() > 0), getValueRisiko7(), 0);

	//8. Haftungsrisiko Kraftfahrzeuge
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushalts, die Halter eines Kraftfahrzeuges sind.

  Mindestsollwert
  Der Mindestsollwert in Bedarfsstufe 1 entspricht je Kraftfahrzeug einerVersicherungssumme von 10 Mio. € pauschal für Personen-, Sach- oder Vermögensschäden.
  */
	const anzahlKfz = modelHaushalt?.fahrzeuge?.autos.length || 0;

	if (anzahlKfz > 0 && isHaushaltsverantwortlich) {
		modelHaushalt.fahrzeuge.autos.forEach((auto, index) => {
			let label = "Auto " + (index + 1);
			if (auto.bezeichnung !== "") label = auto.bezeichnung;
			setRisiko(8, "euro_gerundet", true, 10000000, 0, auto.uuid, label);
		});
	} else setRisiko(8, "euro_gerundet", false, 10000000, 0);

	//9. Haftungsrisiko private Tierhaltung
	/*
  Betroffener Personenkreisxx
  Alle Personen des Privathaushalts, die Halter von Hunden und/oder Pferden sind.

  Mindestsollwert
  Der Mindestsollwert in Bedarfsstufe 1 entspricht einer Versicherungssumme von 10 Mio. € pauschal für Personen-, Sach- oder Vermögensschäden.
  */
	const anzahlHaustiere = modelHaushalt?.haustiere?.length || 0;
	if (isHaushaltsverantwortlich) {
		if (anzahlHaustiere === 0) {
			setRisiko(9, "euro_gerundet", false, 10000000, 0);
		} else {
			if (modelHaushalt?.haustiere?.includes("hund")) {
				setRisiko(9, "euro_gerundet", true, 10000000, 0, "uuid_hund", "Hund");
			}
			if (modelHaushalt?.haustiere?.includes("pferd")) {
				setRisiko(9, "euro_gerundet", true, 10000000, 0, "uuid_pferd", "Pferd");
			}
		}
	}

	//10. Haftungsrisiko Haus-und Grundbesitz
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushalts, die Eigentümer von eigen- und/oder fremdgenutzten Immobilien (auch Sondereigentum) sowie unbebauten Grundstücken sind.

  Mindestsollwert
  Der Mindestsollwert in Bedarfsstufe 1 entspricht je Immobilie/unbebautem Grundstück einer Versicherungssumme von 10 Mio. € pauschal für Personen-, Sach- oder Vermögensschäden.
  */
	if (isHaushaltsverantwortlich && getAnzImmobilien() > 0) {
		eigentum.forEach((wohnsitz, index) => {
			let label = "Wohnsitz " + (index + 1);
			if (wohnsitz.strasse !== "") label = wohnsitz.strasse + " " + wohnsitz.hausnummer;
			setRisiko(10, "euro_gerundet", true, 10000000, 0, wohnsitz.uuid, label);
		});

		const immobilien = getValueHaushalt("immobilien", []);
		immobilien.forEach((immobilie, index) => {
			let label = "Investment " + (index + 1);
			if (immobilie.strasse !== "") label = immobilie.strasse + " " + immobilie.hausnummer;
			setRisiko(10, "euro_gerundet", true, 10000000, 0, immobilie.uuid, label);
		});
	} else setRisiko(10, "euro_gerundet", false, 10000000, 0);

	//11. Haftungsrisiko Bau und Sanierung
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushalts, die Bauherren (Neubau, Sanierung, Umbau, usw.) sind oder planen innerhalb der nächsten 12 Monate Bauherren zu werden.

  Mindestsollwert
  Der Mindestsollwert in Bedarfsstufe 1 entspricht je Bauvorhaben einer Versicherungssumme von 10 Mio. € pauschal für Personen-, Sach- oder Vermögensschäden.
  */
	if (isHaushaltsverantwortlich && getAnzBauSanierung() > 0) {
		eigentum.forEach((wohnsitz, index) => {
			if (wohnsitz.eigene_immobilie.sanierung_geplant) {
				let label = "Wohnsitz " + (index + 1);
				if (wohnsitz.strasse !== "") label = wohnsitz.strasse + " " + wohnsitz.hausnummer;
				setRisiko(11, "euro_gerundet", true, 10000000, 0, wohnsitz.uuid, label);
			}
		});

		const immobilien = getValueHaushalt("immobilien", []);
		immobilien.forEach((immobilie, index) => {
			if (immobilie.sanierung_geplant) {
				let label = "Investment " + (index + 1);
				if (immobilie.strasse !== "") label = immobilie.strasse + " " + immobilie.hausnummer;
				setRisiko(11, "euro_gerundet", true, 10000000, 0, immobilie.uuid, label);
			}
		});

		const kaufobjekte = getValueHaushalt("kaufobjekte", []);
		kaufobjekte.forEach((kaufobjekt, index) => {
			if (isWithinNext12Months(new Date(kaufobjekt.erwerb_zum))) {
				let label = "Kaufobjekt " + (index + 1);
				if (kaufobjekt.strasse !== "") label = kaufobjekt.strasse + " " + kaufobjekt.hausnummer;
				setRisiko(11, "euro_gerundet", true, 10000000, 0, kaufobjekt.uuid, label);
			}
		});
	} else setRisiko(11, "euro_gerundet", false, 10000000, 0);

	//12. Haftungsrisiko Gewässerschäden (Heizöltank o. ä.)
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	if (isHaushaltsverantwortlich && getAnzHeizoel() > 0) {
		eigentum.forEach((wohnsitz, index) => {
			if (wohnsitz.eigene_immobilie.oeltank) {
				let label = "Wohnsitz " + (index + 1);
				if (wohnsitz.strasse !== "") label = wohnsitz.strasse + " " + wohnsitz.hausnummer;
				setRisiko(12, "euro_gerundet", true, 10000000, 0, wohnsitz.uuid, label);
			}
		});

		const immobilien = getValueHaushalt("immobilien", []);
		immobilien.forEach((immobilie, index) => {
			if (immobilie.oeltank) {
				let label = "Investment " + (index + 1);
				if (immobilie.strasse !== "") label = immobilie.strasse + " " + immobilie.hausnummer;
				setRisiko(12, "euro_gerundet", true, 10000000, 0, immobilie.uuid, label);
			}
		});

		const grundstuecke = getValueHaushalt("grundstuecke", []);
		grundstuecke.forEach((grundstueck, index) => {
			if (grundstueck.oeltank) {
				let label = "Investment " + (index + 1);
				if (grundstueck.strasse !== "") label = grundstueck.strasse + " " + grundstueck.hausnummer;
				setRisiko(12, "euro_gerundet", true, 10000000, 0, grundstueck.uuid, label);
			}
		});
	} else setRisiko(12, "euro_gerundet", false, 10000000, 0);

	//13. Haftungsrisiko Photovoltaik-Anlage
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	if (isHaushaltsverantwortlich && getAnzPhotovoltaik() > 0) {
		eigentum.forEach((wohnsitz, index) => {
			if (wohnsitz.eigene_immobilie.photovoltaik) {
				let label = "Wohnsitz " + (index + 1);
				if (wohnsitz.strasse !== "") label = wohnsitz.strasse + " " + wohnsitz.hausnummer;
				setRisiko(13, "euro_gerundet", true, 10000000, 0, wohnsitz.uuid, label);
			}
		});

		const immobilien = getValueHaushalt("immobilien", []);
		immobilien.forEach((immobilie, index) => {
			if (immobilie.photovoltaik) {
				let label = "Investment " + (index + 1);
				if (immobilie.strasse !== "") label = immobilie.strasse + " " + immobilie.hausnummer;
				setRisiko(13, "euro_gerundet", true, 10000000, 0, immobilie.uuid, label);
			}
		});
	} else setRisiko(13, "euro_gerundet", false, 10000000, 0);

	//14. Haftungsrisiko Jagd
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	setRisiko(14, "euro_gerundet", isOneOfHaushalt("hobbies_und_aktivitaeten", ["jagdsport"]), 10000000, 0);

	//15. Haftungsrisiko Luftfahrzeuge (inkl. ferngesteuerte Flugmodelle und Drohnen)
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	const anzahlLuftfahrt = modelHaushalt?.fahrzeuge?.flugzeuge?.length || 0;
	if (isHaushaltsverantwortlich && anzahlLuftfahrt > 0) {
		modelHaushalt.fahrzeuge.flugzeuge.forEach((flugzeug, index) => {
			let label = "Luftfahrzeug " + (index + 1);
			if (flugzeug.bezeichnung !== "") label = flugzeug.bezeichnung;
			setRisiko(15, "euro_gerundet", true, 10000000, 0, flugzeug.uuid, label);
		});
	} else setRisiko(15, "euro_gerundet", false, 10000000, 0);

	//16. Haftungsrisiko aus besonderer ausgeübter Tätigkeit und Ehrenamt
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushalts, die
	— Angestellte mit besonderen Aufgaben/Betriebsbeauftragte wie z. B.	Datenschutzbeauftragte, Brandschutzbeauftragte,
	— Organe von juristischen Personen, Körperschaften, Vereinen wie z. B. Vorstände,	GmbH-Geschäftsführer, Aufsichtsräte, Beiräte,
	— Angestellte im öffentlichen Dienst/Beamte,
	— Syndikusanwälte/angestellte Ärzte, oder
	— ehrenamtlich tätig
	sind.

  Mindestsollwert
  Der Mindestsollwert in Bedarfsstufe 1 entspricht einer Versicherungssumme von 10 Mio. € je Funktion.
  */
	//ToDo: Besondere Tätigkeit mit ausgeben und einzeln ausweisen 2024-10-04
	const besondereTaetigkeiten = getBesondereTaetigkeiten();
	if (besondereTaetigkeiten.length > 0) {
		besondereTaetigkeiten.forEach((taetigkeit, index) => {
			setRisiko(16, "euro_gerundet", true, 10000000, 0, taetigkeit, getLabel(taetigkeit, besondereTaetigkeitOptions.concat(taetigkeitsstatusOptions)));
		});
	} else setRisiko(16, "euro_gerundet", false, 10000000, 0);

	//17. Haftungsrisiko Wasserfahrzeuge
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	const anzahlWasserfahrzeuge = modelHaushalt?.fahrzeuge?.boote?.length || 0;

	if (isHaushaltsverantwortlich && anzahlWasserfahrzeuge > 0) {
		modelHaushalt.fahrzeuge.boote.forEach((boot, index) => {
			let label = "Wasserfahrzeug " + (index + 1);
			if (boot.bezeichnung !== "") label = boot.bezeichnung;
			setRisiko(17, "euro_gerundet", true, 10000000, 0, boot.uuid, label);
		});
	} else setRisiko(17, "euro_gerundet", false, 10000000, 0);

	//18. Verhinderung der kurzfristig drohenden Zahlungsunfähigkeit
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushaltes

  Mindestsollwert
  Der Mindestsollwert in Bedarfsstufe 1 entspricht der 3-fachen Mindestbedarfsgröße multipliziert mit der Anzahl der haushaltsverantwortlichen Personen.
  */
	setRisiko(18, "euro_gerundet", isHaushaltsverantwortlich, getValueRisiko18(), 0);

	//19. Schuldenrisiko aus Dispositions- und Konsumentenkrediten
	/*
  Betroffener Personenkreis
 Alle Personen des Privathaushaltes

  Schwellenwert
 	Der Schwellenwert in Bedarfsstufe 1 entspricht dem 10-fachen monatlichen Nettogesamteinkommen des Privathaushaltes.

	Guard: Erwachsen und (Konsumentenkredite oder Überziehungskredite)
  */

	setRisiko(19, "euro_gerundet", isHaushaltsverantwortlich && getStandKonsumentendarlehen() + getStandUeberziehungskredite() > 10 * getNettoGesamteinkommenHaushalt(), getStandKonsumentendarlehen() + getStandUeberziehungskredite(), 0);

	//20. Altersvorsorge (Grundschutz)
	/*
  Betroffener Personenkreis
  Haushaltsverantwortliche vor Eintritt in den Ruhestand

  Mindestsollwert
  Der Mindestsollwert in Bedarfsstufe 1 entspricht der Mindestbedarfsgröße.
	Der Mindestsollwert ist je haushaltsverantwortlicher Person auf den Zeitpunkt seines Renteneintritts mit der definierten Inflationsrate nach 
	den Rahmenparametern in 5.2, Tabelle 2, hochzurechnen.
  */
	setRisiko(20, "euro_gerundet", isHaushaltsverantwortlich && isOneOf("taetigkeitsstatus", ["angestellt", "angestellt_oeffentlicher_dienst", "selbstaendig_freiberufler", "beamtet", "hausfrau_mann", "studierend"]), getValueRisiko20(), 0);

	//21. Risiko des Verlustes/der Beschädigung einer Immobilie
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	if (isHaushaltsverantwortlich && getAnzImmobilien() > 0) {
		eigentum.forEach((wohnsitz, index) => {
			let label = "Wohnsitz " + (index + 1);
			if (wohnsitz.strasse !== "") label = wohnsitz.strasse + " " + wohnsitz.hausnummer;
			setRisiko(21, "string", true, "Gebäudeversicherung nach gleitendem Neuwert mit Unverversicherungsverzicht", 0, wohnsitz.uuid, label);
		});

		const immobilien = getValueHaushalt("immobilien", []);
		immobilien.forEach((immobilie, index) => {
			let label = "Investment " + (index + 1);
			if (immobilie.strasse !== "") label = immobilie.strasse + " " + immobilie.hausnummer;
			setRisiko(21, "string", true, "Gebäudeversicherung nach gleitendem Neuwert mit Unverversicherungsverzicht", 0, immobilie.uuid, label);
		});
	} else setRisiko(21, "string", false, "Gebäudeversicherung nach gleitendem Neuwert mit Unverversicherungsverzicht", 0);

	//22. Kostenrisiko Krankheit Ausland ==> Frage nach Auslandsreise fehlt
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushalts, die eine Auslandsreise nicht ausschließen

  Mindestsollwert
  Der Mindestsollwert ist das Vorliegen einer Absicherung im Krankheitsfall im Ausland.
  */
	setRisiko(22, "string", true, "Krankenversicherung mit Einschluss Leistungen im Ausland", "");

	//23. Arbeitskraftverlust Erwerbsunfähigkeit
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	setRisiko(23, "euro_gerundet", isErwerbstaetigHausfrau, getNettoErwerbseinkommen80(), 0);

	//24. Arbeitskraftverlust Berufs-/Dienstunfähigkeit
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	setRisiko(24, "euro_gerundet", isErwerbstaetigHausfrau, getNettoErwerbseinkommen80(), 0);

	//25. Arbeitskraftverlust Arbeitsunfähigkeit
	/*
  Betroffener Personenkreis
  Alle erwerbstätigen Haushaltsverantwortlichen, ausgenommen Beamte auf Lebenszeit/Berufssoldaten/Richter

  Mindestsollwert
  Der Sollwert auf Bedarfsstufe 2 entspricht 80 % des Nettoerwerbseinkommens, mindestens jedoch dem Wert in Höhe der Mindestbedarfsgröße.
	Der Sollwert erhöht sich
	— bei privat krankenversicherten Arbeitnehmern um den tatsächlich gezahlten Beitrag zur privaten Krankenversicherung und Pflegepflichtversicherung.
	— bei Selbstständigen/Freiberuflern um den tatsächlich gezahlten Beitrag zur gesetzlichen oder privaten Krankenversicherung und Pflegepflichtversicherung	und die tatsächlich gezahlten Beiträge zur gesetzlichen Rentenversicherung und/oder zu berufsständischen Versorgungswerken.
  */
	setRisiko(25, "euro_gerundet", isHaushaltsverantwortlichErwerbstaetigAngestelltFreiberufler, getNettoErwerbseinkommen80(), 0);

	//26. todesfallbedingte finanzielle Einbußen (Grundschutz)
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	setRisiko(26, "euro_gerundet", isHaushaltsverantwortlich, getValueRisiko26(), 0);

	//27. Aufbau Liquiditätsreserve
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushaltes

  Mindestsollwert
  Der Sollwert in Bedarfsstufe 2 entspricht dem 6-fachen des monatlichen
	Nettogesamteinkommens des Haushaltes (mindestens jedoch der Wert von
	Bedarfsstufe 1)
  */
	setRisiko(27, "euro_gerundet", isHaushaltsverantwortlich, getValueRisiko27(), 0);

	//28. Invalidität/ Erwerbsunfähigkeit/ Berufsunfähigkeit von Kindern
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	setRisiko(28, "euro_gerundet", isWirtschaftlichAbhaengigesKind(modelPerson), MINDESTBEDARF, 0);

	//29. Altersvorsorge
	/*
  Betroffener Personenkreis
  Haushaltsverantwortliche vor Eintritt in den Ruhestand

  Mindestsollwert
  Der Mindestsollwert in Bedarfsstufe 1 entspricht der Mindestbedarfsgröße.
	Der Mindestsollwert ist je haushaltsverantwortlicher Person auf den Zeitpunkt seines Renteneintritts mit der definierten Inflationsrate nach den Rahmenparametern in 5.2, Tabelle 2, hochzurechnen.
  */
	setRisiko(29, "euro_gerundet", isHaushaltsverantwortlich && isOneOf("taetigkeitsstatus", ["angestellt", "angestellt_oeffentlicher_dienst", "selbstaendig_freiberufler", "beamtet", "hausfrau_mann", "studierend"]), getValueRisiko29(), 0);

	//30. Zinsänderungsrisiko bei Immobilienfinanzierungen
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	setRisiko(30, "euro_gerundet", isHaushaltsverantwortlich && hasImmobilienfinanzierung(), 24 * getNettoGesamteinkommen(), 0);

	//31. Kostenrisiko Pflegebedürftigkeit
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	setRisiko(31, "euro_gerundet", true, DURSCHNITTLICHER_EIGENANTEIL_PFLEGE, 0);

	//32. Risiko des Verlustes/der Beschädigung von Hausrat
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushalts, die Hausrat in einer Wohnung besitzen.

  Mindestsollwert
  Der Sollwert in Bedarfsstufe 2 entspricht dem individuell ermittelten Wert des Hausrats oder hilfsweise dem pauschalierten Wert je Quadratmeter Wohnfläche (2018 = 650 €, siehe Rahmenparameter 5.2).
  */

	if (isHaushaltsverantwortlich && (eigentum.length > 0 || miete.length > 0)) {
		eigentum.forEach((wohnsitz, index) => {
			let label = "Wohnsitz " + (index + 1);
			if (wohnsitz.strasse !== "") label = wohnsitz.strasse + " " + wohnsitz.hausnummer;
			setRisiko(32, "euro_gerundet", true, wohnsitz.eigene_immobilie.hausrat_wert, 0, wohnsitz.uuid, label);
		});

		miete.forEach((wohnsitz, index) => {
			let label = "Wohnsitz " + (index + 1);
			if (wohnsitz.strasse !== "") label = wohnsitz.strasse + " " + wohnsitz.hausnummer;
			setRisiko(32, "euro_gerundet", true, wohnsitz.mietobjekt.hausrat_wert, 0, wohnsitz.uuid, label);
		});
	} else setRisiko(32, "euro_gerundet", false, 0, 0);

	//33. Instandhaltung Immobilien ==> ACHTUNG: Datum der letzten Sanierung fehlt --> Umbennennung Baujahr --> Baujahr/letzte Sanierung
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	if (isHaushaltsverantwortlich && getAnzImmobilien() > 0) {
		eigentum.forEach((wohnsitz, index) => {
			let label = "Wohnsitz " + (index + 1);
			if (wohnsitz.strasse !== "") label = wohnsitz.strasse + " " + wohnsitz.hausnummer;
			setRisiko(33, "euro_gerundet", true, calcInstandhaltung(wohnsitz.eigene_immobilie.baujahr, wohnsitz.eigene_immobilie.neubausumme), 0, wohnsitz.uuid, label);
		});

		const immobilien = getValueHaushalt("immobilien", []);
		immobilien.forEach((immobilie, index) => {
			let label = "Investment " + (index + 1);
			if (immobilie.strasse !== "") label = immobilie.strasse + " " + immobilie.hausnummer;
			setRisiko(33, "euro_gerundet", true, calcInstandhaltung(immobilie.baujahr, immobilie.neubausumme), 0, immobilie.uuid, label);
		});
	} else setRisiko(33, "euro_gerundet", false, 0, 0);

	//34. Kostenrisiko Krankheit aus stationären Zusatzleistungen
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushalts

  Mindestsollwert
  Der Sollwert ist das Vorliegen einer Absicherung für das Kostenrisiko aus Zusatzleistungen im stationären Bereich.
  */
	setRisiko(34, "string", true, "PKV (Voll/Zusatz) mit Einschluss stationärer Leistungen", "");

	//35. Risiko von nicht gedeckten Folgekosten nach Unfall und Krankheit
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushalts

  Mindestsollwert
  Der Sollwert in Bedarfsstufe 2 entspricht dem 6-fachen des monatlichen
	Nettogesamteinkommens des Haushalts, mindestens jedoch 20 000 €.
  */
	setRisiko(35, "euro_gerundet", true, getValueRisiko35(), 0);

	//36. Kostenrisiko aus Rechtsdurchsetzung
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	setRisiko(36, "string", true, getValueRisiko36(), "");

	//37. Schaffung von Kapital für die Ausbildung der Kinder
	/*
  Betroffener Personenkreis
  Minderjährige Kinder des Privathaushalts

  Mindestsollwert
  Der Sollwert in Bedarfsstufe 2 entspricht je Kind einem zum Zeitpunkt des
	18. Lebensjahres des Kindes mindestens erforderlichen Kapitals in Höhe des 5-fachen
	aktuellen monatlichen Nettogesamteinkommens, maximal jedoch dem Betrag in Höhe
	des aktuellen BAföG-Höchstsatzes multipliziert mit 10 Semestern (2018 = 44 100 €,
	BAföG-Höchstsatz siehe Rahmenparameter 5.2).
  */
	setRisiko(37, "euro_gerundet", isHaushaltsverantwortlich && getAnzKinder_abhaengig() > 0, getValueRisiko37(), 0);

	//38. Wesentliche Ersatzinvestitionen Sachwerte (ohne Immobilien)
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */
	const relevanteErsatzinvestitionen = getValueHaushalt("ersatzinvestitionen", []).filter(
		(ersatzinvestition) => ersatzinvestition.zielzeitpunkt === null || ersatzinvestition.zielzeitpunkt === undefined || new Date(ersatzinvestition.zielzeitpunkt) > new Date()
	);
	if (isHaushaltsverantwortlich && relevanteErsatzinvestitionen.length > 0) {
		relevanteErsatzinvestitionen.forEach((ersatzinvestition, index) => {
			let label = "Ersatzinvestition " + (index + 1);
			if (ersatzinvestition.bezeichnung !== "") label = ersatzinvestition.bezeichnung;
			setRisiko(38, "euro_gerundet", true, ersatzinvestition.wunschbetrag, 0, ersatzinvestition.uuid, label);
		});
	} else setRisiko(38, "euro_gerundet", false, 0, 0);

	//39. Kostenrisiko Krankheit aus ambulanten, zahnmedizinischen und weiteren Zusatzleistungen
	/*
  Betroffener Personenkreis
  Alle Personen des Privathaushalts

  Mindestsollwert
	Der Sollwert ist das Vorliegen einer Absicherung für weitereKrankheitskosten (z. B. ambulanter oder zahnmedizinischer Bereich).
  */
	setRisiko(39, "string", true, "PKV (Voll/Zusatz) mit Einschluss Leistungen Ambulant und Zahn", "");

	//40. Risiko des Verlustes/der Beschädigung von Fahrzeugen
	/*
  Betroffener Personenkreis
  XXX

  Mindestsollwert
  XXX
  */

	if (anzahlKfz > 0 && isHaushaltsverantwortlich) {
		modelHaushalt.fahrzeuge.autos.forEach((auto, index) => {
			let label = "Auto " + (index + 1);
			if (auto.bezeichnung !== "") label = auto.bezeichnung;
			setRisiko(40, "string", true, "Versicherungsschutz gegen Beschädigung, Zerstörung oder Verlust", 0, auto.uuid, label);
		});
	} else setRisiko(40, "string", false, "Versicherungsschutz gegen Beschädigung, Zerstörung oder Verlust", 0);

	if (isHaushaltsverantwortlich && anzahlWasserfahrzeuge > 0) {
		modelHaushalt.fahrzeuge.boote.forEach((boot, index) => {
			let label = "Wasserfahrzeug " + (index + 1);
			if (boot.bezeichnung !== "") label = boot.bezeichnung;
			setRisiko(40, "string", true, "Versicherungsschutz gegen Beschädigung, Zerstörung oder Verlust", 0, boot.uuid, label);
		});
	} else setRisiko(40, "string", false, "Versicherungsschutz gegen Beschädigung, Zerstörung oder Verlust", 0);

	if (isHaushaltsverantwortlich && anzahlLuftfahrt > 0) {
		modelHaushalt.fahrzeuge.flugzeuge.forEach((flugzeug, index) => {
			let label = "Luftfahrzeug " + (index + 1);
			if (flugzeug.bezeichnung !== "") label = flugzeug.bezeichnung;
			setRisiko(40, "string", true, "Versicherungsschutz gegen Beschädigung, Zerstörung oder Verlust", 0, flugzeug.uuid, label);
		});
	} else setRisiko(40, "string", false, "Versicherungsschutz gegen Beschädigung, Zerstörung oder Verlust", 0);

	const anzahlSonstigeFahrzeuge = modelHaushalt?.fahrzeuge?.sonstige_fahrzeuge?.length;
	if (isHaushaltsverantwortlich && anzahlSonstigeFahrzeuge > 0) {
		modelHaushalt.fahrzeuge.sonstige_fahrzeuge.forEach((sonstiges, index) => {
			let label = "Sonstiges Fahrzeug " + (index + 1);
			if (sonstiges.bezeichnung !== "") label = sonstiges.bezeichnung;
			setRisiko(40, "string", true, "Versicherungsschutz gegen Beschädigung, Zerstörung oder Verlust", 0, sonstiges.uuid, label);
		});
	} else setRisiko(40, "string", false, "Versicherungsschutz gegen Beschädigung, Zerstörung oder Verlust", 0);

	//41. Schaffung von Eigenkapital zum Erwerb von selbstgenutztem Wohneigentum
	/*
  Betroffener Personenkreis
  Privathaushalte ohne selbstgenutztes Wohneigentum mit dem Ziel, selbstgenutztes
	Wohneigentum zu erwerben

  Mindestsollwert
  Der Zielwert in Bedarfsstufe 3 entspricht 20 % des Wertes der Zielimmobilie zzgl.
10 % des Immobilienwertes für Erwerbsnebenkosten.
  */
	setRisiko(41, "euro_gerundet", isHaushaltsverantwortlich && eigentum.length === 0 && kaufobjekte.length > 0, getValueRisiko41(), 0);

	//42. Weitere individuelle Ziele
	/*
  Betroffener Personenkreis
  Haushaltsverantwortliche, die eines oder mehrere finanzielle Ziele haben, die nicht die Finanzthemen des Dokuments betreffen bzw. deutlich über die dort festgelegten Zielgrößen hinausgehen

  Mindestsollwert
  Der Zielwert in Bedarfsstufe 3 entspricht dem jeweiligen Kapitalwert des weiteren, individuellen Ziels des Privathaushalts.
  */

	const relevanteInvestitionsziele = getValueHaushalt("investitionsziele", []).filter(
		(investitionsziel) => investitionsziel.zielzeitpunkt === null || investitionsziel.zielzeitpunkt === undefined || new Date(investitionsziel.zielzeitpunkt) > new Date()
	);
	if (isHaushaltsverantwortlich && relevanteInvestitionsziele.length > 0) {
		relevanteInvestitionsziele.forEach((investitionsziel, index) => {
			let label = getLabel(investitionsziel.bezeichnung, investitionszieleOptions);
			setRisiko(42, "euro_gerundet", true, investitionsziel.wert, 0, investitionsziel.uuid, label);
		});
	} else setRisiko(42, "euro_gerundet", false, 0, 0);

	return tmpSollwerte;
};

const getEntityName = (entity, label) => {
	var dynLabel = (entity?.vorname || "") + " " + (entity?.nachname || "");
	dynLabel = dynLabel.trim();
	if (dynLabel !== "") return dynLabel;
	return label;
};

export const calcSoll = (inModel) => {
	//console.log("calcSoll", inModel);
	let outModel = [];
	outModel.push(calcSollDetail(inModel, inModel.kunde, inModel.haushalt, getEntityName(inModel.kunde, "Kunde"), true, inModel.kinder, inModel.kunde.uuid));
	//outModel.push(calcSollHaushalt(inModel.haushalt));
	if (inModel.kunde.familienstand === "verheiratet" || inModel.kunde.familienstand === "partnerschaft")
		outModel.push(calcSollDetail(inModel, inModel.partner, inModel.haushalt, getEntityName(inModel.partner, "Partner"), true, inModel.kinder, inModel.partner.uuid));
	inModel.kinder.forEach((kind, index) => {
		if (kind.geburtsdatum) outModel.push(calcSollDetail(inModel, kind, inModel.haushalt, getEntityName(kind, "Kind " + index + 1), false, inModel.kinder, kind.uuid));
	});
	//console.log("outModel", outModel);
	return outModel.flat();
};
