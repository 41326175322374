import { vermoegenswerteOptions, investitionszieleOptions, verbindlichkeitenOptions } from "services/optionsService";

export const defTemplateVAO = [
	{
		control: "expandable",
		label_add: "Vermögenswert hinzufügen",
		name: "vermoegenswerte",
		label: "Vermögenswerte",
		delete_label: "der Vermögenswert",
		icon: "MoneyIncome",
		cols: 12,
		info: {
			title: "Hinweise",
			description:
				"Der Auflistung ihrer Vermögenswerte dient dazu einen Gesamtüberblick über ihre Vermögenssituation zu erhalten. Hierzu haben Sie die Möglichkeit alle vorhandenen Geld-, Vermögens- und Sachwerte aufzulisten. Die Angaben werden Ihnen in einer Bilanz zusammengefasst.",
		},
		show_sum: {
			name: "wert",
			unit_type: "currency_no_digit",
			unit: "€",
		},
		default: [
			{
				bezeichnung: "girokonto",
				wert: 0,
			},
			{
				bezeichnung: "spareinlagen",
				wert: 0,
			},
		],
		children: [
			{
				name: "bezeichnung",
				label: "Bezeichnung",
				control: "select",
				cols: 12,
				options: vermoegenswerteOptions,
			},
			{
				name: "wert",
				label: "Wert",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 2000000,
				step: 100,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
			},
			{
				name: "protected",
				control: "boolean",
				default: false,
			},
		],
	},

	{
		control: "expandable",
		label_add: "Verbindlichkeit hinzufügen",
		name: "verbindlichkeiten",
		label: "Verbindlichkeiten",
		delete_label: "die Verbindlichkeit",
		icon: "MoneySpending",
		info: {
			title: "Hinweise",
			description:
				"Der Auflistung ihrer Verbindlichkeiten dient dazu einen Gesamtüberblick über ihre Vermögenssituation zu erhalten. Hierzu haben Sie die Möglichkeit alle vorhandenen Darlehens- und Kreditverbindlichkeiten aufzulisten. Die Angaben werden Ihnen in einer Bilanz zusammengefasst.",
		},
		cols: 12,
		show_sum: {
			name: "wert",
			unit_type: "currency_no_digit",
			unit: "€",
		},
		default: [
			{
				bezeichnung: "ueberziehungkredit",
				wert: 0,
			},
			{
				bezeichnung: "kreditkarten",
				wert: 0,
			},
			{
				bezeichnung: "sonstige_kredite",
				wert: 0,
			},
		],
		children: [
			{
				name: "bezeichnung",
				label: "Bezeichnung",
				control: "select",
				cols: 12,
				options: verbindlichkeitenOptions,
			},
			{
				name: "wert",
				label: "Wert",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 2000000,
				step: 100,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
			},
			{
				name: "protected",
				control: "boolean",
				default: false,
			},
		],
	},

	{
		control: "expandable",
		label_add: "Ziel hinzufügen",
		name: "investitionsziele",
		label: "Investitionsziele",
		delete_label: "das Investitionsziel",
		icon: "Target",
		info: {
			title: "Hinweise",
			description:
				"Die Angabe von Investitionszielen dient dazu, finanzielle Ziele, die nicht von der Norm abgedeckt sind bzw. die über die festgelegten Zielgrößen hinausgehen bei der Planung der Verwendung der finanziellen Mittel mit einzubeziehen.",
		},
		cols: 12,
		show_sum: {
			name: "wert",
			unit_type: "currency_no_digit",
			unit: "€",
		},
		children: [
			{
				name: "bezeichnung",
				label: "Bezeichnung",
				control: "select",
				cols: 12,
				options: investitionszieleOptions,
			},
			{
				name: "zeitpunkt",
				label: "Zielzeitpunkt",
				control: "year_month_picker",
				cols: 6,
			},
			{
				name: "wert",
				label: "Zielwert",
				control: "sliderinput",
				unit: "€",
				default: 0,
				min: 0,
				max: 2000000,
				step: 100,
				unit_type: "currency_no_digit",
				cols: 12,
				prefix: "über",
			},
		],
	},
];
