import { Menu, MenuOpen } from "@mui/icons-material/";
import { Box, Collapse, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Fragment, useState } from "react";

export default function Collapsable({ children }) {
	const theme = useTheme();
	const [visible, setVisible] = useState(true);

	return (
		<Fragment>
			{!visible && (
				<Box
					sx={{
						margin: "0px",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<IconButton edge="end" color="inherit" aria-label="toggle menu" onClick={() => setVisible(!visible)}>
						<Menu />
					</IconButton>
				</Box>
			)}
			<Collapse
				in={visible}
				orientation="horizontal"
				sx={{
					transition: theme.transitions.create("width", {
						duration: theme.transitions.duration.standard,
						easing: theme.transitions.easing.easeInOut,
					}),
				}}
			>
				<Box sx={{ width: "100%", display: "flex", paddingRight: "10px", marginBottom: "-40px", justifyContent: "flex-end" }}>
					<IconButton edge="end" color="inherit" aria-label="toggle menu" onClick={() => setVisible(!visible)}>
						<MenuOpen />
					</IconButton>
				</Box>
				{children}
			</Collapse>
		</Fragment>
	);
}
