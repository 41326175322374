import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { getAlter } from "services/formatService";
import { isWirtschaftlichAbhaengigesKind } from "services/DINSollCalculationService";

export const getValue = (values, name) => {
	const fieldPath = name.split(".");
	let result = values;

	for (const field of fieldPath) {
		if (result && typeof result === "object" && field in result) {
			result = result[field];
		} else {
			result = undefined;
			break;
		}
	}
	return result;
};

export const getNestedValue = (obj, path) => {
	return path
		.split(/[\.\[\]]+/)
		.filter(Boolean)
		.reduce((o, p) => (o && o[p] !== "undefined" ? o[p] : undefined), obj);
};

export const arraysAreEqual = (array1, array2) => {
	if (array1.length !== array2.length) {
		return false;
	}
	for (let i = 0; i < array1.length; i++) {
		if (array1[i] !== array2[i]) {
			return false;
		}
	}
	return true;
};

const string2boolean = (wert) => {
	if (wert === "true") return true;
	if (wert === "false") return false;
	return wert;
};

export const isItemHidden = (item, values) => {
	if (item.visible) {
		const def = item.visible;
		const field = def.field || "";
		const entity_path = def.entity_path || "";
		const cmp_value = def.value;
		let value = "";
		if (field !== "") {
			value = getNestedValue(values, field);
			if (!value) {
				const objName = item.name;
				value = getNestedValue(getNestedValue(values, objName.slice(0, objName.lastIndexOf("."))), field);
			}
		}
		let entity = {};
		if (entity_path !== "") entity = getNestedValue(values, entity_path);

		switch (def.func) {
			case "const":
				if (cmp_value === "false") return true;
				return false;
			case "equal":
				if (Array.isArray(cmp_value)) {
					if (cmp_value.every((single_value) => value !== single_value)) return true;
				} else if (value !== cmp_value) return true;
				break;
			case "not_equal":
				if (value === cmp_value) return true;
				break;
			case "equal_bool":
				if (value !== string2boolean(cmp_value)) return true;
				break;
			case "gt":
				if (value <= cmp_value) return true;
				break;
			case "volljaehrig":
				if (value === undefined) return true;
				if (getAlter(value) >= 18) return false;
				return true;
			case "wirtschaftlichAbhaengig":
				if (isWirtschaftlichAbhaengigesKind(entity)) return true;
				return false;
			default:
		}
		return false;
	}
};

export const getEmptyModel = (structure) => {
	let model = {};
	if (!structure) return model;
	const filter = ["section", "tabs", "tab", "stepper"];
	structure.forEach((item) => {
		if (filter.includes(item.control)) {
			switch (item.control) {
				case "section":
				case "tab":
				case "tabs":
					Object.assign(model, getEmptyModel(item.children));
					break;
				case "stepper":
					item.children.forEach((step) => {
						Object.assign(model, getEmptyModel(step.children));
					});
					break;
				default:
			}
		}

		let defaultValue = "";
		switch (item.control) {
			case "select":
				defaultValue = item.default || "";
				if (item.multiple) defaultValue = item.default || undefined;
				break;
			case "upload":
			case "expandable":
			case "button_checkboxes":
			case "checkbox_group":
				defaultValue = item.default || [];
				break;
			case "switch":
			case "boolean":
				defaultValue = item.default || false;
				break;
			case "datepicker":
			case "year_month_picker":
			case "year_picker":
			case "detailed_radio_group":
				defaultValue = item.default || null;
				break;
			case "input":
			case "numberinput":
			case "numberlabel":
			case "button_options":
			case "display":
				defaultValue = item.default || "";
				break;
			case "sliderinput":
				defaultValue = item.default || item.min || 0;
				break;

			default:
		}

		if (item.name) model[item.name] = item.default || defaultValue;
	});
	return model;
};

export const convertPunktToNested = (obj) => {
	const newObj = {};
	for (let key in obj) {
		if (obj.hasOwnProperty(key)) {
			const keys = key.split(".");
			let currentObj = newObj;
			for (let i = 0; i < keys.length; i++) {
				const k = keys[i];
				if (!currentObj[k]) {
					if (i === keys.length - 1) {
						currentObj[k] = obj[key];
					} else {
						currentObj[k] = {};
					}
				}
				currentObj = currentObj[k];
			}
		}
	}
	return newObj;
};

export const containerRendererGrid = (children) => {
	return (
		<Grid container="true" spacing={2}>
			{children}
		</Grid>
	);
};

export const itemRendererGrid = (children, item, index) => {
	const numColumns = Number(item.cols) || 12;
	return (
		<Grid sm={12} md={numColumns} lg={numColumns} xl={numColumns} key={index} sx={{ width: "100%" }}>
			{children}
		</Grid>
	);
};

export const containerRendererFlex = (children) => {
	return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>{children}</Box>;
};

export const itemRendererFlex = (children, item, index) => {
	const numColumns = Number(item.cols) || 12;
	let widthPercent = (100 / 12) * numColumns + "%";
	let width = widthPercent;
	if (numColumns < 12) width = "calc(" + widthPercent + " - 8px)";
	return (
		<Box key={index} sx={{ width: width }}>
			{children}
		</Box>
	);
};
