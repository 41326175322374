import { Box } from "@mui/material";
import SubModules from "components/SubModules";
import Tile from "components/Tile";
import BackendLayout from "layouts/BackendLayout";
import { useRecoilValue } from "recoil";
import { appWindowAtom } from "recoil/atoms/appWindowAtom";
import { workflowAtom } from "recoil/atoms/workflowAtom";
import { getActiveSubmodule } from "services/workflowService";
import DynamicComponentLoader from "components/DynamicComponentLoader";

export default function Generic() {
	const workflow = useRecoilValue(workflowAtom);
	const appWindow = useRecoilValue(appWindowAtom);
	const activeSubmodule = getActiveSubmodule(workflow);

	return (
		<BackendLayout>
			<Box sx={{ display: "flex", width: appWindow.contentWidth }}>
				<SubModules />
				<Tile sx={{ ml: "16px", mr: "0px", mt: "0px", mb: "0px", display: "flex", flexGrow: "0", padding: "16px", height: appWindow.contentHeight + "px" }}>
					<DynamicComponentLoader config={activeSubmodule?.config} />
				</Tile>
			</Box>
		</BackendLayout>
	);
}
